import React, { Component, Fragment } from "react";
import AUX from "../../../hoc/Aux_";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import $ from "jquery";
import _ from "lodash";
import Services from "../PermissionService";
import TableCustomTotal from "../../Shared/TableCustomTotal";
import { Form } from 'react-bootstrap';

class MyTeams extends Component {
  constructor(props) {
    super(props);
    this.state = {
      perms: "",
      page: 1,
      recordsPerPage: 25,
      keyword: this.props.location.keyword || "",
      records: [],
      totalCount: "",
    };

    this.rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.props.history.push({
          pathname: "/permissions/teams/teamDetail/" + row.id,
          type: "add",
          uid: row.id,
          keyword: $("#keyword").val(),
        });
      },
    };
    this.RemotePagination = this.RemotePagination.bind(this);
    this.sizePerPageChange = this.sizePerPageChange.bind(this);
    this.columns = this.columns.bind(this);
  }

  sizePerPageChange = (sizePerPage) => {
    this.setState({ recordsPerPage: sizePerPage });
  };

  RemotePagination = (data, page, sizePerPage, showTotal, rowEvents) => {
    const sizePerPageList = [
      {
        text: "25",
        value: 25,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "All",
        value: data && data.length,
      },
    ];
    let paginationOptions = {
      totalSize: data ? data.length : 0,
      showTotal,
      onSizePerPageChange: this.sizePerPageChange,
      paginationTotalRenderer: TableCustomTotal,
      sizePerPage,
      sizePerPageList,
      page
    };

    return (
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => (
          <div>
            <BootstrapTable
              keyField="id"
              data={data}
              columns={this.columns()}
              {...paginationTableProps}
              rowEvents={rowEvents}
              id="myTeamsListing"
            />
          </div>
        )}
      </PaginationProvider>
    );
  };

  columns = () => {
    return [
      {
        text: "Team Name",
        dataField: "teamName",
        sort: true,
        classes: "c-pointer",
      },
      {
        text: "Positions",
        isDummyField: true,
        classes: "c-pointer",
        sort: true,
        formatter: (cellContent, row) => {
          return (
            <div>
              {row.positionName} {row.isOwner && <b> (OWNER)</b>}
            </div>
          );
        },
      },
    ];
  }

  async getTeams() {
    $(".loading").show();
    let data = await Services.MyTeam();
    if (!_.isEmpty(data)) {
      this.setState({
        records: data.result,
      });
    }
    $(".loading").hide();
  }
  componentDidMount() {
    this.props.history.push("/myTeams");
    this.getTeams();
  }

  componentWillUnmount() { }
  render() {

    const { records, sizePerPage, page, totalCount } = this.state;
    const showTotal = true;
    return (
      <AUX>
        <div className="container-fluid">
          <div class="page-title-box">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <h4 class="text-uppercase page-title-search">My Teams</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {!_.isEmpty(records) &&
                this.RemotePagination(
                  records,
                  page,
                  sizePerPage,
                  showTotal,
                  this.rowEvents
                )}
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default MyTeams;
