import _ from "lodash";
import authaxios from "../Shared/auth-header";
import auth0 from "auth0-js";

class AuthServiceClass {
  // Initializing important variables
  constructor(domain) {
    //this.domain = domain || "http://localhost:8080"; // API server domain
    this.login = this.login.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.logout = this.logout.bind(this);
    this._checkStatus = this._checkStatus.bind(this);
    this.isJson = this.isJson.bind(this);
    this.getJwtToken = this.getJwtToken.bind(this);
    this.getRefreshToken = this.getRefreshToken.bind(this);
    this.saveJwtToken = this.saveJwtToken.bind(this);
    this.saveRefreshToken = this.saveRefreshToken.bind(this);
    this.setSession = this.setSession.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.loginAuthO = this.loginAuthO.bind(this);
    this.logoutAuthO = this.logoutAuthO.bind(this);
    this.silentAuthorization = this.silentAuthorization.bind(this);
    this.canMTView = this.canMTView.bind(this);
    console.log(window.location.origin);
    console.log(window.location.host);
  }

  auth0 = new auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    redirectUri: window.location.origin + "/startSession",
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    responseType: "id_token code",
    useRefreshTokens: true,
    scope: "openid offline_access",
  });
  intercomInitialization() {
    let loggeduser = AuthService.getProfile();
    if (window.Intercom) {
      window.Intercom("shutdown");
    }
    if (loggeduser.memberData != null && AuthService.isAuthenticated()) {
      if (this.canMTView()) {
        window.intercomSettings = {
          api_base: "https://api-iam.intercom.io",
          app_id: process.env.REACT_APP_INTERCOM_APPID,
          user_id: loggeduser.memberData.membernumber, // a UUID for your user
          user_hash: loggeduser.memberData.hmac, // an Identity Verification user hash for your user
        };
        window.Intercom("boot", window.intercomSettings);
      }
    } else {
      window.intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: process.env.REACT_APP_INTERCOM_APPID,
      };
      window.Intercom("boot", window.intercomSettings);
    }
  }
  silentAuthorization() {
    this.auth0.authorize({ prompt: "none" });
  }
  checkSession() {
    sessionStorage.setItem(
      "redirect",
      window.location.href.split(window.location.host)[1]
    );

    this.auth0.authorize({
      prompt: "none",
      redirectUri: window.location.origin + "/startSession?login=true",
    });
  }

  handleAuthentication(history) { }

  setSession(authResult) {
    let expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );
    localStorage.setItem("token", authResult.accessToken);
    localStorage.setItem("id_token", authResult.idToken);
    localStorage.setItem("expires_at", expiresAt);
  }

  isAuthenticated() {
    let jwt = this.getJwtToken();
    let refresh = this.getRefreshToken();
    return jwt && refresh;
  }
  loginAuthO() {
    this.auth0.authorize();
  }
  logoutAuthO() {

    this.auth0.logout({
      returnTo: `${window.location.protocol}//${window.location.host}/startSession?logout=true`,
    });

    AuthService.clearIntercomCookiesAndRestart();

  }
  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  login(username, password) {
    return authaxios
      .post("/api/Account/SignIn", {
        Username: username,
        Password: password,
      })
      .then(({ data }) => {
        if (data.status && data.status == "Success") {
          this.setToken(data.data);
          localStorage.setItem("user", "loggedas" + username);
          return Promise.resolve(data);
        } else {
          return Promise.resolve(data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken(); // GEtting token from localstorage
    return !!token.memberData; // handwaiving here
  }
  checkSessionRepeat() {
    if (
      process.env.NODE_ENV !== "development" &&
      process.env.NODE_ENV !== "local"
    ) {
      let $this = this;

      this.auth0.checkSession(
        {
          scope: "code",
          connection: process.env.REACT_APP_AUTH0_DB,
        },
        function (err) {
          //console.log("check session");
          //console.log(err);
          // err if automatic parseHash fails
          if (err !== undefined && err) {
            $this.logoutAuthO();
            console.error(err);
            return;
          }

          let loggeduser = AuthService.getProfile();
          if (loggeduser.memberData != null && $this.isAuthenticated()) {
            setTimeout(function () {
              $this.checkSessionRepeat();
            }, 30000);
            return;
          }
          AuthService.intercomInitialization();

          //resolve(authResult);
        }
      );
    }
  }

  setToken(res) {
    console.log(res);
    sessionStorage.setItem("activeSession", "true");
    localStorage.setItem("memberData", JSON.stringify(res.memberData));
    localStorage.setItem("user", "loggedas" + res.memberData.username);
    localStorage.setItem(
      "memberpermissions",
      JSON.stringify(res.memberPermissions)
    );
    localStorage.setItem(
      "memberpermissionswithscope",
      JSON.stringify(res.memberPermissionsWithScope)
    );
    localStorage.setItem("ownpermissions", JSON.stringify(res.ownPermissions));
    //localStorage.setItem(
    //  "scopepermissions",
    //  JSON.stringify(res.scopePermissions)
    //);
    localStorage.setItem("token", res.token);
    localStorage.setItem("scopeaccess", JSON.stringify(res.scopeAccess));
    localStorage.setItem("refreshToken", res.refreshToken);
    //   localStorage.setItem("logout-event", "logout" + Math.random());
  }
  getToken() {
    return {
      memberData: JSON.parse(localStorage.getItem("memberData")),
    };
  }

  logout() {
    console.log("logout called from auth service.");
    localStorage.removeItem("memberData");
    //localStorage.removeItem("token");
    localStorage.removeItem("memberpermissions");
    localStorage.removeItem("memberpermissionswithscope");
    sessionStorage.removeItem("memberdetailpermissions");
    localStorage.removeItem("ownpermissions");
    sessionStorage.removeItem("facilitypermissions");
    localStorage.removeItem("teamsPageNumber");
    localStorage.removeItem("teamsPageSize");
    //localStorage.removeItem("scopepermissions");
    //localStorage.removeItem("refreshToken");
    //localStorage.setItem("logout-event", "logout" + Math.random());
    //localStorage.removeItem("logout-event");

    sessionStorage.clear();
  }
  ClearLocalStorage() {
    console.log("logout called from auth service.");
    localStorage.removeItem("memberData");
    //localStorage.removeItem("token");
    localStorage.removeItem("memberpermissions");
    sessionStorage.removeItem("memberdetailpermissions");
    sessionStorage.removeItem("facilitypermissions");

    localStorage.removeItem("ownpermissions");
    //localStorage.removeItem("scopepermissions");
    localStorage.removeItem("refreshToken");
    //localStorage.setItem("logout-event", "logout" + Math.random());
    //localStorage.removeItem("logout-event");
    localStorage.removeItem("token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");

    sessionStorage.clear();
  }
  getProfile() {
    return this.getToken();
  }

  async _checkStatus() {
    return await authaxios
      .post("/api/Account/IsUserSignedIn")
      .then(({ data }) => {
        return Promise.resolve(data);
      })
      .catch(function (error) {
        console.log(error);
      });
    //return await authaxios.get(
    //    "/api/Account/Refresh?refresh_token=" +
    //    this.getRefreshToken()
    //)
    //    .then((jsonRefreshResponse) => {
    //        debugger;
    //        if (jsonRefreshResponse.status === 200) {
    //            // 0) Check If token Updated Already

    //            // 1) put token to LocalStorage
    //            this.saveJwtToken(jsonRefreshResponse.data.token);
    //            this.saveRefreshToken(
    //                jsonRefreshResponse.data.refreshToken
    //            );

    //            return Promise.resolve(true);
    //        }
    //        else {
    //            return false;
    //        }
    //    });
  }
  getJwtToken() {
    return localStorage.getItem("token");
  }
  getRefreshToken() {
    return localStorage.getItem("refreshToken");
  }
  saveJwtToken(token) {
    localStorage.removeItem("token");
    localStorage.setItem("token", token);
  }
  saveRefreshToken(refreshToken) {
    localStorage.setItem("refreshToken", refreshToken);
  }
  clearIntercomCookiesAndRestart() {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      // Check if the cookie name starts with 'intercom-'
      if (cookie.indexOf("intercom-") === 0) {
        var parts = cookie.split("=");
        var name = parts[0];
        // Clear the cookie by setting an expiration date in the past
        document.cookie =
          name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      }
    }
    AuthService.intercomInitialization();
  }
  ///Can member type veiw
  canMTView() {
    var memberData = JSON.parse(localStorage.getItem("memberData"));
    if (
      memberData &&
      memberData.memberType &&
      (memberData.memberType.description || "").trim() == "Non-Member"
    ) {
      return false;
    }
    return true;
  }
  canSPView(id, regionId = null, facilityId = null, memberId = null) {
    var memberPermissions = JSON.parse(
      localStorage.getItem("memberpermissionswithscope")
    );
    if (_.some(memberPermissions, { id: id, isView: true })) {
      let obj = _.filter(memberPermissions, { id: id, isView: true })[0];
      if (obj.isViewScope) {
        let scopeObj = JSON.parse(obj.isViewScope);
        if (scopeObj.National == 1) {
          return true;
        } else {
          if (regionId) {
            if (scopeObj.Region.findIndex((x) => x == regionId) > -1) {
              return true;
            }
          }
          if (facilityId) {
            if (scopeObj.Facility.findIndex((x) => x == facilityId) > -1) {
              return true;
            }
          }
          if (memberId) {
            if (scopeObj.ThisTeam.findIndex((x) => x == memberId) > -1) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  canSPAdd(id, regionId = null, facilityId = null, memberId = null) {
    var memberPermissions = JSON.parse(
      localStorage.getItem("memberpermissionswithscope")
    );
    if (_.some(memberPermissions, { id: id, isAdd: true })) {
      let obj = _.filter(memberPermissions, { id: id, isAdd: true })[0];
      if (obj.isAddScope) {
        let scopeObj = JSON.parse(obj.isAddScope);
        if (scopeObj.National == 1) {
          return true;
        } else {
          if (regionId) {
            if (scopeObj.Region.findIndex((x) => x == regionId) > -1) {
              return true;
            }
          }
          if (facilityId) {
            if (scopeObj.Facility.findIndex((x) => x == facilityId) > -1) {
              return true;
            }
          }
          if (memberId) {
            if (scopeObj.ThisTeam.findIndex((x) => x == memberId) > -1) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  canSPUpdate(id, regionId = null, facilityId = null, memberId = null) {
    var memberPermissions = JSON.parse(
      localStorage.getItem("memberpermissionswithscope")
    );
    if (_.some(memberPermissions, { id: id, isUpdate: true })) {
      let obj = _.filter(memberPermissions, { id: id, isUpdate: true })[0];
      if (obj.isUpdateScope) {
        let scopeObj = JSON.parse(obj.isUpdateScope);
        if (scopeObj.National == 1) {
          return true;
        } else {
          if (regionId) {
            if (scopeObj.Region.findIndex((x) => x == regionId) > -1) {
              return true;
            }
          }
          if (facilityId) {
            if (scopeObj.Facility.findIndex((x) => x == facilityId) > -1) {
              return true;
            }
          }
          if (memberId) {
            if (scopeObj.ThisTeam.findIndex((x) => x == memberId) > -1) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  canSPDelete(id, regionId = null, facilityId = null, memberId = null) {
    var memberPermissions = JSON.parse(
      localStorage.getItem("memberpermissionswithscope")
    );
    if (_.some(memberPermissions, { id: id, isDelete: true })) {
      let obj = _.filter(memberPermissions, { id: id, isDelete: true })[0];
      if (obj.isDeleteScope) {
        let scopeObj = JSON.parse(obj.isDeleteScope);
        if (scopeObj.National == 1) {
          return true;
        } else {
          if (regionId) {
            if (scopeObj.Region.findIndex((x) => x == regionId) > -1) {
              return true;
            }
          }
          if (facilityId) {
            if (scopeObj.Facility.findIndex((x) => x == facilityId) > -1) {
              return true;
            }
          }
          if (memberId) {
            if (scopeObj.ThisTeam.findIndex((x) => x == memberId) > -1) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  canView(id) {
    var memberPermissions = JSON.parse(
      localStorage.getItem("memberpermissions")
    );
    if (_.some(memberPermissions, { id: id, isView: true })) {
      return true;
    }
    return false;
  }
  canAdd(id) {
    var memberPermissions = JSON.parse(
      localStorage.getItem("memberpermissions")
    );
    if (_.some(memberPermissions, { id: id, isAdd: true })) {
      return true;
    }
    return false;
  }
  canUpdate(id) {
    var memberPermissions = JSON.parse(
      localStorage.getItem("memberpermissions")
    );
    if (_.some(memberPermissions, { id: id, isUpdate: true })) {
      return true;
    }
    return false;
  }
  canDelete(id) {
    var memberPermissions = JSON.parse(
      localStorage.getItem("memberpermissions")
    );
    if (_.some(memberPermissions, { id: id, isDelete: true })) {
      return true;
    }
    return false;
  }
  canFacilityView(id) {
    if (sessionStorage.getItem("facilitypermissions") != null) {
      var memberPermissions = JSON.parse(
        sessionStorage.getItem("facilitypermissions")
      );

      if (
        this.canSPView(
          id,
          memberPermissions.regionId,
          memberPermissions.facilityId
        )
      ) {
        return true;
      }
    }

    return false;
  }
  canFacilityAdd(id) {
    if (sessionStorage.getItem("facilitypermissions") !== null) {
      var memberPermissions = JSON.parse(
        sessionStorage.getItem("facilitypermissions")
      );
      if (
        this.canSPAdd(
          id,
          memberPermissions.regionId,
          memberPermissions.facilityId
        )
      ) {
        return true;
      }
    }
    return false;
  }
  canFacilityUpdate(id) {
    if (sessionStorage.getItem("facilitypermissions") !== null) {
      var memberPermissions = JSON.parse(
        sessionStorage.getItem("facilitypermissions")
      );
      if (
        this.canSPUpdate(
          id,
          memberPermissions.regionId,
          memberPermissions.facilityId
        )
      ) {
        return true;
      }
    }
    return false;
  }
  canFacilityDelete(id) {
    if (sessionStorage.getItem("facilitypermissions") !== null) {
      var memberPermissions = JSON.parse(
        sessionStorage.getItem("facilitypermissions")
      );
      if (
        this.canSPDelete(
          id,
          memberPermissions.regionId,
          memberPermissions.facilityId
        )
      ) {
        return true;
      }
    }
    return false;
  }
  canMemberView(id) {
    if (sessionStorage.getItem("memberdetailpermissions") != null) {
      var memberPermissions = JSON.parse(
        sessionStorage.getItem("memberdetailpermissions")
      );

      if (
        this.canSPView(
          id,
          memberPermissions.regionId,
          memberPermissions.facilityId,
          memberPermissions.memberId
        )
      ) {
        return true;
      }
    }

    return false;
  }
  canMemberAdd(id) {
    if (sessionStorage.getItem("memberdetailpermissions") !== null) {
      var memberPermissions = JSON.parse(
        sessionStorage.getItem("memberdetailpermissions")
      );
      if (
        this.canSPAdd(
          id,
          memberPermissions.regionId,
          memberPermissions.facilityId,
          memberPermissions.memberId
        )
      ) {
        return true;
      }
    }
    return false;
  }
  canMemberUpdate(id) {
    if (sessionStorage.getItem("memberdetailpermissions") !== null) {
      var memberPermissions = JSON.parse(
        sessionStorage.getItem("memberdetailpermissions")
      );
      if (
        this.canSPUpdate(
          id,
          memberPermissions.regionId,
          memberPermissions.facilityId,
          memberPermissions.memberId
        )
      ) {
        return true;
      }
    }
    return false;
  }
  canMemberDelete(id) {
    if (sessionStorage.getItem("memberdetailpermissions") !== null) {
      var memberPermissions = JSON.parse(
        sessionStorage.getItem("memberdetailpermissions")
      );
      if (
        this.canSPDelete(
          id,
          memberPermissions.regionId,
          memberPermissions.facilityId,
          memberPermissions.memberId
        )
      ) {
        return true;
      }
    }
    return false;
  }

  canOwnView(id, memberId) {
    var ownpermissions = JSON.parse(localStorage.getItem("ownpermissions"));
    var memberData = JSON.parse(localStorage.getItem("memberData"));
    if (
      _.some(ownpermissions, { id: id, isView: true }) &&
      memberData.id === +memberId
    ) {
      return true;
    }
    return false;
  }
  canOwnAdd(id, memberId) {
    var ownpermissions = JSON.parse(localStorage.getItem("ownpermissions"));
    var memberData = JSON.parse(localStorage.getItem("memberData"));
    if (
      _.some(ownpermissions, { id: id, isAdd: true }) &&
      memberData.id === +memberId
    ) {
      return true;
    }
    return false;
  }
  canOwnUpdate(id, memberId) {
    var ownpermissions = JSON.parse(localStorage.getItem("ownpermissions"));
    var memberData = JSON.parse(localStorage.getItem("memberData"));
    if (
      _.some(ownpermissions, { id: id, isUpdate: true }) &&
      memberData.id === +memberId
    ) {
      return true;
    }
    return false;
  }
  canOwnDelete(id, memberId) {
    var ownpermissions = JSON.parse(localStorage.getItem("ownpermissions"));
    var memberData = JSON.parse(localStorage.getItem("memberData"));
    if (
      _.some(ownpermissions, { id: id, isDelete: true }) &&
      memberData.id === +memberId
    ) {
      return true;
    }
    return false;
  }
  canNational() {
    var scopeaccess = JSON.parse(localStorage.getItem("scopeaccess"));
    if (!_.isEmpty(scopeaccess)) {
      return scopeaccess.national;
    } else {
      return false;
    }
  }
  canRegion() {
    var scopeaccess = JSON.parse(localStorage.getItem("scopeaccess"));
    if (!_.isEmpty(scopeaccess)) {
      return scopeaccess.region;
    } else {
      return false;
    }
  }
  canFacility() {
    var scopeaccess = JSON.parse(localStorage.getItem("scopeaccess"));
    if (!_.isEmpty(scopeaccess)) {
      return scopeaccess.facility;
    } else {
      return false;
    }
  }
  canThisTeam() {
    var scopeaccess = JSON.parse(localStorage.getItem("scopeaccess"));
    if (!_.isEmpty(scopeaccess)) {
      return scopeaccess.thisTeam;
    } else {
      return false;
    }
  }
}
const AuthService = new AuthServiceClass(window.location.host);

export default AuthService;
