import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../store/action";
import { Link } from "react-router-dom";
import { MDBInput } from "mdbreact";
import AuthService from "../Auth/AuthService";
import $ from "jquery";
import chistory from "../Shared/history";

import Register from "./Register";
import { toast } from "react-toastify";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      username_err: "",
      password_err: "",
      valid: false,
      message: "",
      status: false,
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleChange = this.changeHandler.bind(this);
    this.Auth = AuthService;
  }

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.value === "") {
      this.setState({ [event.target.name + "_err"]: "Required Field" });
      this.setState({ valid: false });
    } else {
      this.setState({ [event.target.name + "_err"]: "" });
      this.setState({ valid: true });
    }
  };

  handleFormSubmit(e) {
    e.preventDefault();
    if (this.state.username === "" || this.state.username_err != "") {
      this.setState({ username_err: "Fix the Error" });
      this.setState({ valid: false });
      return false;
    }
    if (this.state.password === "" || this.state.password_err != "") {
      this.setState({ password_err: "Fix the Error" });
      this.setState({ valid: false });
      return false;
    }
    if (this.state.valid) {
      $(".loading").show();
      this.setState({ status: false });
      this.Auth.login(this.state.username, this.state.password)
        .then((res) => {
          if (res.status.toLowerCase() == "pendingregistration") {
            $(".loading").hide();
            toast.warn(
              "Pending Registration: Please complete it, before login!"
            );
            chistory.push("/register", {
              registrationPending: true,
              registrationInfo: res.registrationInfo,
            });
            return;
          }

          if (res.status.toLowerCase() == "fail") {
            this.setState({ status: true });
            this.setState({ message: res.message });
            $(".loading").hide();
          } else {
            $(".loading").hide();
            this.props.UpdateLoginAgain();
            //this.props.history.replace("/dashboard");
            window.addEventListener("storage", (event) => {
              if (event.key == "logout-event") {
                //this.Auth.logout();
                this.props.history.replace("/login");
              }
            });

            this.props.history.push(
              "/dashboard/myProfile/" + res.data.memberData.id
            );
            //this.props.LoginSucess();
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
  }
  componentDidMount() {
    if (this.props.loginpage === false) {
      this.props.UpdateLogin();
    }
  }
  componentWillMount() {
    if (this.Auth.loggedIn()) {
      this.props.UpdateLoginAgain();
      this.props.history.replace("/");
    }
  }
  render() {
    return (
      <div className="wrapper-page">
        <div className="card overflow-hidden account-card mx-3">
          <div
            className="bg-primary ptb-login text-white text-center position-relative"
            style={{ height: "145px" }}
          >
            <span>
              <img src="assets/images/logo.png" height="142" alt="logo" />
            </span>
          </div>
          <div className="account-card-content">
            {this.state.status && (
              <div class="alert alert-danger" role="alert">
                {this.state.message}
              </div>
            )}

            {/* <form
              className="form-horizontal m-t-40"
              onSubmit={this.handleFormSubmit}
            >
              <div className="form-group">
                <MDBInput
                  name="username"
                  value={this.state.username}
                  onChange={this.changeHandler}
                  label="Username"
                  group
                  type="text"
                  id="username"
                >
                  <div className="height17px">
                    <span className="text-danger height17px">
                      {this.state.username_err}
                    </span>
                  </div>
                </MDBInput>
              </div>

              <div className="form-group">
                <MDBInput
                  name="password"
                  value={this.state.password}
                  onChange={this.changeHandler}
                  label="Password"
                  id="password"
                  group
                  type="password"
                >
                  <div className="height17px">
                    <span className="text-danger">
                      {this.state.password_err}
                    </span>
                  </div>
                </MDBInput>
              </div>

              <div className="form-group row m-t-20">
                <div className="col-sm-6">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customControlInline"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customControlInline"
                    >
                      Remember me
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-12 mx-auto text-center">
                <button
                  disabled={!this.state.valid}
                  className="btn btn-primary btn-block w-md waves-effect waves-light"
                  type="submit"
                >
                  Log In
                </button>
              </div>
            </form> */}
            <div className="form-horizontal m-t-40">
              <div className="form-group mb-4 row">
                <div className="col-12 mx-auto text-center">
                  <button
                    className="btn btn-danger btn-block w-md waves-effect waves-light"
                    onClick={() => AuthService.loginAuthO()}
                  >
                    Log In With Auth0
                  </button>
                </div>
                <div className="col-12 m-t-20">
                  Need an account?{" "}
                  <Link to="register" className="font-500 text-primary">
                    {" "}
                    Register Now{" "}
                  </Link>{" "}
                </div>

                <div className="col-12 m-t-20 text-left font-weight-bold">
                  By registering for an account with NATCA you agree to our{" "}
                  <a
                    className="font-500 text-primary text-right"
                    href="https://www.natca.org/privacy-policy/"
                    target="_blank"
                  >
                    Terms & Conditions.
                  </a>
                  {/*<Link
                    to="/privacy-policy"
                    className="font-500 text-primary text-right"
                  >
                    {" "}
                    Terms & Conditions.{" "}
                  </Link>{" "}*/}
                </div>

                <div className="col-sm-12 mt-2">
                  Can't remember your password ?
                  <Link to="/AccountRecovery"> Reset Password?</Link>
                </div>
                <div className="col-12 m-t-5 text-left font-weight-lighter">
                  Other issues?{" "}
                  <a target="_blank" href={`mailto:support@natca.org`}>
                    Email Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="m-t-40 text-center">
          <p>{new Date().getFullYear()} © NATCA. V 2.2.2</p>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    loginpage: state.ui_red.loginpage,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    LoginSucess: () => dispatch({ type: actionTypes.WS_CONNECT, value: true }),
    UpdateLogin: () => dispatch({ type: actionTypes.LOGINPAGE, value: true }),
    UpdateLoginAgain: () =>
      dispatch({ type: actionTypes.LOGINPAGE, value: false }),
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(Login);
