import axios from "./../../modules/Shared/auth-header";
import permissionService from "./../Permissions/PermissionService";
import { toast } from "react-toastify";
import _ from "lodash";
import $ from "jquery";

export default {
  LoadGrievants: async (searchTerms, offset, fetch) => {
    let url =
      offset === null || fetch === null
        ? `api/Grievance/Members?searchTerms=` +
        searchTerms +
        `&type=1&offset=0&fetch=10`
        : `api/Grievance/Members?searchTerms=` +
        searchTerms +
        `&type=1&offset=` +
        offset +
        `&fetch=` +
        fetch;
    var { data } = await axios.get(url);
    if (data.statusCode) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  LoadPARFAAContacts: async () => {
    let url = `api/Grievance/PARFAAContacts`;
    var { data } = await axios.get(url);
    if (data.status && data.status.toLowerCase().trim() == "success") {
      return data.data;
    } else {
      toast.error(data.Message);
      return null;
    }
  },
  LoadAllGrievances: async (includeClosed) => {
    let url = `api/Grievance/GetRequests/` + includeClosed;
    var { data } = await axios.get(url);
    if (data.status) {
      return data;
    } else {
      toast.error(data.Message);
      return null;
    }
  },
  GetStepIdByGrievanceId: async (grievanceId) => {
    let url = `api/Grievance/GetFirstStepIdByGrievanceId/` + grievanceId;
    var { data } = await axios.get(url);
    if (data.status) {
      return data;
    } else {
      toast.error(data.Message);
      return null;
    }
  },
  LoadGrievanceByRepresentative: async (inlcudeClosed, includeDraft) => {
    let url = `api/Grievance/GetRequestsByRepresentative/${inlcudeClosed}/${includeDraft}`;
    var { data } = await axios.get(url);
    if (data.status) {
      return data;
    } else {
      toast.error(data.Message);
      return null;
    }
  },

  LoadRepresentativesByLoggedInUser: async () => {
    let url = `api/Grievance/GetRepresentatives`;
    var { data } = await axios.get(url);
    if (data.status) {
      return data;
    } else {
      toast.error(data.Message);
      return null;
    }
  },
  GetRepresentativeRole: async () => {
    let url = `api/Grievance/GetRepresentativeRole/`;
    var { data } = await axios.get(url);
    if (data.status) {
      return data;
    } else {
      toast.error(data.Message);
      return null;
    }
  },
  LoadAllGrievancesByRepresentative: async (includeClosed) => {
    let url = `api/Grievance/GetRequestsByRepresentative/` + includeClosed;
    var { data } = await axios.get(url);
    if (data.status) {
      return data;
    } else {
      toast.error(data.Message);
      return null;
    }
  },
  LoadDataByGrievant: async (grievantId) => {
    let url = `api/Grievance/GetDataByGrievantId/` + grievantId;
    var { data } = await axios.get(url);
    if (data.status) {
      return data;
    } else {
      toast.error(data.Message);
      return null;
    }
  },
  LoadGrievanceDataById: async (grievantId) => {
    let url = `api/Grievance/GetRequestById/` + grievantId;
    var { data } = await axios.get(url);
    if (data.status) {
      return data;
    } else {
      toast.error(data.Message);
      return null;
    }
  },

  loadAllContracts: async () => {
    let url = `api/Grievance/GetBUContracts`;
    var { data } = await axios.get(url);
    if (data.status = "Success") {
      return data;
    } else {
      toast.error(data.Message);
      return null;
    }
  },
  loadAllArticles: async () => {
    let url = `api/Grievance/GetAllArticles`;
    var { data } = await axios.get(url);
    if (data.status = "Success") {
      return data;
    } else {
      toast.error(data.Message);
      return null;
    }
  },
  GetNextStepDueDate: async (lastStepId, grievantId) => {
    let url =
      `api/Grievance/GetNextStepDueDate/` + lastStepId + "/" + grievantId;
    var { data } = await axios.get(url);
    if (data.status) {
      return data;
    } else {
      toast.error(data.Message);
      return null;
    }
  },
  GetArticleDetails: async (articleId) => {
    let url = `api/Grievance/GetArticleDetails/` + articleId;
    var { data } = await axios.get(url);
    if (data.status) {
      return data;
    } else {
      toast.error(data.Message);
      return null;
    }
  },
  SaveGrievance: async (formdata) => {
    let url = "/api/Grievance/SaveGrievance";
    var { data } = await axios.post(url, formdata, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    });

    if (data.status == "Success") {
      return data;
    } else {
      toast.error("Invalid Request");
      return null;
    }
  },
  EditGrievance: async (formdata) => {
    let url = "/api/Grievance/EditGrievance";
    var { data } = await axios.post(url, formdata, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    });

    if (data.status == "Success") {
      return data;
    } else {
      toast.error("Invalid Request");
      return null;
    }
  },
  FetchGrievancesPDFData: async (ids) => {
    let url = "/api/Grievance/GetMultiplePDFData?grievanceIds=" + ids;
    var { data } = await axios.get(url);
    if (data.status) {
      return data;
    } else {
      toast.error(data.Message);
      return null;
    }
  },
  SaveStep: async (formdata) => {
    let url = "/api/Grievance/SaveStep";
    var { data } = await axios.post(url, formdata, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    });
    if (data.status == "Success") {
      return data;
    } else {
      toast.error("Invalid Request");
      return data;
    }
  },
  DeleteRecipient: async (id) => {
    let url = "/api/Grievance/DeleteRecipient?id=" + id;
    var { data } = await axios.delete(url);
    if (data.status == "Success") {
      toast.success(data.message);
      return true;
    } else {
      toast.error(data.message);
      return false;
    }
  },
  UpdateRecipient: async (id, name) => {
    let url = "/api/Grievance/UpdateRecipient?id=" + id + "&name=" + name;
    var { data } = await axios.put(url);
    if (data.status == "Success") {
      toast.success(data.message);
      return true;
    } else {
      toast.error(data.message);
      return false;
    }
  },
  GetRecipient: async (id) => {
    let url = "/api/Grievance/GetRecipientList?representativeId=" + id;
    var { data } = await axios.get(url);
    if (data.status == "Success") {
      return data.data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  SaveStep: async (formdata) => {
    let url = "/api/Grievance/SaveStep";
    var { data } = await axios.post(url, formdata, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    });
    if (data.status == "Success") {
      return data;
    } else {
      toast.error("Invalid Request");
      return data;
    }
  },
  UpdateFile: async (data) => {
    let url = "/api/Grievance/UpdateFileData";
    var { data } = await axios.post(url, data);
    if (data.status == "Success") {
      return data;
    } else {
      toast.error("Invalid Request");
      return null;
    }
  },
  SaveComment: async (comment) => {
    let url = "/api/Grievance/SaveComment";
    var { data } = await axios.post(url, comment, {
      headers: {
        Accept: "application/json",
      },
    });

    if (data.status == "Success") {
      return data;
    } else {
      toast.error("Invalid Request");
      return null;
    }
  },
  GetComments: async (grievanceId) => {
    let url = `api/Grievance/GetComments/` + grievanceId;
    var { data } = await axios.get(url);
    if (data.status) {
      return data;
    } else {
      toast.error(data.Message);
      return null;
    }
  },
  SaveAttachments: async (data, grievanceId) => {
    $(".loading").show();
    let url = "/api/Grievance/SaveAttachments/" + grievanceId;
    var { data } = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    });
    $(".loading").hide();
    if (data.status == "Success") {
      return data;
    } else {
      toast.error("Invalid Request");
      return null;
    }
  },
  DuplicateGrievance: async (Object) => {
    let url = "/api/Grievance/DuplicateGrievance";
    var { data } = await axios.post(url, Object);

    if (data.status == "Success") {
      toast.success(data.message);
      return true;
    } else {
      toast.error(data.message);
      return false;
    }
  },
  SaveSnapShot: async (data, stepId) => {
    let url = "/api/Grievance/SaveSnapShot/" + stepId;
    var { data } = await axios.post(url, data);
    if (data.status == "Success") {
      return data;
    } else {
      toast.error("Invalid Request");
      return null;
    }
  },
  UndoElevation: async (grievanceId) => {
    let url = "/api/Grievance/UndoElevation/" + grievanceId;
    var { data } = await axios.post(url, data);
    if (data.status == "Success") {
      return data;
    } else {
      toast.error("Invalid Request");
      return null;
    }
  },
  GetAttachments: async (grievanceId) => {
    let url = `api/Grievance/GetAttachments/` + grievanceId;
    var { data } = await axios.get(url);
    if (data.status) {
      return data;
    } else {
      toast.error(data.Message);
      return null;
    }
  },
  GetPDFData: async (grievanceId, stepId) => {
    let url = `api/Grievance/GetPDFData/${grievanceId}/${stepId}`;
    var { data } = await axios.get(url);
    if (data.status) {
      return data;
    } else {
      toast.error(data.Message);
      return null;
    }
  },
  GetPDFDataByGrievanceId: (grievanceId) => {
    let url = `api/Grievance/GetPDFDataByGrievanceId/${grievanceId}`;
    axios.get(url).then((data) => {
      if (data.status) {
        return data;
      } else {
        toast.error(data.Message);
        return null;
      }
    });
  },
  GetLRRepresentatives: async (regiondId) => {
    let url = `api/Grievance/GetLRRepresentatives/${regiondId}`;
    var { data } = await axios.get(url);
    if (data.status) {
      return data;
    } else {
      toast.error(data.Message);
      return null;
    }
  },
  GetGRTRepresentatives: async () => {
    let url = `api/Grievance/GetGRTRepresentatives`;
    var { data } = await axios.get(url);
    if (data.status) {
      return data;
    } else {
      toast.error(data.Message);
      return null;
    }
  },
  DeleteGrievance: async (grievanceId) => {
    let url = "api/Grievance/DeleteRequest/" + grievanceId;
    var { data } = await axios.delete(url);
    return data;
  },
  DeleteAttachment: async (filename) => {
    let url = "api/Grievance/DeleteAttachment/" + filename;
    var { data } = await axios.delete(url);
    return data;
  },
  SavePDFOnSubmission: async (data, grievanceId) => {
    let url = "/api/Grievance/SaveStepPdfOnSubmission/" + grievanceId;
    var { data } = await axios.post(url, data);
    if (data.status == "Success") {
      return data;
    } else {
      toast.error("Invalid Request");
      return null;
    }
  },

  GetFacilitiesWithNATCA: async () => {
    let url = `api/Grievance/GetFacilitiesWithNATCA`;
    var { data } = await axios.get(url);
    if (data.status) {
      return data;
    } else {
      toast.error(data.Message);
      return null;
    }
  },
};
