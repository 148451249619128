import React, { Component } from "react";
import AUX from "../../../hoc/Aux_";
import axios from "../../Shared/auth-header";
import { toast } from "react-toastify";
import $ from "jquery";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";

import _ from "lodash";
import { Collapse, Card, CardBody, Spinner, Row, Col } from "reactstrap";
import { Dropdown, DropdownButton, Button } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PayfileAction from "../../../store/payfile/action";
import AuthService from "../../Auth/AuthService";
import Service from "../PayfileService";
import PermissionFeature from "../../Permissions/PermissionFeature";

class PayfileIndicator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payfilecardopen: true,
      data: [],
      statusOptions: [],
      editingRowId: null,
    };
    this.columns = this.columns.bind(this);
  }
  componentDidMount = async () => {
    try {
      const tableData = await Service.LoadPayfileIndicators();
      const statusOptions = await Service.LoadStatusOptions();

      this.setState({
        data: tableData,
        statusOptions,
      });
    } catch (error) {
      // Handle the error, e.g., display an error message
    }
  };
  handleEditClick = async (rowId) => {
    await this.setState({ editingRowId: rowId });
  };

  handleCancelClick = () => {
    this.setState({ editingRowId: null });
  };

  handleSaveClick = async (rowData) => {
    try {
      let response = await Service.SaveStatusChange(rowData);

      // Update the state with the new data and exit editing mode
      if (response) {
        this.props.reloadPayfileIndidator();
        this.setState((prevState) => ({
          data: prevState.data.map((row) =>
            row.id === rowData.id ? rowData : row
          ),
          editingRowId: null,
        }));
      }
    } catch (error) {
      // Handle the error, e.g., display an error message
    }
  };

  handleDeleteClick = async (rowId) => {
    try {
      let response = await Service.DeletePayfileIndicatorStatus(rowId);
      if (response) {
        this.props.reloadPayfileIndidator();
        // Update the state by removing the deleted row
        this.setState((prevState) => ({
          data: prevState.data.filter((row) => row.id !== rowId),
        }));
      }
    } catch (error) {
      // Handle the error, e.g., display an error message
    }
  };
  columns = () => [
    {
      dataField: "payperiod",
      text: "Pay Period",
    },
    {
      dataField: "year",
      text: "Year",
    },
    {
      dataField: "status",
      text: "Status",
      formatExtraData: this.state.editingRowId,
      formatter: (cellContent, row) => {
        if (row.id === this.state.editingRowId) {
          return (
            <select
              //className="browser-default custom-select"
              name="Status"
              onChange={(e) => {
                const updatedRow = { ...row, status: e.target.value };
                this.handleSaveClick(updatedRow);
              }}
              className="bg-transparent pl-0 rounded-0 border-left-0 border-top-0 border-right-0 form-control"
            >
              {!_.isEmpty(this.state.statusOptions) &&
                this.state.statusOptions.map((option, index) => {
                  return (
                    <option value={option} selected={row.status == option}>
                      {option}
                    </option>
                  );
                })}
            </select>
          );
          //return (
          //    <DropdownButton title={value || 'Select Status'}>
          //        {this.state.statusOptions.map((option) => (
          //            <Dropdown.Item
          //                key={option}
          //                onClick={() => {
          //                    const updatedRow = { ...row, status: option };
          //                    this.handleSaveClick(updatedRow);
          //                }}
          //            >
          //                {option}
          //            </Dropdown.Item>
          //        ))}
          //    </DropdownButton>
          //);
        } else {
          return row.status; // Display the status text if not in edit mode
        }
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      formatExtraData: this.state.editingRowId,
      hidden: !AuthService.canUpdate(PermissionFeature.PayfileIndicator),
      headerStyle: (colum, colIndex) => {
        return { width: "11px" };
      },
      formatter: (cellContent, row) => {
        if (row.id === this.state.editingRowId) {
          return (
            <>
              <span
                onClick={() => this.handleSaveClick(row)}
                class="text-success c-pointer pr-1"
                id="save"
              >
                <i class="fas fa-check"></i>
              </span>
              <span
                class="text-danger c-pointer pr-1"
                onClick={this.handleCancelClick}
              >
                <i class="fas fa-times"></i>
              </span>
            </>
          );
        } else {
          return (
            <>
              <span
                onClick={() => this.handleEditClick(row.id)}
                class="text-primary c-pointer pr-1"
                id="edit"
              >
                <i class="fas fa-pencil-alt"></i>
              </span>
              {/*<span
                      class="text-danger c-pointer pr-1"
                      onClick={() => this.handleDeleteClick(row.id)}
                  >
                      <i class="fas fa-trash"></i>
                  </span>*/}
            </>
          );
        }
      },
    },
  ];
  render() {
    return (
      <AUX>
        <Card>
          <h5
            className="card-header cursorPointer"
            onClick={() =>
              this.setState({
                payfilecardopen: !this.state.payfilecardopen,
              })
            }
          >
            <i className="fas fa-calendar" aria-hidden="true"></i> Pay Files
            Indicator
            <i
              className={`pull-right float-right ${
                this.state.payfilecardopen
                  ? "fas arrow-icon fa-chevron-down"
                  : "fas arrow-icon fa-chevron-up"
              }`}
            ></i>
          </h5>
          <Collapse isOpen={this.state.payfilecardopen}>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <BootstrapTable
                      keyField="id"
                      data={this.state.data}
                      columns={this.columns()}
                      //cellEdit={cellEditFactory({ mode: 'click', blurToSave: true })}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Collapse>
        </Card>
      </AUX>
    );
  }
}

export default connect(
  (state) => ({
    payfile: state.payfile,
  }),
  {
    ...PayfileAction,
  }
)(PayfileIndicator);
