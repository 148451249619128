import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-300.ttf",
      fontWeight: "light",
    },

    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf", // Bold font
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  GrienvantSecondFooter: {
    textDecoration: "underline",
  },
  container: {
    flexDirection: "row", // Align children horizontally
  },
  mB1: {
    marginBottom: 10,
  },
  boldText: {
    fontFamily: "Open Sans",

    fontWeight: "bold",
  },
  grievanceHeaderLeft: {
    width: "50%",
  },
  pT4: {
    paddingTop: "34px",
  },
  pT3: {
    paddingTop: "21px",
  },
  pT2: {
    paddingTop: "13px",
  },
  grievanceHeaderRight: {
    width: "50%",
    paddingRight: 15,
    paddingTop: 15,
    textAlign: "right",
  },
  headerImageView: {
    marginLeft: 100,
  },
  GrienvantFooter: {
    marginTop: 30,
  },
  image: {
    width: 80,
    height: 80,
  },
  floatLeft: {
    float: "left",
  },
  sec: {
    //marginTop: 80
  },
  Dear: {
    marginTop: 30,
    marginBottom: 30,
  },
  afterSalution: {
    marginTop: 10,
  },
  afterDateText: {
    marginTop: 30,
  },
  dateText: {
    marginTop: 50,
  },
  logoImage: {
    width: 80,
    height: 80,
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    fontFamily: "Open Sans",
    fontSize: 12,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "left",
    marginTop: 20,
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },

  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export function PdfDocument(props) {
  let Address = props.data.facilityAddressObj;
  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.container}>
          <View style={styles.grievanceHeaderLeft}>
            <Image
              style={styles.image}
              src="https://upload.wikimedia.org/wikipedia/en/6/67/NATCA_logo.png"
            />
          </View>
          <View style={styles.grievanceHeaderRight}>
            <Text>NATCA/{props.data.facilityCode}</Text>
            {Address && (
              <>
                <Text>
                  {" "}
                  {Address.address1 +
                    (Address.address2 ? ", " + Address.address2 : "") +
                    (Address.address3 ? ", " + Address.address3 : "")}
                </Text>
                <Text>
                  {Address.city +
                    (Address.state ? ", " + Address.state : "") +
                    (Address.zipcode ? ", " + Address.zipcode : "")}
                </Text>
                <Text>United States</Text>
                <Text style={styles.pT3}>
                  {" "}
                  NATCA Grievance Number: {props.data.grievanceNumber}
                </Text>
              </>
            )}
          </View>
        </View>
        <View style={{ ...styles.container, ...styles.pT4 }}>
          <Text style={styles.boldText}>Date Step 1 Grievance filed: </Text>
          <Text>{props.data.dateFiled}</Text>
        </View>
        <Text style={styles.pT2}>Federal Aviation Administration</Text>
        <Text>{props.data.recipientName}</Text>

        {Address && (
          <>
            <Text style={styles.pT2}>
              {" "}
              {Address.address1 +
                (Address.address2 ? ", " + Address.address2 : "") +
                (Address.address3 ? ", " + Address.address3 : "")}
            </Text>
            <Text>
              {Address.city +
                (Address.state ? ", " + Address.state : "") +
                (Address.zipcode ? ", " + Address.zipcode : "")}
            </Text>
            <Text>United States</Text>
          </>
        )}

        <View style={{ ...styles.container, ...styles.pT2 }}>
          <Text>FAA Grievance Number: </Text>
          <Text>________________</Text>
        </View>

        <View style={{ ...styles.container, ...styles.pT2 }}>
          <Text>Re: Grievance regarding </Text>
          <Text>{props.data.grievanceSubject}</Text>
        </View>

        <Text style={styles.pT2}>{props.data.recipientName},</Text>

        <View style={styles.pT2}>
          <Text>
            This grievance is filed pursuant to Article 9 of the 2016 Collective
            Bargaining Agreement and 5 USC 7103 (a) (9). The Agency's actions
            constitute a violation of the 2016 Agreement, 5 U.S.C. Chapter 71
            and all applicable laws, rules, regulations, and past practice.
          </Text>
        </View>
        <View style={{ ...styles.container, ...styles.pT2 }}>
          <Text style={styles.boldText}>Name of Grievant: </Text>
          <Text>{props.data.grievantName}</Text>
        </View>
        <View style={{ ...styles.container }}>
          <Text style={styles.boldText}>Name of Union Representative: </Text>
          <Text>{props.data.representativeName}</Text>
        </View>
        <View style={{ ...styles.container }}>
          <Text style={styles.boldText}>Request Oral Presentation: </Text>
          <Text>{props.data.isMeetingRequested}</Text>
        </View>
        <View style={styles.container}>
          <Text style={styles.boldText}>Date of violation: </Text>
          <Text>{props.data.voilationDate}</Text>
        </View>

        <View style={{ ...styles.pT2 }}>
          <Text style={{ ...styles.boldText, ...styles.GrienvantSecondFooter }}>
            Nature of the Grievance:
          </Text>
          <Text>{props.data.statementOfFact}</Text>
        </View>

        <View style={{ ...styles.pT2 }}>
          <Text style={{ ...styles.boldText, ...styles.GrienvantSecondFooter }}>
            Remedy Sought:
          </Text>
          <Text>{props.data.remedySought}</Text>
        </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}
