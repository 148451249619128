import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import actionFilter from "../../../../store/advancedfilter/action";
import AuthService from "../../../../modules/Auth/AuthService";
import PermissionFeature from "../../../../modules/Permissions/PermissionFeature";

import { Nav, NavItem, NavLink, Col, Collapse, CardBody } from "reactstrap";
import FE_AdvanceFilter_NewTabs from "../../../F&E/AdvanceFilter/FE_AdvanceFilter_NewTabs";

export class DeskTopSideBar extends Component {
  constructor(props) {
    super(props);
    this.DEBUG = process.env.NODE_ENV === "development";
  }

  memberDetailsSection() {
    return (
      <>
        <div
          className="collapsBtnFirst"
          onClick={() => this.props.onAccordionClick("1")}
        >
          <a>ACCOUNT</a>
          <i
            className={
              this.props.filterState.collaps === "1"
                ? "fas arrow-icon fa-chevron-down"
                : "fas arrow-icon fa-chevron-up"
            }
          ></i>
        </div>
        <Collapse isOpen={this.props.filterState.collaps == "1"}>
          <Nav tabs vertical pills>
            {AuthService.canView(PermissionFeature.MembershipMemberStatus) ? (
              <NavItem className="mb-1 mt-1">
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "9",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("9");
                  }}
                >
                  MEMBER STATUS
                </NavLink>
              </NavItem>
            ) : null}
          </Nav>
        </Collapse>

        <div
          className="collapsBtnFirst"
          onClick={() => this.props.onAccordionClick("2")}
        >
          <a>CONTACT</a>
          <i
            className={
              this.props.filterState.collaps === "2"
                ? "fas arrow-icon fa-chevron-down"
                : "fas arrow-icon fa-chevron-up"
            }
          ></i>
        </div>
        <Collapse isOpen={this.props.filterState.collaps == "2"}>
          <Nav tabs vertical pills>
            {AuthService.canView(PermissionFeature.ContactEmailAddresses) ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "33",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("33");
                  }}
                >
                  EMAIL
                </NavLink>
              </NavItem>
            ) : null}
            {AuthService.canView(PermissionFeature.ContactEmailAddresses) ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "51",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("51");
                  }}
                >
                  PHONE
                </NavLink>
              </NavItem>
            ) : null}
            {AuthService.canView(PermissionFeature.ContactAddresses) ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "26",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("26");
                  }}
                >
                  ADDRESS
                </NavLink>
              </NavItem>
            ) : null}
            {AuthService.canView(
              PermissionFeature.CommunicationAllowFacebook
            ) ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "52",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("52");
                  }}
                >
                  FACEBOOK
                </NavLink>
              </NavItem>
            ) : null}

            {(AuthService.canView(PermissionFeature.CommunicationAllowEmails) ||
              AuthService.canView(
                PermissionFeature.CommunicationAllowPhoneCall
              ) ||
              AuthService.canView(
                PermissionFeature.CommunicationAllowPostalMail
              ) ||
              AuthService.canView(
                PermissionFeature.CommunicationAllowTextMessages
              ) ||
              AuthService.canView(
                PermissionFeature.CommunicationAllowFacebook
              )) && (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "25",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("25");
                  }}
                >
                  SUBSCRIPTIONS
                </NavLink>
              </NavItem>
            )}
          </Nav>
        </Collapse>
        <div
          className="collapsBtnFirst"
          onClick={() => this.props.onAccordionClick("3")}
        >
          <a>DATES</a>
          <i
            className={
              this.props.filterState.collaps === "3"
                ? "fas arrow-icon fa-chevron-down"
                : "fas arrow-icon fa-chevron-up"
            }
          ></i>
        </div>
        <Collapse isOpen={this.props.filterState.collaps == "3"}>
          <Nav tabs vertical pills>
            {" "}
            {AuthService.canView(PermissionFeature.FacilityMemberSeniority) ||
            AuthService.canView(PermissionFeature.DatesEntryOnDutyDate) ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "36",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("36");
                  }}
                >
                  EOD DATE
                </NavLink>
              </NavItem>
            ) : null}
            {(AuthService.canView(PermissionFeature.FacilityMemberSeniority) ||
              AuthService.canView(
                PermissionFeature.DatesServiceComputationDate
              )) && (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "35",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("35");
                  }}
                >
                  SCD
                </NavLink>
              </NavItem>
            )}
            {AuthService.canView(PermissionFeature.FacilityMemberSeniority) ||
            AuthService.canView(PermissionFeature.DatesBargainingUnitDate) ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "38",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("38");
                  }}
                >
                  BU DATE
                </NavLink>
              </NavItem>
            ) : null}
            {AuthService.canView(PermissionFeature.FacilityMemberSeniority) ||
            AuthService.canView(
              PermissionFeature.DatesNatcaBargainingUnitDate
            ) ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "37",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("37");
                  }}
                >
                  NATCA BU DATE
                </NavLink>
              </NavItem>
            ) : null}
            {AuthService.canView(PermissionFeature.DatesJoinedUnionDate) ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "39",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("39");
                  }}
                >
                  UNION START DATE
                </NavLink>
              </NavItem>
            ) : null}
            {AuthService.canView(PermissionFeature.DatesJoinedUnionDate) ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "40",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("40");
                  }}
                >
                  UNION JOIN DATE
                </NavLink>
              </NavItem>
            ) : null}
            {AuthService.canView(
              PermissionFeature.DatesPlannedRetirementDate
            ) ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "34",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("34");
                  }}
                >
                  PLANNED RETIREMENT
                </NavLink>
              </NavItem>
            ) : null}
            {AuthService.canView(
              PermissionFeature.DatesMandatoryRetirementDate
            ) ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "50",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("50");
                  }}
                >
                  MANDATORY RETIREMENT
                </NavLink>
              </NavItem>
            ) : null}
            {AuthService.canView(PermissionFeature.DatesRetirementDate) ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "41",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("41");
                  }}
                >
                  RETIREMENT
                </NavLink>
              </NavItem>
            ) : null}
          </Nav>
        </Collapse>

        <div
          className="collapsBtnFirst"
          onClick={() => this.props.onAccordionClick("4")}
        >
          <a>FINANCIAL</a>
          <i
            className={
              this.props.filterState.collaps === "4"
                ? "fas arrow-icon fa-chevron-down"
                : "fas arrow-icon fa-chevron-up"
            }
          ></i>
        </div>
        <Collapse isOpen={this.props.filterState.collaps == "4"}>
          <Nav tabs vertical pills>
            {AuthService.canView(PermissionFeature.FinancialHourlySalary) ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "31",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("31");
                  }}
                >
                  HOURLY SALARY
                </NavLink>
              </NavItem>
            ) : null}

            {AuthService.canView(PermissionFeature.FinancialYearlySalary) ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "32",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("32");
                  }}
                >
                  ANNUAL SALARY
                </NavLink>
              </NavItem>
            ) : null}
            {AuthService.canView(PermissionFeature.FinancialPacDonation) && (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "17",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("17");
                  }}
                >
                  PAC
                </NavLink>
              </NavItem>
            )}
            {AuthService.canView(PermissionFeature.FinancialDrfDonation) && (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "18",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("18");
                  }}
                >
                  DRF
                </NavLink>
              </NavItem>
            )}
            {AuthService.canView(PermissionFeature.Finance) && (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "53",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("53");
                  }}
                >
                  DUES
                </NavLink>
              </NavItem>
            )}
          </Nav>
        </Collapse>
        <div
          className="collapsBtnFirst"
          onClick={() => this.props.onAccordionClick("5")}
        >
          <a>JOBS</a>
          <i
            className={
              this.props.filterState.collaps === "5"
                ? "fas arrow-icon fa-chevron-down"
                : "fas arrow-icon fa-chevron-up"
            }
          ></i>
        </div>
        <Collapse isOpen={this.props.filterState.collaps == "5"}>
          <Nav tabs vertical pills>
            {AuthService.canView(PermissionFeature.Region) ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "11",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("11");
                  }}
                >
                  REGION
                </NavLink>
              </NavItem>
            ) : null}
            {AuthService.canView(PermissionFeature.Facility) ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "8",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("8");
                  }}
                >
                  FACILITY
                </NavLink>
              </NavItem>
            ) : null}
            {AuthService.canView(PermissionFeature.FinancialPayGrade) ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "23",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("23");
                  }}
                >
                  PAY GRADE
                </NavLink>
              </NavItem>
            ) : null}

            {AuthService.canView(PermissionFeature.FinancialEmployer) ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "43",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("43");
                  }}
                >
                  EMPLOYER
                </NavLink>
              </NavItem>
            ) : null}
            {AuthService.canView(PermissionFeature.FinancialCareerLevel) ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "22",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("22");
                  }}
                >
                  CAREER LEVEL
                </NavLink>
              </NavItem>
            ) : null}

            {AuthService.canView(PermissionFeature.FinancialUnit) ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "20",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("20");
                  }}
                >
                  UNIT
                </NavLink>
              </NavItem>
            ) : null}
            {AuthService.canView(
              PermissionFeature.FinancialFacilityAddressRoutingSymbol
            ) ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "29",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("29");
                  }}
                >
                  FACILITY ROUTING SYMBOL
                </NavLink>
              </NavItem>
            ) : null}
            {AuthService.canView(PermissionFeature.PersonalInfoFlsaCategory) ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "24",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("24");
                  }}
                >
                  FLSA CATEGORY
                </NavLink>
              </NavItem>
            ) : null}

            {AuthService.canView(
              PermissionFeature.FinancialOccupationSeries
            ) ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "21",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("21");
                  }}
                >
                  OCCUPATION SERIES
                </NavLink>
              </NavItem>
            ) : null}

            {AuthService.canView(
              PermissionFeature.FinancialOrganizationalCode
            ) ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "30",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("30");
                  }}
                >
                  ORG. CODE
                </NavLink>
              </NavItem>
            ) : null}

            {AuthService.canView(
              PermissionFeature.FinancialDutyStationDescription
            ) ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "19",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("19");
                  }}
                >
                  DUTY STATION
                </NavLink>
              </NavItem>
            ) : null}
          </Nav>
        </Collapse>

        <div
          className="collapsBtnFirst"
          onClick={() => this.props.onAccordionClick("6")}
        >
          <a>MEMBERSHIP</a>
          <i
            className={
              this.props.filterState.collaps === "6"
                ? "fas arrow-icon fa-chevron-down"
                : "fas arrow-icon fa-chevron-up"
            }
          ></i>
        </div>
        <Collapse isOpen={this.props.filterState.collaps == "6"}>
          <Nav tabs vertical pills>
            {AuthService.canView(PermissionFeature.PersonalInfoMemberNumber) ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "12",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("12");
                  }}
                >
                  MEMBER NUMBER
                </NavLink>
              </NavItem>
            ) : null}
            {AuthService.canView(PermissionFeature.MembershipMemberType) ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "10",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("10");
                  }}
                >
                  MEMBER TYPE
                </NavLink>
              </NavItem>
            ) : null}
          </Nav>
        </Collapse>
        <div
          className="collapsBtnFirst"
          onClick={() => this.props.onAccordionClick("7")}
        >
          <a>PERSONAL INFO</a>
          <i
            className={
              this.props.filterState.collaps === "7"
                ? "fas arrow-icon fa-chevron-down"
                : "fas arrow-icon fa-chevron-up"
            }
          ></i>
        </div>
        <Collapse isOpen={this.props.filterState.collaps == "7"}>
          <Nav tabs vertical pills>
            {AuthService.canView(
              PermissionFeature.PersonalInfoEmployeeCommonId
            ) ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "7",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("7");
                  }}
                >
                  EMPLOYEE COMMON ID
                </NavLink>
              </NavItem>
            ) : null}

            {AuthService.canView(PermissionFeature.PersonalInfoFirstName) ||
            AuthService.canView(PermissionFeature.PersonalInfoLastName) ||
            AuthService.canView(
              PermissionFeature.PersonalInfoPreviousLastName
            ) ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "14",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("14");
                  }}
                >
                  NAME
                </NavLink>
              </NavItem>
            ) : null}

            {AuthService.canView(PermissionFeature.PersonalInfoGender) ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "16",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("16");
                  }}
                >
                  GENDER
                </NavLink>
              </NavItem>
            ) : null}

            {AuthService.canView(PermissionFeature.PersonalInfoShirtSize) ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "15",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("15");
                  }}
                >
                  SHIRT SIZE
                </NavLink>
              </NavItem>
            ) : null}

            {AuthService.canView(PermissionFeature.PersonalInfoDateOfBirth) ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "4",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("4");
                  }}
                >
                  AGE
                </NavLink>
              </NavItem>
            ) : null}

            {AuthService.canView(PermissionFeature.PersonalInfoVetStatus) && (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "27",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("27");
                  }}
                >
                  VETERAN
                </NavLink>
              </NavItem>
            )}
            {AuthService.canView(
              PermissionFeature.PersonalInfoRetiredMilitaryController
            ) && (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "28",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("28");
                  }}
                >
                  RETIRED MILLITARY
                </NavLink>
              </NavItem>
            )}
          </Nav>
        </Collapse>
      </>
    );
  }
  render() {
    const { isEditFilterModal } = this.props;
    return (
      <Col
        className={`spac-remove modalScroll sidebar-adv borderNone ${
          isEditFilterModal ? "modalDefaultHeight" : ""
        }`}
        xs="3"
        sm="3"
        md="3"
        xl="3"
        lg="3"
      >
        {AuthService.canView(PermissionFeature.Directory) &&
        AuthService.canView(PermissionFeature.Member)
          ? this.memberDetailsSection()
          : null}
        <div
          className="collapsBtnFirst"
          onClick={() => this.props.onAccordionClick("8")}
        >
          <a>POSITIONS</a>
          <i
            className={
              this.props.filterState.collaps === "8"
                ? "fas arrow-icon fa-chevron-down"
                : "fas arrow-icon fa-chevron-up"
            }
          ></i>
        </div>
        <Collapse isOpen={this.props.filterState.collaps == "8"}>
          <Nav tabs vertical pills>
            {AuthService.canView(PermissionFeature.Teams) && (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "54",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("54");
                  }}
                >
                  TEAMS
                </NavLink>
              </NavItem>
            )}
            {AuthService.canView(PermissionFeature.MembersPositions) && (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "42",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("42");
                  }}
                >
                  POSITION NAME
                </NavLink>
              </NavItem>
            )}
            {AuthService.canView(PermissionFeature.MembersPositions) && (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "55",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("55");
                  }}
                >
                  ACTIVE DATES
                </NavLink>
              </NavItem>
            )}
            {AuthService.canView(PermissionFeature.MembersPositions) && (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.props.filterState.activeTab === "44",
                  })}
                  onClick={() => {
                    this.props.setActiveTab("44");
                  }}
                >
                  POSITION TYPE
                </NavLink>
              </NavItem>
            )}
          </Nav>
        </Collapse>
        {/* FE Module Work Started Here */}
        {AuthService.canView(PermissionFeature.FEAdmin) && (
          <>
            <div
              className="collapsBtnFirst"
              onClick={() => this.props.onAccordionClick("9")}
            >
              <a>EQUIPMENT</a>
              <i
                className={
                  this.props.filterState.collaps === "9"
                    ? "fas arrow-icon fa-chevron-down"
                    : "fas arrow-icon fa-chevron-up"
                }
              ></i>
            </div>
            <Collapse isOpen={this.props.filterState.collaps == "9"}>
              <Nav tabs vertical pills>
                {AuthService.canView(PermissionFeature.FEAccountingStatus) && (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          this.props.filterState.activeTab ==
                          FE_AdvanceFilter_NewTabs.MEM_AF_INV_STATUS,
                      })}
                      onClick={() => {
                        this.props.setActiveTab(
                          FE_AdvanceFilter_NewTabs.MEM_AF_INV_STATUS
                        );
                      }}
                    >
                      STATUS
                    </NavLink>
                  </NavItem>
                )}
                {AuthService.canView(PermissionFeature.FEAccountingBudgetID) ? (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          this.props.filterState.activeTab ==
                          FE_AdvanceFilter_NewTabs.MEM_AF_INV_BUDGETLINES,
                      })}
                      onClick={() => {
                        this.props.setActiveTab(
                          FE_AdvanceFilter_NewTabs.MEM_AF_INV_BUDGETLINES
                        );
                      }}
                    >
                      BUDGET LINE
                    </NavLink>
                  </NavItem>
                ) : null}
                {AuthService.canView(PermissionFeature.FEAccountingCost) && (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          this.props.filterState.activeTab ==
                          FE_AdvanceFilter_NewTabs.MEM_AF_INV_BOOKVAL,
                      })}
                      onClick={() => {
                        this.props.setActiveTab(
                          FE_AdvanceFilter_NewTabs.MEM_AF_INV_BOOKVAL
                        );
                      }}
                    >
                      BOOK VALUE
                    </NavLink>
                  </NavItem>
                )}
                {AuthService.canView(PermissionFeature.FEAccountingType) && (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          this.props.filterState.activeTab ==
                          FE_AdvanceFilter_NewTabs.MEM_AF_INV_TYPE,
                      })}
                      onClick={() => {
                        this.props.setActiveTab(
                          FE_AdvanceFilter_NewTabs.MEM_AF_INV_TYPE
                        );
                      }}
                    >
                      TYPE
                    </NavLink>
                  </NavItem>
                )}
                {AuthService.canView(
                  PermissionFeature.FEAccountingDescription
                ) && (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          this.props.filterState.activeTab ==
                          FE_AdvanceFilter_NewTabs.MEM_AF_INV_DESC,
                      })}
                      onClick={() => {
                        this.props.setActiveTab(
                          FE_AdvanceFilter_NewTabs.MEM_AF_INV_DESC
                        );
                      }}
                    >
                      DESCRIPTION
                    </NavLink>
                  </NavItem>
                )}
              </Nav>
            </Collapse>
          </>
        )}
        <div
          className="collapsBtnFirst"
          onClick={() => this.props.onAccordionClick("10")}
        >
          <a>CONGRESSIONAL</a>
          <i
            className={
              this.props.filterState.collaps === "10"
                ? "fas arrow-icon fa-chevron-down"
                : "fas arrow-icon fa-chevron-up"
            }
          ></i>
        </div>
        <Collapse isOpen={this.props.filterState.collaps == "10"}>
          <Nav tabs vertical pills>
            <NavItem>
              <NavLink
                className={classnames({
                  active: this.props.filterState.activeTab === "56",
                })}
                onClick={() => {
                  this.props.setActiveTab("56");
                }}
              >
                DISTRICT
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
        {/* <div
          className="collapsBtn"
          onClick={() => this.props.onAccordionClick("3")}
        >
          <a>TRAINING</a>
          <i
            className={
              this.props.filterState.collaps === "3"
                ? "fas arrow-icon fa-chevron-down"
                : "fas arrow-icon fa-chevron-up"
            }
          ></i>
        </div>
        <Collapse isOpen={this.props.filterState.collaps == "3"}>
          <Nav tabs vertical pills>
            <NavItem>
              <NavLink className="active">ADDRESS</NavLink>
            </NavItem>
            <NavItem>
              <NavLink>CAREER LEVEL</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
        <div
          className="collapsBtn"
          onClick={() => this.props.onAccordionClick("4")}
        >
          <a>FINANCES</a>{" "}
          <i
            className={
              this.props.filterState.collaps === "4"
                ? "fas arrow-icon fa-chevron-down"
                : "fas arrow-icon fa-chevron-up"
            }
          ></i>
        </div>
        <Collapse isOpen={this.props.filterState.collaps == "4"}>
          <Nav tabs vertical pills>
            <NavItem>
              <NavLink className="active">ADDRESS</NavLink>
            </NavItem>
            <NavItem>
              <NavLink>CAREER LEVEL</NavLink>
            </NavItem>
          </Nav>
        </Collapse> */}
      </Col>
    );
  }
}

export default connect((state) => ({ filterState: state.advancedFilter }), {
  ...actionFilter,
})(DeskTopSideBar);
