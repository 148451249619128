import React, { Component } from "react";
import _ from "lodash";
import { Field, reduxForm } from "redux-form";
import { Row, Col, Form } from "reactstrap";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import actionFilter from "../../../store/advancedfilter/action";
import axios from "../../Shared/auth-header";

class CongressionalDistrict extends Component {
  constructor(props) {
    super(props);
    this.state = {
      states: [],
    };
    this.loadState = this.loadState.bind(this);
  }
  componentDidMount() {
    this.loadState();
  }
  async loadState() {
    let url = "/api/Member/StatesWithName";
    var { data } = await axios.get(url);
    if (data.status && data.status == "Success") {
      this.setState({ states: data.data });
    } else {
      toast.error("Error occured while fetching states, please try later!");
      return null;
    }
  }
  onChange(item) {
    var congressionaldistrictIndex = _.findIndex(
      this.props.filterState.filterData.congressionalDistrict,
      (o) => {
        return o.cd === item.cd;
      }
    );
    item.checked = !this.props.filterState.filterData.congressionalDistrict[
      congressionaldistrictIndex
    ].checked;
    this.props.filterState.filterData.congressionalDistrict[congressionaldistrictIndex] = item;
    this.props.setFilterData({
      ...this.props.filterState.filterData,
    });
  }
  chechboxes(item) {
    return (
      <li class="chechbox-list_item" key={"cdliitem-" + item.cd}>
        <span
          className="card-text custom-checkbox ml-3 mr-4"
          key={"cditem-" + item.cd}
        >
          <input
            type="checkbox"
            htmlFor={"cditem-" + item.cd}
            value={item.cd}
            checked={item.checked}
            className="custom-control-input"
            id={"cditem-" + item.cd}
            name={"cditem-" + item.cd}
            onChange={() => this.onChange(item)}
          />

          <label
            className="custom-control-label"
            style={{ color: this.props.isMobile ? "white" : null }}
            htmlFor={"cditem-" + item.cd}
          >
            {item.cd}
            <small className="totalCount">{`(${item.total})`}</small>
          </label>
        </span>
      </li>
    );
  }

  render() {
    let congressionalDistrict =
      !_.isEmpty(this.props.filterState.filterData) &&
      this.props.filterState.filterData.congressionalDistrict
        ? this.props.filterState.filterData.congressionalDistrict
        : null;
    let filterState =
      congressionalDistrict &&
      this.state.states &&
      !_.isEmpty(this.state.states)
        ? this.state.states.filter((state) =>
            congressionalDistrict.some((cd) => cd.cd.startsWith(state.state))
          )
        : null;
    return (
      <>
        {this.props.isMobile !== true ? (
          <>
            <h4>Congressional District</h4>
            <hr />
          </>
        ) : (
          ""
        )}
        <div class="filter-fields-scroll">
          <ul class="chechbox-list">
            {congressionalDistrict &&
              congressionalDistrict
                .filter((cd) => cd.cd == "Not Specified")
                .map((item) => this.chechboxes(item))}
            {filterState &&
              congressionalDistrict &&
              filterState.map((s) => (
                <>
                  <li class="chechbox-list_item" key={"cdliitem-" + s.fullName}>
                    <h6 class="my-1">{s.fullName}</h6>
                  </li>
                  {congressionalDistrict
                    .filter((cd) => cd.cd.startsWith(s.state))
                    .map((item) => this.chechboxes(item))}
                </>
              ))}
            {filterState && congressionalDistrict && (
              <>
                <li class="chechbox-list_item" key={"cdliitem-Other"}>
                  <h6 class="my-1">Other</h6>
                </li>
                {congressionalDistrict
                  .filter(
                    (cd) =>
                      !filterState.some(
                        (state) =>
                          cd.cd.startsWith(state.state) ||
                          cd.cd == "Not Specified"
                      )
                  )
                  .map((item) => this.chechboxes(item))}
              </>
            )}
          </ul>
        </div>
        <div className="height-scroll"></div>
      </>
    );
  }
}

export default connect((state) => ({ filterState: state.advancedFilter }), {
  ...actionFilter,
})(CongressionalDistrict);
