import React, { Component, Fragment } from "react";
import AUX from "../../hoc/Aux_";
import {
  Button,
  ButtonGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Route, NavLink } from "react-router-dom";
import Users from "../Admin/Users/Users";
import Facilities from "./Facility/Facilities";
import Areas from "./Areas/Areas";
import Regions from "./Region/Region";
import Services from "./RFTeamsService";
import $ from "jquery";
import _ from "lodash";
import Teams from "./Teams/Teams";
import AuthService from "../../modules/Auth/AuthService";
import { isMobile } from "../Shared/Helpers";
import TopSearchBar from "../../../src/components/Layout/TopSearchBar";
import { MDBInput } from "mdbreact";
import EditRegion from "./Region/EditRegion";
import { connect } from "react-redux";
import OfficerButton from "../Dashboard/FacilityDashboard/OfficerButton";

import actionFilter from "../../store/advancedfilter/action";
import facilityActions from "../../store/facility/action";
import permissionActions from "../../store/permission/action";
import PermissionFeature from "../Permissions/PermissionFeature";
import SpecialTeamPermission from "../Permissions/General/SpecialTeamPermission";
import MailGunMembersListModal from "../Shared/MailGunMembersListModal";
import ManageAreasModal from "../Directory/Areas/ManageAreasModal";
import FacilityTeamEmailListNameModal from "../Shared/FacilityTeamEmailListNameModal";
import MemberBucket from "../AdvanceSearch/SavedSearches/MemberBucket";
import UpdateEmailList from "../AdvanceSearch/SavedSearches/UpdateEmailList";

import saveSearchAction from "../../store/saveSearch/action";

import * as Constants from "../AdvanceSearch/SavedSearches/Constants";
import axios from "../Shared/auth-header";
import { toast } from "react-toastify";
import AdvancedSavedSearch from "../AdvanceSearch/SavedSearches/savedSearch.component";
import * as Permissions from "../Permissions/PermissionValues";
import * as SavedSearchTypes from "../AdvanceSearch/SavedSearches/SavedSearchTypes";
import moment from "moment";
import Region from "../AdvanceSearch/MemberDetails/Region";
import MailGunEmailTypes from "../../modules/Email/Enums/MailGunEmailTypes";
import SavedSearchFiltersList from "../AdvanceSearch/SavedSearches/Listing/SavedSearchFiltersList";
import FiltersHeader from "../AdvanceSearch/SavedSearches/Listing/FiltersHeader";

let timeOutTask;
class Directory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: props.location.tab || null,
      tabName: props.location.name || "",
      reload: Math.random(),
      emailListExists: null,
      showEmailMembersModal: false,
      showManageAreasModal: false,
      showAssignMemberModal: false,
      teamsCreateStatus: null,
      showTeamsCreateStatus: false,
      bulkProgress: true,
      refreshRF: false,
      emailListId: -1,
      showCreateMgListModal: false,
      userData: {},
    };
    this.editSearchDialog = React.createRef();
    this.handleTabChange = this.handleTabChange.bind(this);
    this.DeleteEmailList = this.DeleteEmailList.bind(this);
    this.getTeamCreateStatus = this.getTeamCreateStatus.bind(this);
    this.setFalseRefreshRF = this.setFalseRefreshRF.bind(this);
    this.facilitiesComponent = this.facilitiesComponent.bind(this);
    this.regionsComponent = this.regionsComponent.bind(this);
    this.tabsSettings = [
      { name: "Region", component: this.regionsComponent },
      { name: "Facility", component: this.facilitiesComponent },
      { name: "Members", component: Users },
      { name: "Filters", component: this.filtersListComponent }, // New work Saved Filters
    ];
    this.confirm = () => {
      this.confirmed = () => {
        // if (this.state.uid === 0) {
        //     return false;
        // }
        // authAxios
        //     .delete("/api/Region/" + this.state.uid)
        //     .then(({ data }) => {
        //         if (data.status && data.status == "Success") {
        //             toast.success("Region Deleted Successfuly.");
        //             this.setState({ delId: 0 });
        //             $(".cancelModal").trigger("click");
        //             this.props.history.push({ pathname: "/directory", tab: Region, name: "Regions" });
        //             //this.getRegions();
        //         } else {
        //             toast.error(data.message);
        //         }
        //     })
        //     .catch(function (error) {
        //         console.log(error);
        //     });
      };
      return this;
    };

    //   this.RemotePagination = (
    //     data,
    //     page,
    //     sizePerPage,
    //     onTableChange,
    //     totalSize,
    //     showTotal,
    //     rowEvents,
    //     selectedFields
    //   ) => {
    //     // if (selectedFields && selectedFields.length > 0) {
    //     //   this.setColumnsHeaders(selectedFields);
    //     // }
    //     const { columnsToView } = this.state;
    //     return (
    //       <div>
    //         <BootstrapTable
    //           hover
    //           remote
    //           keyField="id"
    //           data={data}
    //           columns={columnsToView}
    //           defaultSortDirection="asc"
    //           selectRow={selectRow}
    //           pagination={paginationFactory({
    //             page,
    //             sizePerPage,
    //             totalSize,
    //             showTotal,
    //           })}
    //           onTableChange={onTableChange}
    //           rowEvents={rowEvents}
    //           id="membersListing"
    //         />
    //       </div>
    //     );
    //   };
  }

  changeModalState = () => {
    this.setState({ showModal: true });
  };
  setFalseRefreshRF = () => {
    this.state.refreshRF = false;
    this.setState({ refreshRF: false });
  };
  getTeamCreateStatus = async (byUser, refresh) => {
    let data = await Services.GetTeamsCreateStatus(byUser);
    if (data) {
      let TCSObj = data.result;
      this.state.teamsCreateStatus = TCSObj;
      if (data.result.status == "Progress") {
        this.state.teamsCreateStatus.progress = Math.round(
          ((TCSObj.totalparse + TCSObj.totalignore) * 95) / TCSObj.totalteams
        );
        if (byUser) {
          await this.setState({ showTeamsCreateStatus: true });
        }

        let func = this.getTeamCreateStatus;
        timeOutTask = setTimeout(function() {
          func(false, true);
        }, 30000);
        this.state.bulkProgress = true;
      } else {
        this.state.teamsCreateStatus.progress = 100;
        this.state.refreshRF = refresh;
        this.state.bulkProgress = false;
      }
      await this.setState({
        teamsCreateStatus: this.state.teamsCreateStatus,
        refreshRF: this.state.refreshRF,
        bulkProgress: this.state.bulkProgress,
      });
    } else {
      await this.setState({
        teamsCreateStatus: null,
        refreshRF: false,
        bulkProgress: false,
      });
    }
  };
  handleTabChange = (e) => {
    const currentLocation = window.location.pathname;
    const currTab = this.tabsSettings.find(
      (t) => t.name == e.currentTarget.dataset.tab
    );
    this.setState({ tab: currTab.component, reload: Math.random() });
    $(".d-tabs").removeClass("directory-brand-active");
    e.target.className = e.target.className + " directory-brand-active";

    if (e.currentTarget.dataset.tab == "Filters") {
      this.props.history.push("/directory/myfilters");
    } else {
      this.props.history.push(
        "/directory/" + e.currentTarget.dataset.tab.toLowerCase()
      );
    }

    this.props.history.location.lastPlace = currentLocation;
  };
  facilitiesComponent() {
    if (AuthService.canView(PermissionFeature.Facility)) {
      return (
        <Facilities
          {...this.props}
          bulkProgress={this.state.bulkProgress}
          refreshRF={this.state.refreshRF}
          setFalseRefreshRF={this.setFalseRefreshRF}
        />
      );
    }
    return Facilities;
  }
  regionsComponent() {
    if (AuthService.canView(PermissionFeature.Region)) {
      return (
        <Regions
          {...this.props}
          bulkProgress={this.state.bulkProgress}
          refreshRF={this.state.refreshRF}
          setFalseRefreshRF={this.setFalseRefreshRF}
        />
      );
    }
    return Regions;
  }
  filtersListComponent = () => {
    if (AuthService.canView(PermissionFeature.MembersFilter)) {
      return (
        <SavedSearchFiltersList /*redirectToMembers={this.redirect} */
          {...this.props}
        />
      );
    }
  };
  onAddFacilityClick = (e) => {
    this.props.getFacilityTypeOptions();
    this.props.getFacilityRegionsOptions();
  };

  onEditFacilityClick = (e) => {
    this.props.getFacilityTypeOptions();
    this.props.getFacilityRegionsOptions();
  };

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.props.location.pathname !== "/directory/facility" &&
      this.props.location.pathname !== "/directory/region"
    ) {
      clearTimeout(timeOutTask);
    }
    if (
      prevProps.location.pathname !== "/directory/facility" &&
      prevProps.location.pathname !== "/directory/region" &&
      (this.props.location.pathname === "/directory/facility" ||
        this.props.location.pathname === "/directory/region") &&
      AuthService.canAdd(PermissionFeature.TeamsTeams)
    ) {
      await this.getTeamCreateStatus(false, false);
      if (
        this.state.teamsCreateStatus &&
        this.state.teamsCreateStatus.status == "Progress"
      ) {
        this.setState({
          showTeamsCreateStatus: true,
        });
      }
    }
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.routeFromAdFilterOnCancel &&
      this.props.location.pathname != prevProps.location.pathname &&
      this.props.location.pathname == "/directory/myfilters" &&
      this.state.tabName != "Filters"
    ) {
      this.setState({
        tab: this.tabsSettings.find((t) => t.name == this.props.name).component,
        tabName: this.props.name,
        reload: Math.random(),
      });
      $(".d-tabs").removeClass("directory-brand-active");
      $(".FiltersList").addClass("directory-brand-active");
    }

    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.routeFromDDLFiler &&
      this.props.location.pathname != prevProps.location.pathname &&
      this.props.location.pathname == "/directory/members" &&
      this.props.history.location &&
      this.props.history.location.link == "/directoryTabs"
    ) {
      this.setState({
        tab: this.tabsSettings.find((t) => t.name == this.props.name).component,
        tabName: this.props.name,
        reload: Math.random(),
      });
      $(".d-tabs").removeClass("directory-brand-active");
      $(".Members").addClass("directory-brand-active");
    }
    //Handle click for Dropdowns' "Saved Filters List" link
    if (
      this.props.location &&
      this.props.location.pathname === "/directory/myfilters" &&
      this.state.tabName != "Filters"
    ) {
      this.setState({
        tab: SavedSearchFiltersList,
        tabName: "Filters",
        reload: Math.random(),
      });
      this.props.history.push("/directory/myfilters");
      $(".d-tabs").removeClass("directory-brand-active");
      $(".FiltersList").addClass("directory-brand-active");
    }
    //Handle click for Dropdowns'item click
    // if (
    //   this.props.location &&
    //   this.props.location.pathname.includes("filters") &&
    //   this.state.tabName != "Filters"
    // ) {
    //   this.setState({
    //     tab: SavedSearchFiltersList,
    //     tabName: "Filters",
    //     reload: Math.random(),
    //   });
    //   this.props.history.push(this.props.location.pathname);
    //   $(".d-tabs").removeClass("directory-brand-active");
    //   $(".FiltersList").addClass("directory-brand-active");
    // }
  }
  createMailingList = () => {
    this.setState({ showCreateMgListModal: true });
  };
  toggleCreateMgListModal = () => {
    this.setState({ showCreateMgListModal: !this.state.showCreateMgListModal });
  };
  async componentDidMount() {
    // this.props.setDirectoryMenuState(true);
    let featureId = 0;
    switch (this.props.name) {
      case "Region":
        featureId = PermissionFeature.Region;
        break;
      case "Facility":
        featureId = PermissionFeature.Facility;
        break;
      case "Members":
        featureId = PermissionFeature.Member;
        break;
      case "Filters":
        featureId = PermissionFeature.MembersFilter;
        break;
      default:
        break;
    }
    if (this.props.tab && AuthService.canView(featureId)) {
      this.setState({
        tab: this.tabsSettings.find((t) => t.name == this.props.name).component,
        tabName: this.props.name,
        reload: Math.random(),
      });
      $(".d-tabs").removeClass("directory-brand-active");
      $("." + this.props.name).addClass("directory-brand-active");
    } else if (this.props.location.pathname == "/directory/myfilters") {
      this.props.history.push("/directory/myfilters");
    } else if (
      this.props.location.pathname.indexOf("/view") < 0 &&
      this.props.location.pathname.indexOf("/userAction") < 0 &&
      this.props.location.pathname.indexOf("/myProfile") < 0
    ) {
      if (AuthService.canView(PermissionFeature.Region)) {
        this.setState({
          tab: this.regionsComponent,
          tabName: "Region",
          reload: Math.random(),
        });
        $(".d-tabs").removeClass("directory-brand-active");
        $(".Region").addClass("directory-brand-active");
        this.props.history.push("/directory/region");
      } else if (AuthService.canView(PermissionFeature.Facility)) {
        this.setState({
          tab: this.facilitiesComponent,
          tabName: "Facilities",
          reload: Math.random(),
        });
        $(".d-tabs").removeClass("directory-brand-active");
        $(".Facilities").addClass("directory-brand-active");
        this.props.history.push("/directory/facility");
      }
    }

    if (
      this.props.location.pathname
        .toLowerCase()
        .indexOf("/directory/facility/view") != -1
    ) {
      let facilityId = 0;
      if (this.props.match.params.id !== undefined) {
        facilityId = parseInt(this.props.match.params.id);
      }
      if (facilityId > 0) {
        let url = `/api/FacilityRegionEmail/EntityEmailExists?entityId=${facilityId}&entityType=${Constants.FACILITY_EMAIL_TYPE_ID}`;
        $(".loading").show();
        axios.get(url).then(({ data }) => {
          $(".loading").hide();
          if (data.exists != undefined) {
            this.setState({
              emailListExists: data.exists,
              mgListAddress: data.mgListAddress,
              emailListId: data.emailListId,
            });
          }
        });
      }
    }
    if (
      (this.props.location.pathname === "/directory/facility" ||
        this.props.location.pathname === "/directory/region") &&
      AuthService.canAdd(PermissionFeature.TeamsTeams)
    ) {
      await this.getTeamCreateStatus(false, false);
      if (
        this.state.teamsCreateStatus &&
        this.state.teamsCreateStatus.status == "Progress"
      ) {
        this.setState({ showTeamsCreateStatus: true });
      }
    }
    this.setState({ userData: AuthService.getProfile().memberData });
  }
  componentWillUnmount() {
    // this.props.setDirectoryMenuState(false);
    clearTimeout(timeOutTask);
  }

  canCreateFacilityEmail = () => {
    return AuthService.canAdd(PermissionFeature.EmailListAdmin); //AuthService.canAdd(PermissionFeature.EmailListsLists);
  };

  canConfigureFacilityEmail = () => {
    return (
      AuthService.canView(PermissionFeature.EmailListAdmin) ||
      AuthService.canUpdate(PermissionFeature.EmailListAdmin)
    );
  };

  canViewSavedSearch = () => {
    //console.log(this.props.location);
    let isMemberPath = false;
    if (this.props.location && this.props.location.pathname) {
      isMemberPath =
        this.props.location.pathname.indexOf("/directory") != -1 ||
        this.props.location.pathname.indexOf("/dashboard") != -1 ||
        this.props.location.pathname.indexOf("/filters") != -1 ||
        this.props.location.pathname.indexOf("/allfilters") != -1;
    }

    return AuthService.canView(Permissions.Saved_Search) && isMemberPath;
  };

  createFacilityEmail = (emailAlias) => {
    let facilityId = this.props.match.params.id;
    if (facilityId > 0) {
      $(".loading").show();
      let body = {
        entityId: facilityId,
        entityType: Constants.FACILITY_EMAIL_TYPE_ID,
      };
      let url = `/api/FacilityRegionEmail/CreateEntityEmail?emailAlias=${emailAlias}&entityId=${facilityId}&entityType=${Constants.FACILITY_EMAIL_TYPE_ID}`;

      axios.post(url, []).then(({ data }) => {
        $(".loading").hide();
        if (
          data &&
          data.status != undefined &&
          data.status.trim().toLowerCase() === "success"
        ) {
          toast.success("Email list created successfully.");
          this.setState({
            emailListExists: true,
            mgListAddress: data.mgListAddress,
            emailListId: data.emailListId,
          });
        } else {
          toast.error(data.message);
        }
      });
    }
  };

  DeleteEmailList = () => {
    $(".loading").show();
    let url = `/api/FacilityRegionEmail/DeleteEmailList?emailListId=${this.state.emailListId}`;
    axios.delete(url).then(({ data: response }) => {
      $(".loading").hide();
      if (response.status === "success") {
        toast.success("Email list removed successfully!");
        this.setState({
          emailListExists: false,
        });
      } else {
        toast.error(response.message);
      }
    });
  };
  configureFacilityEmail = () => {
    this.setState({
      showEmailMembersModal: true,
    });
  };

  openManageAreasModal = () => {
    this.setState({
      showManageAreasModal: true,
    });
  };

  mailGunMemberListClosed = () => {
    this.setState({
      showEmailMembersModal: false,
    });
  };

  closeManageAreasModal = () => {
    this.setState({
      showManageAreasModal: false,
    });
  };

  assignMemberListClosed = () => {
    this.setState({
      showAssignMemberModal: false,
    });
  };

  // setSavedSearchDetail = (savedSearchId, searchType, searchTitle) => {
  //   this.setState({
  //     savedSearchId: savedSearchId,
  //     searchType: searchType,
  //     searchTitle: searchTitle,
  //   });
  // };

  // loadSavedSearchData = (
  //   searchCriteria,
  //   customizedColumns,
  //   searchId,
  //   searchTypeId,
  //   pageNo,
  //   sizePerPage
  // ) => {
  //   const body = searchCriteria;

  //   const { SortOrder } = this.state;
  //   let offset = (pageNo - 1) * sizePerPage;
  //   let url = `/api/Member/AdvancedFilter?pageOffset=${offset}&pageFetch=${sizePerPage}&sortName=${SortOrder}`;
  //   if (
  //     searchTypeId &&
  //     (searchTypeId === SavedSearchTypes.BUCKET_ITEM ||
  //       (searchTypeId === SavedSearchTypes.SHARED_ITEM &&
  //         this.props.SavedSearch.SharedSaveSearchTypeId ===
  //           SavedSearchTypes.BUCKET_ITEM)) &&
  //     searchId
  //   ) {
  //     url = `/api/SavedSearch/GetBucketSearchResults?bucketSearchId=${searchId}&sortName=${SortOrder}&offset=${offset}&pageSize=${sizePerPage}`;
  //     this.setState({ BCounter: 0 });
  //   }
  //   this.showSpinner();
  //   axios.post(url, body).then(({ data: response }) => {
  //     //this.onResetTable();
  //     this.setColumnsHeaders(customizedColumns);
  //     this.setState({
  //       data: response.data,
  //       totalCount: response.totalCount,
  //       savedSearchCriteria: searchCriteria,
  //     });
  //     this.setState({ customizeColumns: customizedColumns });

  //     const { data, page, sizePerPage, totalCount } = this.state;
  //     this.RemotePagination(
  //       data,
  //       page,
  //       sizePerPage,
  //       this.handleTableChange,
  //       totalCount,
  //       true,
  //       this.rowEvents
  //     );
  //     this.hideSpinner();
  //   });
  // };

  // setSavedSearchCriteria = (
  //   savedSearchCriteriaDetail,
  //   searchTypeId,
  //   searchId
  // ) => {
  //   if (savedSearchCriteriaDetail) {
  //     this.setState({
  //       searchCriteria: savedSearchCriteriaDetail.searchCriteria,
  //       customizedColumns: savedSearchCriteriaDetail.searchColumns,
  //       savedSearchId: searchId,
  //       savedSearchTypeId: searchTypeId,
  //     });
  //     this.onResetTable();
  //     this.setState({
  //       sizePerPage: 25,
  //     });
  //     this.loadSavedSearchData(
  //       savedSearchCriteriaDetail.searchCriteria,
  //       savedSearchCriteriaDetail.searchColumns,
  //       searchId,
  //       searchTypeId,
  //       1,
  //       25
  //     );
  //     this.props.getNewFilteredData(
  //       savedSearchCriteriaDetail.searchCriteria,
  //       false
  //     );
  //     // this.props.setAdvanceFilterLoader(false);
  //     this.props.getMemberTotalCount(savedSearchCriteriaDetail.searchCriteria);
  //     this.props.setFilterData(savedSearchCriteriaDetail.searchCriteria);
  //     this.props.setTempFilterData(savedSearchCriteriaDetail.searchCriteria);

  //     return;
  //   }
  // };

  // setSavedSearchDataState = (
  //   savedSearchCriteria,
  //   filterData,
  //   columnHeaders
  // ) => {
  //   this.setState({ searchResultData: filterData });
  //   this.setState({ searchResultHeaders: columnHeaders });
  //   this.setState({ savedSearchCriteria: savedSearchCriteria });
  //   this.setState({ page: 1 });
  //   this.onResetTable();

  //   this.setColumnsHeaders(columnHeaders);
  //   this.setState({ data: filterData.data });
  //   this.setState({ totalCount: filterData.totalCount });
  //   const { data, sizePerPage, page, totalCount } = this.state;
  //   this.RemotePagination(
  //     data,
  //     0,
  //     sizePerPage,
  //     this.handleTableChange,
  //     totalCount,
  //     true,
  //     this.rowEvents
  //   );
  // };

  // onResetTable() {
  //   this.setState({
  //     //data: null,
  //     totalCount: 0,
  //     page: 1,
  //     sizePerPage: 25,
  //   });
  // }

  showSpinner = () => {
    $(".loading").show();
  };

  hideSpinner = () => {
    $(".loading").hide();
  };

  setColumnsHeaders = (colHeaders) => {
    if (colHeaders) {
      let searchCols = [];
      let dateColumns = [
        "dateOfBirth",
        "plannedRetirementDate",
        "entryOnDutyDate",
        "serviceComputationDate",
        "bargainingUnitDate",
        "natcaBargainingUnitDate",
        "unionJoinedDate",
      ];
      let boolColumns = [
        "hasLifeInsurance",
        "hasLongTermDisability",
        "isRetiredMilitaryController",
        "allowEmails",
        "allowPhoneCall",
        "allowPostalMail",
        "allowTextMessages",
        "isLocked",
        "isEnabled",
      ];
      let emailColumns = ["emailAddress"];
      let phoneColumns = ["phoneNumber"];
      let addressColumns = [
        "mailAddress",
        "deliveryAddress",
        "homeAddress",
        "physicalAddress",
      ];

      let currencyColumns = [
        "yearlySalary",
        "hourlySalary",
        "drfDonation",
        "pacDonation",
        "ncf",
        "expecteddues",
      ];

      this.setState({
        selectedFields: colHeaders,
      });
      colHeaders.map((col, index, array) => {
        let colDef = {
          text: col.column_name,
          dataField: col.backend_column_name,
          sort: true,
          formatter: (cell, row) => {
            let hasPermission = AuthService.canView(col.permission_feature_id);
            if (!hasPermission) return "";
            if (dateColumns.indexOf(col.backend_column_name) != -1) {
              return moment(cell).isValid()
                ? moment(cell).format("MM/DD/YYYY")
                : " ";
            } else {
              if (boolColumns.indexOf(col.backend_column_name) != -1) {
                if (cell == null) {
                  return "No";
                } else {
                  return cell === true ? "Yes" : "No";
                }
              } else {
                if (emailColumns.indexOf(col.backend_column_name) != -1) {
                  return cell == null ? (
                    ""
                  ) : cell.trim().length == 0 ? (
                    ""
                  ) : (
                    <a target="_blank" href={`mailto:${cell}`}>
                      <span className="email-to">{cell}</span>
                    </a>
                  );
                } else {
                  if (phoneColumns.indexOf(col.backend_column_name) != -1) {
                    return cell == null ? (
                      ""
                    ) : cell.trim().length == 0 ? (
                      ""
                    ) : (
                      <a target="_blank" href={`tel:${cell}`}>
                        <span className="telephone">{cell}</span>
                      </a>
                    );
                  } else {
                    if (addressColumns.indexOf(col.backend_column_name) != -1) {
                      if (!cell || cell == null || cell.trim().length <= 0) {
                        return "";
                      } else {
                        let arr = cell.split(",");
                        let filtered = arr.filter((item) => {
                          return item != null && item.trim().length > 0;
                        });
                        console.log(filtered);
                        if (filtered && filtered.length > 0) {
                          let address = filtered.join(",");
                          console.log(address);
                          return (
                            <a
                              target="_blank"
                              className="view-map"
                              href={`http://maps.google.com/?q=${address}`}
                            >
                              <span className="view-map">{address}</span>
                            </a>
                          );
                        }
                      }
                    } else if (
                      currencyColumns.indexOf(col.backend_column_name) != -1
                    ) {
                      const formatter = new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 2,
                      });
                      return moment(cell).isValid()
                        ? formatter.format(cell)
                        : " ";
                    } else {
                      return cell;
                    }
                  }
                }
              }
            }
          },
        };
        if (AuthService.canView(col.permission_feature_id)) {
          searchCols.push(colDef);
        }
      });
      this.columns = searchCols;
      this.setState({
        columnsToView: this.columns,
      });
      // this.setState({
      //   selectedFields: this.columns,
      // });
    }
  };

  redirect = () => {
    $(".d-tabs").removeClass("directory-brand-active");
    $("." + this.props.name).addClass("directory-brand-active");
    this.setState({
      tab: Users,
      tabName: "Members",
      reload: Math.random(),
    });
    $(".d-tabs").removeClass("directory-brand-active");
    $(".Members").addClass("directory-brand-active");
    this.props.history.push("/directory/members");
  };
  redirectToFiltersList = (item) => {
    const currentLocation = window.location.pathname;
    let filterId = 0; // Can be Saved Search or Shared Saved Search Id
    this.setState({
      tab: SavedSearchFiltersList,
      tabName: "Filters",
      reload: Math.random(),
    });
    if (item) {
      if (item.real_search_type_id) {
        filterId = item.saved_search_id;
      } else {
        filterId = item.id;
      }
      $(".d-tabs").removeClass("directory-brand-active");
      $(".FiltersList").addClass("directory-brand-active");
      //console.log("redirectToFiltersList called");
      this.props.history.push(
        `/directory/myfilters?showEmailConfigModal=${filterId}`,
        {
          emailListDetails: { ...item },
        }
      );
    } else {
      this.props.history.push("/directory/myfilters");
    }
    this.props.history.location.lastPlace = currentLocation;
  };
  redirectToAllFiltersList = (item) => {
    const currentLocation = window.location.pathname;
    let filterId = 0; // Can be Saved Search or Shared Saved Search Id
    if (item.real_search_type_id) {
      filterId = item.saved_search_id;
    } else {
      filterId = item.id;
    }
    this.setState({
      tab: SavedSearchFiltersList,
      tabName: "Filters",
      reload: Math.random(),
    });
    $(".d-tabs").removeClass("directory-brand-active");
    $(".FiltersList").addClass("directory-brand-active");

    this.props.history.push(
      `/directory/allfilters?showEmailConfigModal=${filterId}`,
      {
        emailListDetails: { ...item },
      }
    );
    this.props.history.location.lastPlace = currentLocation;
  };
  redirectFromFiltersListToMembers = () => {
    $(".d-tabs").removeClass("directory-brand-active");
    $("." + this.props.name).addClass("directory-brand-active");
    this.setState({
      tab: Users,
      tabName: "Members",
      reload: Math.random(),
    });
    $(".d-tabs").removeClass("directory-brand-active");
    $(".Members").addClass("directory-brand-active");
    localStorage.setItem("isMemberEditRowClick", false);
    this.props.history.push("/directory/members", {
      routeFromProfile: true,
    });
  };
  CreateBulkTeams = async () => {
    $(".loading").show();
    let data = await Services.CreateBulkTeams();
    if (data != null) {
      //puller code will be apply here
      await this.setState({ bulkProgress: true });
      this.getTeamCreateStatus(true, false);
    }
    $("#CreateBulk").prop("disabled", false);
    $(".loading").hide();
  };
  onDeleteEmailList = () => {
    this.setState({
      emailListExists: false,
      emailListId: 0,
    });
  };
  render() {
    let featureId = 0;
    switch (this.props.name) {
      case "Region":
        featureId = PermissionFeature.Region;
        break;
      case "Facility":
        featureId = PermissionFeature.Facility;
        break;
      case "Members":
        featureId = PermissionFeature.Member;
        break;
      case "Filters":
        featureId = PermissionFeature.MembersFilter;
        break;
      default:
        break;
    }
    if (
      this.props.location &&
      this.props.location.keyword &&
      this.props.location.keyword !== "" &&
      this.props.location.pathname === "/directory/members" &&
      AuthService.canView(featureId)
    ) {
      $(".d-tabs").removeClass("directory-brand-active");
      $("." + this.props.name).addClass("directory-brand-active");
      this.setState({
        tab: Users,
        tabName: "Members",
        reload: Math.random(),
      });
      $(".d-tabs").removeClass("directory-brand-active");
      $(".Members").addClass("directory-brand-active");
      this.props.history.push("/directory/members");
    } else if (
      this.props.location.pathname.indexOf("/directory/userAction") > -1 ||
      this.props.location.pathname.indexOf("/dashboard/myProfile") > -1
    ) {
      $(".d-tabs").removeClass("directory-brand-active");
      $(".Members").addClass("directory-brand-active");
    }
    return (
      <AUX>
        <FacilityTeamEmailListNameModal
          showModal={this.state.showCreateMgListModal}
          toggleModal={this.toggleCreateMgListModal}
          createEmail={this.createFacilityEmail}
        />
        {this.canViewSavedSearch() === true &&
          (this.state.userData.membertypeid == 6 ||
            this.state.userData.membertypeid == 8 ||
            this.state.userData.membertypeid == 10) && (
            <div
              id="SavedSearchList"
              className={`${
                this.props.location.pathname.indexOf(
                  "/directory/facility/view"
                ) > -1 &&
                AuthService.canSPView(
                  PermissionFeature.OfficerUpdate,
                  undefined,
                  this.props.match.params.id
                )
                  ? "Officer-Btn-Space"
                  : ""
              }`}
            >
              {/* Filters DDL */}
              <AdvancedSavedSearch
                // savedFilterSelected={this.setSavedSearchDataState}
                //setSavedSearchCriteria={this.setSavedSearchCriteria}
                //setSavedSearchDetail={this.setSavedSearchDetail}
                // isAdvanceSearchActive={
                //   this.props.filterState.isAdvancedFilterSelected
                // }
                redirectToMembers={this.redirect}
                redirectToAllFiltersList={this.redirectToAllFiltersList}
                redirectToFiltersList={this.redirectToFiltersList}
              />
            </div>
          )}
        <nav className="navbar navbar-expand-sm directory-navbar">
          {AuthService.canView(PermissionFeature.Region) &&
            (this.state.userData.membertypeid == 6 ||
              this.state.userData.membertypeid == 8 ||
              this.state.userData.membertypeid == 10) && (
              <a
                className="d-tabs Region navbar-brand directory-navbar-brand"
                data-tab="Region"
                onClick={this.handleTabChange}
              >
                <i className="ion ion-md-globe" /> Regions
              </a>
            )}
          {AuthService.canView(PermissionFeature.Facility) &&
            (this.state.userData.membertypeid == 6 ||
              this.state.userData.membertypeid == 8 ||
              this.state.userData.membertypeid == 10) && (
              <a
                className="d-tabs Facility navbar-brand directory-navbar-brand"
                data-tab="Facility"
                onClick={this.handleTabChange}
              >
                <i className="mdi mdi-office-building" /> Facilities
              </a>
            )}
          {AuthService.canView(PermissionFeature.Member) &&
            (this.state.userData.membertypeid == 6 ||
              this.state.userData.membertypeid == 8 ||
              this.state.userData.membertypeid == 10) && (
              <a
                className="d-tabs Members navbar-brand directory-navbar-brand"
                data-tab="Members"
                onClick={this.handleTabChange}
              >
                <i className="mdi mdi-account-box-multiple" /> Members
              </a>
            )}
          {/* ///Displayed on the Facility Subheader/// */}
          {this.props.location.pathname === "/directory/facility" &&
            (this.state.userData.membertypeid == 6 ||
              this.state.userData.membertypeid == 8 ||
              this.state.userData.membertypeid == 10) && (
              <a
                className="navbar-brand directory-navbar-brand"
                data-toggle="tooltip"
                title="Advance Filters"
              >
                <i className="fas fa-filter"></i> Filter
              </a>
            )}
          {/* ///Displayed on the Members Subheader/// */}
          {AuthService.canView(PermissionFeature.MembersFilter) &&
            this.props.location.pathname === "/directory/members" &&
            (this.state.userData.membertypeid == 6 ||
              this.state.userData.membertypeid == 8 ||
              this.state.userData.membertypeid == 10) && (
              <a
                className="d-tabs navbar-brand directory-navbar-brand"
                id="clickAdvancedFilter"
                data-toggle="tooltip"
                title="Advance Filters"
              >
                <i className="fas fa-filter"></i> Filter
              </a>
            )}
          {/* ///Displayed on the ***Profile*** Screen/// */}
          {AuthService.canView(PermissionFeature.MembersFilter) &&
            this.props.location.pathname.indexOf("/dashboard") > -1 &&
            (this.state.userData.membertypeid == 6 ||
              this.state.userData.membertypeid == 8 ||
              this.state.userData.membertypeid == 10) && (
              <a
                //href="/directory/members"
                onClick={() => {
                  localStorage.setItem("isMemberEditRowClick", false);
                  this.props.history.push("/directory/members", {
                    routeFromProfile: true,
                  });
                }}
                className="navbar-brand directory-navbar-brand"
                //id="clickAdvancedFilter"
                data-toggle="tooltip"
                title="Advance Filters"
              >
                <i className="fas fa-filter"></i> Filter
              </a>
            )}

          {/* /// On Saved Filters Listing /// */}
          {AuthService.canView(PermissionFeature.MembersFilter) &&
            this.props.location.pathname.indexOf("filters") > -1 &&
            (this.state.userData.membertypeid == 6 ||
              this.state.userData.membertypeid == 8 ||
              this.state.userData.membertypeid == 10) && (
              <a
                className="d-tabs MemberFiltersModal navbar-brand directory-navbar-brand"
                data-toggle="tooltip"
                id="clickAdvancedFilter"
                title="Advance Filters"
                onClick={() => {
                  this.redirectFromFiltersListToMembers();
                }}
              >
                <i className="fas fa-filter"></i> Filter
              </a>
            )}

          {/* Invisible Tab for Filters Listing */}
          {(this.state.userData.membertypeid == 6 ||
            this.state.userData.membertypeid == 8 ||
            this.state.userData.membertypeid == 10) && (
            <a
              className="d-tabs FiltersList d-none navbar-brand directory-navbar-brand"
              data-toggle="tooltip"
              data-tab="Filters"
              id="FiltersList"
              title="Saved Filters List"
              onClick={this.handleTabChange}
            >
              <i className="fas fa-filter"></i> Saved Filters List
            </a>
          )}
          {/* <FiltersHeader /> */}
          {/* {this.props.location.pathname === "/directory/filters" &&
            (this.state.userData.membertypeid == 6 ||
              this.state.userData.membertypeid == 8 ||
              this.state.userData.membertypeid == 10) && (
              <a
                className="d-tabs FiltersList navbar-brand directory-navbar-brand"
                data-toggle="tooltip"
                data-tab="Filters"
                id="MyFiltersList"
                title="Saved Filters List"
                onClick={this.handleTabChange}
              >
                <i className="fab fa-searchengin"></i> My Saved Filters
              </a>
            )}
          {this.props.location.pathname === "/directory/filters" &&
            (this.state.userData.membertypeid == 6 ||
              this.state.userData.membertypeid == 8 ||
              this.state.userData.membertypeid == 10) && (
              <a
                className="d-tabs FiltersList navbar-brand directory-navbar-brand"
                data-toggle="tooltip"
                data-tab="Filters"
                id="AllFiltersList"
                title="Saved Filters List"
                onClick={this.handleTabChange}
              >
                <i className="fab fa-searchengin"></i> All Saved Filters
              </a>
            )} */}
          {((this.props.location.pathname === "/directory/region" &&
            (AuthService.canAdd(PermissionFeature.Region) ||
              AuthService.canAdd(PermissionFeature.TeamsTeams))) ||
            (this.props.location.pathname.indexOf("/directory/region/view") >
              -1 &&
              AuthService.canUpdate(PermissionFeature.Region)) ||
            (this.props.location.pathname === "/directory/facility" &&
              (AuthService.canAdd(PermissionFeature.Facility) ||
                AuthService.canAdd(PermissionFeature.TeamsTeams))) ||
            (this.props.location.pathname.indexOf("/directory/facility/view") >
              -1 &&
              AuthService.canUpdate(PermissionFeature.Facility) &&
              (AuthService.canUpdate(PermissionFeature.FacilityCode) ||
                AuthService.canUpdate(PermissionFeature.FacilityDescription) ||
                AuthService.canUpdate(PermissionFeature.FacilityRegion) ||
                AuthService.canUpdate(PermissionFeature.FacilityType))) ||
            (this.props.location.pathname === "/directory/members" &&
              AuthService.canAdd(PermissionFeature.Member)) ||
            this.props.location.pathname.indexOf(
              "/directory/userAction" &&
                AuthService.canUpdate(PermissionFeature.Member)
            ) > -1 ||
            ((this.props.location.pathname.indexOf("/directory/userAction") >
              -1 ||
              this.props.location.pathname.indexOf("/dashboard") > -1) &&
              (this.state.userData.membertypeid == 6 ||
                this.state.userData.membertypeid == 8 ||
                this.state.userData.membertypeid == 10) &&
              AuthService.canDelete(PermissionFeature.Member))) &&
            // DevOps:1014
            // Remove the Gear Icon and create a button for Create Member
            // that opens the Create Member Modal
            this.props.location.pathname != "/directory/members" && (
              <UncontrolledDropdown
                setActiveFromChild
                className="dropdown-menu-toggle directory-navbar-brand AlmashoorGearIcon"
              >
                <DropdownToggle
                  tag="a"
                  className="nav-link nav-link-font-size navpadding pt-1 pb-0"
                >
                  <i className="fas fa-cog"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-body resize">
                  {this.props.location.pathname === "/directory/region" &&
                    AuthService.canAdd(PermissionFeature.Region) && (
                      <DropdownItem
                        className="dropdown-item-custom"
                        data-toggle="modal"
                        data-target="#AddRegionModal"
                        data-backdrop="static"
                      >
                        {" "}
                        <i className="fas fa-plus-circle"></i> Add Region{" "}
                      </DropdownItem>
                    )}
                  {this.props.location.pathname.indexOf(
                    "/directory/region/view"
                  ) > -1 &&
                    AuthService.canUpdate(PermissionFeature.Region) && (
                      <DropdownItem
                        className="dropdown-item-custom"
                        data-toggle="modal"
                        data-target="#EditRegionModal"
                        data-backdrop="static"
                      >
                        {" "}
                        <i className="fas fa-pencil-alt edit-pencil-icon"></i>{" "}
                        Edit Region{" "}
                      </DropdownItem>
                    )}

                  {this.props.location.pathname === "/directory/facility" &&
                    AuthService.canAdd(PermissionFeature.Facility) && (
                      <DropdownItem
                        className="dropdown-item-custom"
                        data-toggle="modal"
                        data-target="#AddFacilityModal"
                        data-backdrop="static"
                        onClick={(e) => this.onAddFacilityClick(e)}
                      >
                        {" "}
                        <i className="fas fa-plus-circle"></i> Add Facility{" "}
                      </DropdownItem>
                    )}
                  {this.props.location.pathname.indexOf(
                    "/directory/facility/view"
                  ) > -1 &&
                    AuthService.canUpdate(PermissionFeature.Facility) && (
                      <DropdownItem
                        className="dropdown-item-custom"
                        data-toggle="modal"
                        data-target="#EditFacilityModal"
                        data-backdrop="static"
                        onClick={(e) => this.onEditFacilityClick(e)}
                      >
                        {" "}
                        <i className="fas fa-pencil-alt edit-pencil-icon"></i>{" "}
                        Edit Facility{" "}
                      </DropdownItem>
                    )}
                  {(this.props.location.pathname === "/directory/facility" ||
                    this.props.location.pathname === "/directory/region") &&
                    AuthService.canAdd(PermissionFeature.TeamsTeams) && (
                      <>
                        <DropdownItem
                          className="dropdown-item-custom"
                          id="CreateBulk"
                          onClick={(e) => {
                            $("#CreateBulk").prop("disabled", true);
                            this.CreateBulkTeams();
                          }}
                        >
                          {" "}
                          <i className="fas fa-plus-circle"></i> Create Teams{" "}
                        </DropdownItem>
                        <DropdownItem
                          className="dropdown-item-custom"
                          id="showCreateBulkStatus"
                          onClick={(e) => {
                            if (this.state.teamsCreateStatus) {
                              this.setState({ showTeamsCreateStatus: true });
                            } else {
                              this.getTeamCreateStatus(true, false);
                            }
                          }}
                        >
                          {" "}
                          <i className="fas fa-chart-line"></i> Bulk Teams
                          Progress{" "}
                        </DropdownItem>
                      </>
                    )}
                  {this.props.location.pathname === "/directory/region" &&
                    AuthService.canAdd(PermissionFeature.TeamsTeams) && (
                      <>
                        <DropdownItem
                          className="dropdown-item-custom"
                          id="showSpecialTeamPermission"
                          onClick={(e) => {
                            this.props.openSpecialTeamPermissionModal();
                          }}
                        >
                          {" "}
                          <i className="fas fa-lock"></i> Special Team Permissions{" "}
                        </DropdownItem>
                      </>
                    )}
                  {this.canConfigureFacilityEmail() === true &&
                    (this.state.userData.membertypeid == 6 ||
                      this.state.userData.membertypeid == 8 ||
                      this.state.userData.membertypeid == 10) &&
                    this.props.location.pathname.indexOf(
                      "/directory/facility/view"
                    ) > -1 &&
                    this.state.emailListExists === true && (
                      <DropdownItem
                        className="dropdown-item-custom"
                        onClick={this.configureFacilityEmail}
                      >
                        {" "}
                        <i className="fas fa-cog"></i> Configure Email{" "}
                        {/* // Facility's Update Email List // */}
                      </DropdownItem>
                    )}
                  {AuthService.canDelete(PermissionFeature.EmailListAdmin) &&
                    this.props.location.pathname.indexOf(
                      "/directory/facility/view"
                    ) > -1 &&
                    this.state.emailListExists === true && (
                      <DropdownItem
                        className="dropdown-item-custom"
                        onClick={() => {
                          if (
                            window.confirm(
                              `Are you sure you want to delete email list?`
                            )
                          ) {
                            this.DeleteEmailList();
                          }
                        }}
                      >
                        {" "}
                        <i className="fas fa-trash"></i> Delete Email List{" "}
                      </DropdownItem>
                    )}
                  {this.canCreateFacilityEmail() === true &&
                    this.props.location.pathname.indexOf(
                      "/directory/facility/view"
                    ) > -1 &&
                    this.state.emailListExists === false && (
                      <DropdownItem
                        className="dropdown-item-custom"
                        // data-toggle="modal"
                        // data-target="#FacilityTeamEmailListNameModal"
                        // data-backdrop="static"
                        onClick={this.toggleCreateMgListModal}
                        //onClick={this.createFacilityEmail}
                      >
                        {" "}
                        <i className="fas fa-plus"></i> Create Email List{" "}
                      </DropdownItem>
                     )}

                  {this.props.location.pathname.indexOf(
                    "/directory/facility/view"
                  ) > -1 &&
                    AuthService.canView(PermissionFeature.Areas) && (
                      <DropdownItem
                        className="dropdown-item-custom"
                        onClick={this.openManageAreasModal}
                      >
                        {" "}
                        <i className="fas fa-map-marker-alt"></i> Manage Areas{" "}
                      </DropdownItem>
                    )}
                  {/*
{/*{this.props.location.pathname === "/directory/members" &&
                  AuthService.canAdd(PermissionFeature.Member) && (
                    <DropdownItem
                      href="/directory/AddNewUser"
                      className="dropdown-item-custom"
                    >
                      {" "}
                      <i className="fas fa-plus-circle"></i> Add Member{" "}
                    </DropdownItem>
                  )} */}
                  {this.props.location.pathname.indexOf(
                    "/directory/userAction"
                  ) > -1 &&
                    AuthService.canDelete(PermissionFeature.Member) &&
                    !(
                      this.props.match &&
                      this.props.match.params &&
                      this.props.match.params.id &&
                      this.props.match.params.id ==
                        JSON.parse(localStorage.getItem("memberData")).id
                    ) && (
                      <DropdownItem
                        className="dropdown-item-custom deleteMemberItem"
                        data-toggle="modal"
                        data-target=".delete-modal"
                      >
                        {" "}
                        <i className="fas fa-trash-alt"></i> Delete Member{" "}
                      </DropdownItem>
                    )}
                  {(this.props.location.pathname.indexOf(
                    "/directory/userAction"
                  ) > -1 ||
                    this.props.location.pathname.indexOf("/dashboard") > -1) &&
                    AuthService.canView(
                      PermissionFeature.MembersReviewPermission
                    ) && (
                      <DropdownItem
                        data-toggle="modal"
                        data-target="#ViewPermissionModal"
                        data-backdrop="static"
                        className="dropdown-item-custom"
                      >
                        {" "}
                        <i class="fas fa-shield-alt"></i> Review Permissions{" "}
                      </DropdownItem>
                    )}
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
          {/* DevOps:1014 */}
          {this.props.location.pathname === "/directory/members" &&
            AuthService.canAdd(PermissionFeature.Member) && (
              <div className="d-tabs c-pointer navbar-brand equipmentHeader-navbar-brand">
                <button
                  className="feHeader-tabs cnrbtn btn btn-success btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light"
                  type="button"
                >
                  <NavLink
                    activeClassName="active"
                    to="/directory/AddNewUser"
                    className="mlink text-white"
                  >
                    Add Member
                  </NavLink>
                </button>
              </div>
            )}
          <button
            type="button"
            className="btn btn-primary"
            id="modalOpenButton"
            data-toggle="modal"
            data-target=".AdvanceFilterModal"
            data-backdrop="static"
            data-keyboard="false"
            style={{ display: "none" }}
          ></button>

          {/* <OfficerButton class="d-xl-none d-lg-inline-block px-0 text-right" /> */}
        </nav>
        <div
          className={
            "container-fluid 9070" +
            (this.props.location.pathname === "/directory/facility" ||
            this.props.location.pathname === "/directory/region"
              ? " RFContainer"
              : "")
          }
        >
          {(this.props.location.pathname === "/directory/facility" ||
            this.props.location.pathname === "/directory/region") &&
            AuthService.canAdd(PermissionFeature.TeamsTeams) &&
            this.state.teamsCreateStatus && (
              <div
                className="pt-3 position-relative"
                style={{
                  display: this.state.showTeamsCreateStatus ? "block" : "none",
                }}
                id="bulkTeamStatus"
              >
                <div className="d-block w-100 pr-2 position-absolute text-right">
                  <i
                    className="c-pointer text-black-50 fas fa-times"
                    onClick={() => {
                      this.setState({ showTeamsCreateStatus: false });
                    }}
                  ></i>
                </div>
                <h4 className="page-title-search">Teams Creation Progress:</h4>
                <div className="w-100">
                  {this.state.teamsCreateStatus.totalparse} /{" "}
                  {this.state.teamsCreateStatus.totalteams} <b>Ignore: </b>{" "}
                  {this.state.teamsCreateStatus.totalignore}
                </div>
                <div id="bulkTeam-PB" className="progress bg-white">
                  <div
                    className="bg-success rounded progress-bar"
                    style={{
                      width: this.state.teamsCreateStatus.progress + "%",
                    }}
                    role="progressbar"
                    aria-valuenow={this.state.teamsCreateStatus.progress}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    <span className="progress-label">
                      {this.state.teamsCreateStatus.progress}%
                    </span>
                  </div>
                </div>
                {!_.isEmpty(this.state.teamsCreateStatus.response) && (
                  <>
                    <label className="w-100 mb-0">
                      <b>Response:</b>
                    </label>
                    <p className="h6 mb-2">
                      {this.state.teamsCreateStatus.response}
                    </p>
                  </>
                )}
                {!_.isEmpty(this.state.teamsCreateStatus.description) && (
                  <>
                    <label className="w-100 mb-0">
                      <b>Description:</b>
                    </label>
                    {this.state.teamsCreateStatus.description
                      .split("\\n")
                      .map((item) => (
                        <p className="mb-0">{item}</p>
                      ))}
                  </>
                )}
              </div>
            )}
          {/* <div className="page-title-box">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <h1 className="page-title">Directory</h1>
                            </div>
                        </div>
                    </div> */}

          {/* <div className="row mb-20">
                        <div className="col">
                            <div className="btn-group" role="group">
                                <ButtonGroup>
                                    {this.hasAccess("Region") && (
                                        <Button
                                            color="secondary"
                                            className="d-tabs Region"
                                            data-tab="Region"
                                            onClick={this.handleTabChange}>
                                            <i className="ion ion-md-globe" /> Regions{" "}
                                        </Button>
                                    )}
                                    {this.hasAccess("Facility") && (
                                        <Button
                                            className="d-tabs Facility"
                                            color="secondary"
                                            data-tab="Facility"
                                            onClick={this.handleTabChange}>
                                            <i className="mdi mdi-office-building" /> Facilities{" "}
                                        </Button>
                                    )}
                                    {this.hasAccess("Members") && (
                                        <Button
                                            color="secondary"
                                            className="d-tabs Members"
                                            data-tab="Members"
                                            onClick={this.handleTabChange}>
                                            <i className="mdi mdi-account-box-multiple" /> Members{" "}
                                        </Button>
                                    )} */}
          {/* {this.hasAccess("Teams") && (
                                        <Button color="secondary" className="d-tabs Teams" data-tab="Teams" onClick={this.handleTabChange}>
                                            <i class="mdi mdi-account-supervisor-circle" /> Teams{" "}
                                        </Button>
                                    )} */}

          {/* {this.hasAccess("Areas") && (
                                        <Button className="d-tabs Areas" color="secondary" data-tab="Areas" onClick={this.handleTabChange}>
                                            <i class="mdi mdi-map-marker-check" />
                                            Areas
                                        </Button>
                                    )} */}
          {/* </ButtonGroup>
                            </div>
                        </div>
                    </div> */}

          <div className="row">
            <Route
              key={this.state.reload}
              path="/"
              component={this.state.tab}
            />
          </div>
        </div>
        <SpecialTeamPermission />
        <MailGunMembersListModal
          showModal={this.state.showEmailMembersModal}
          onModalClose={this.mailGunMemberListClosed}
          mgListAddress={this.state.mgListAddress}
          TeamCheck={true}
          FacilityId={this.props.match.params.id}
          isFacility={true}
          MailGunEmailType={MailGunEmailTypes.Facility}
          emailListId={this.state.emailListId}
          onDeleteEmailList={this.onDeleteEmailList}
          isRegistration={false}
        ></MailGunMembersListModal>

        <ManageAreasModal
          showModal={this.state.showManageAreasModal}
          onModalClose={this.closeManageAreasModal}
          FacilityId={this.props.match.params.id}
        ></ManageAreasModal>
      </AUX>
    );
  }
}

export default connect(
  (state) => ({
    filterState: state.advancedFilter,
    formValues: state.form,
    currentState: state,
    SavedSearch: state.saveSearch,
    permissionState: state.permission,
  }),
  {
    ...actionFilter,
    ...facilityActions,
    ...saveSearchAction,
    ...permissionActions,
  }
)(Directory);
