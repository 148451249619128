import React, { Component } from "react";

class Tooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTooltip: false,
    };
    this.tooltipContentRef = React.createRef();
  }

  componentDidMount() {}

  handleMouseEnter = () => {
    this.setState({ showTooltip: true });
  };

  handleMouseLeave = () => {
    this.setState({ showTooltip: false });
  };

  render() {
    const { text, children } = this.props;
    const { showTooltip } = this.state;

    return (
      <div style={{ position: "relative", display: "inline-block" }}>
        {showTooltip && (
          <div
            style={{
              position: "absolute",
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              color: "#fff",
              padding: "5px",
              borderRadius: "5px",
              zIndex: "999",
              top: "calc(100% + 5px)",
              left: "50%",
              transform: "translateX(-50%)",
              minWidth: "118px",
            }}
          >
            <div ref={this.tooltipContentRef} style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  top: "-10px",
                  left: "50%",
                  marginLeft: "-5px",
                  width: "0",
                  height: "0",
                  borderTop: "none",
                  borderRight: "5px solid transparent",
                  borderBottom: "5px solid rgba(0, 0, 0, 0.7)",
                  borderLeft: "5px solid transparent",
                }}
              ></div>
              {text}
            </div>
          </div>
        )}
        <div
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        >
          {children}
        </div>
      </div>
    );
  }
}

export default Tooltip;
