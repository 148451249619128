import AUX from "../../hoc/Aux_";
import React, { Component, useState } from "react";
import { connect } from "react-redux";
import actionFilter from "../../store/advancedfilter/action";
import _ from "lodash";
import $ from "jquery";
import TagsActionButtons from "../AdvanceSearch/AdvanceFilterTabs/TagsButtons";
import { store } from "../../index";
import Tags from "../AdvanceSearch/AdvanceFilterTabs/Tags";
import SelectedFilter from "../AdvanceSearch/AdvanceFilterTabs/SelectedFilter";

import "../../components/Layout/AdvanceFilterSideBar.css";
import { reset, isInvalid, getFormValues } from "redux-form";

import "../../components/Layout/advancedFilter.css";

import axios from "../Shared/auth-header";
import { toast } from "react-toastify";

import DesktopTabContents from "../AdvanceSearch/AdvanceFilterTabs/DeskTop/DeskTopTabContents";
import MobileSideBar from "../AdvanceSearch/AdvanceFilterTabs/Mobile/MobileSideBar";
import DesktopSideBar from "../AdvanceSearch/AdvanceFilterTabs/DeskTop/DesktopSideBar";
// import {
//   fe_request_statuses,
//   feequipmentstatuses,
//   fe_inventory_types,
// } from "../F&E/AdvanceFilter/FE_AdvanceSearch_Modal";
import { Row, Col } from "reactstrap";
import FE_AdvanceFilter_NewTabs from "../F&E/AdvanceFilter/FE_AdvanceFilter_NewTabs";
import savedSearchAction from "../../store/saveSearch/action";
import FilterEmailConfigTabs from "../Email/Enums/FilterEmailConfigTabs";
import mailGunEmailActions from "../../store/mailgunEmails/action";

class AdvanceFilter extends Component {
  constructor(props) {
    super(props);
    this.DEBUG = process.env.NODE_ENV === "development";
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    window.addEventListener(
      "orientationchange",
      this.orientationchange.bind(this)
    );
    this.resize();
    $("#clickAdvancedFilter").on("click", this.onModalClick);
    //const { showOnlyBody } = this.props;
    // if (showOnlyBody) {
    //   this.preloadAdvancedFilterData();
    // }
    if (this.props.advancedFilterOpenedInTab) {
      this.resetALLFormFilters();
      console.log("advancedFilterOpenedInTab");
    }
  }
  orientationchange = () => {
    this.resize();
  };

  onModalClick = (e) => {
    //console.log("/api/Member/AdvancedFilteredData called!!");
    console.log(this.props.filterState.filterData);
    if (_.isEmpty(this.props.filterState.filterData)) {
      $(".loading").show();
      axios
        .post("/api/Member/AdvancedFilteredData?getRefreshData=true", {})
        .then(({ data }) => {
          if (data.status && data.status.toLowerCase() == "success") {
            $("#modalOpenButton").click();
            $("body").removeClass("modal-open");
            $("html").addClass("removeYScroll");
            this.resize();
            this.stopBodyScrolling(true);
            this.props.setFilterData(data.data);
            this.props.setTempFilterData(data.data);
            $(".loading").hide();
            this.onReturnNo();
            this.props.setAdvanceFilterLoader(false);
          } else {
            $(".loading").hide();
            toast.error(data.message);
            this.props.setAdvanceFilterLoader(false);
          }
        });
    } else {
      // console.log('else block of onModalClick')
      $(".loading").hide();
      console.log(this.props.filterState.filterData.searchApplied);

      $("#modalOpenButton").click();
      $("body").removeClass("modal-open");
      $("html").addClass("removeYScroll");
      this.resize();
      this.stopBodyScrolling(true);
    }
  };
  preloadAdvancedFilterData = () => {
    console.log(this.props.filterState.filterData);
    if (_.isEmpty(this.props.filterState.filterData)) {
      $(".loading").show();
      axios
        .post("/api/Member/AdvancedFilteredData?getRefreshData=true", {})
        .then(({ data }) => {
          if (data.status && data.status.toLowerCase() == "success") {
            this.props.setFilterData(data.data);
            this.props.setTempFilterData(data.data);
            $(".loading").hide();
            this.onReturnNo();
            this.props.setAdvanceFilterLoader(false);
          } else {
            $(".loading").hide();
            toast.error(data.message);
            this.props.setAdvanceFilterLoader(false);
          }
        });
    } else {
      $(".loading").hide();
    }
  };
  getFilterPreStateData = () => {};

  resize() {
    let currentHideNav = window.innerWidth >= 897; /// isDesktop

    if (currentHideNav !== this.props.filterState.showWebSideBar) {
      this.props.showWebSideBar(currentHideNav);
      this.props.showMobileSideBar(false);
    } else if (window.innerWidth <= 897) {
      this.props.showMobileSideBar(true);
      this.props.showWebSideBar(false);
    }
    let vh = 0;
    let tagsScroll = 0;
    if (window.innerHeight > window.innerWidth) {
      ///Potrait Mode
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight * 0.018}px`
      );
      document.documentElement.style.setProperty(
        "--tagsScroll",
        `${window.innerHeight * 0.01 - 2}px`
      );
    } else if (window.innerHeight < window.innerWidth) {
      ///Landscape Mode
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight * 0.01}px`
      );

      document.documentElement.style.setProperty(
        "--tagsScroll",
        `${window.innerHeight * 0.01}px`
      );
    }

    let bodyHeight = $(".modal-body").height() * 0.01;

    document.documentElement.style.setProperty(
      "--bodyHeight",
      `${bodyHeight}px`
    );

    if (this.DEBUG) {
      // console.log("resized called");
      //console.log(`set vh ${vh}px`);
      //  console.log(`set body ${bodyHeight}px`);
      //console.log(window.innerWidth);
    }
  }
  resetALLFormFilters() {
    store.dispatch(reset("drfForm"));
    store.dispatch(reset("pacForm"));
    store.dispatch(reset("ageForm"));
    store.dispatch(reset("nameForm"));
    store.dispatch(reset("phoneForm"));
    store.dispatch(reset("memberECIForm"));
    store.dispatch(reset("memberNoForm"));
    store.dispatch(reset("facilityRoutingSymbolForm"));
    store.dispatch(reset("orgCodeForm"));
    store.dispatch(reset("hourlySalaryForm"));
    store.dispatch(reset("annualSalaryForm"));
    store.dispatch(reset("emailForm"));
    store.dispatch(reset("plannedRetirementForm"));
    store.dispatch(reset("retirementForm"));
    store.dispatch(reset("sCDForm"));
    store.dispatch(reset("eODDateForm"));
    store.dispatch(reset("nATCABUDateForm"));
    store.dispatch(reset("bUDateForm"));
    store.dispatch(reset("unionStartDateForm"));
    store.dispatch(reset("unionJoinDateForm"));
    store.dispatch(reset("teamPositionForm"));
    store.dispatch(reset("activeDatePositionForm"));
    store.dispatch(reset("mandatoryRetirementForm"));
    store.dispatch(reset("facebookForm"));
  }
  resetALLFilters() {
    store.dispatch(reset("drfForm"));
    store.dispatch(reset("pacForm"));
    store.dispatch(reset("ageForm"));
    store.dispatch(reset("nameForm"));
    store.dispatch(reset("phoneForm"));
    store.dispatch(reset("memberECIForm"));
    store.dispatch(reset("memberNoForm"));
    store.dispatch(reset("facilityRoutingSymbolForm"));
    store.dispatch(reset("orgCodeForm"));
    store.dispatch(reset("hourlySalaryForm"));
    store.dispatch(reset("annualSalaryForm"));
    store.dispatch(reset("emailForm"));
    store.dispatch(reset("plannedRetirementForm"));
    store.dispatch(reset("retirementForm"));
    store.dispatch(reset("sCDForm"));
    store.dispatch(reset("eODDateForm"));
    store.dispatch(reset("nATCABUDateForm"));
    store.dispatch(reset("bUDateForm"));
    store.dispatch(reset("unionStartDateForm"));
    store.dispatch(reset("unionJoinDateForm"));
    store.dispatch(reset("teamPositionForm"));
    store.dispatch(reset("activeDatePositionForm"));
    store.dispatch(reset("mandatoryRetirementForm"));
    store.dispatch(reset("facebookForm"));

    this.props.filterState.filterData.searchApplied = [];

    this.props.filterState.filterData.facility = this.props.filterState.filterData.facility.map(
      (item) => {
        item.checked = false;
        return item;
      }
    );
    this.props.filterState.filterData.memberStatus = this.props.filterState.filterData.memberStatus.map(
      (item) => {
        if (item.memberStatus === "Active") {
          item.checked = true;
        } else {
          item.checked = false;
        }
        return item;
      }
    );
    this.props.filterState.filterData.memberType = this.props.filterState.filterData.memberType.map(
      (item) => {
        item.checked = false;
        return item;
      }
    );
    this.props.filterState.filterData.region = this.props.filterState.filterData.region.map(
      (item) => {
        item.checked = false;
        return item;
      }
    );

    this.props.filterState.filterData.shirtSize = this.props.filterState.filterData.shirtSize.map(
      (item) => {
        item.checked = false;
        return item;
      }
    );
    this.props.filterState.filterData.gender = this.props.filterState.filterData.gender.map(
      (item) => {
        item.checked = false;
        return item;
      }
    );

    this.props.filterState.filterData.name = null;
    this.props.filterState.filterData.age = null;
    this.props.filterState.filterData.pac = null;
    this.props.filterState.filterData.drf = null;
    this.props.filterState.filterData.memberECI = null;
    this.props.filterState.filterData.memberNumber = null;

    this.props.filterState.filterData.dutyStation = this.props.filterState.filterData.dutyStation.map(
      (item) => {
        item.checked = false;
        return item;
      }
    );
    this.props.filterState.filterData.unit = this.props.filterState.filterData.unit.map(
      (item) => {
        item.checked = false;
        return item;
      }
    );

    this.props.filterState.filterData.congressionalDistrict = this.props.filterState.filterData.congressionalDistrict.map(
      (item) => {
        item.checked = false;
        return item;
      }
    );
    this.props.filterState.filterData.occupationSeries = this.props.filterState.filterData.occupationSeries.map(
      (item) => {
        item.checked = false;
        return item;
      }
    );
    this.props.filterState.filterData.employer = this.props.filterState.filterData.employer.map(
      (item) => {
        item.checked = false;
        return item;
      }
    );
    this.props.filterState.filterData.careerLevel = this.props.filterState.filterData.careerLevel.map(
      (item) => {
        item.checked = false;
        return item;
      }
    );
    this.props.filterState.filterData.payGrade = this.props.filterState.filterData.payGrade.map(
      (item) => {
        item.checked = false;
        return item;
      }
    );
    this.props.filterState.filterData.flsaCategory = this.props.filterState.filterData.flsaCategory.map(
      (item) => {
        item.checked = false;
        return item;
      }
    );
    this.props.filterState.filterData.communication = this.props.filterState.filterData.communication.map(
      (item) => {
        item.checked = false;
        return item;
      }
    );
    this.props.filterState.filterData.address.addressTypes = this.props.filterState.filterData.address.addressTypes.map(
      (item) => {
        item.checked = false;
        return item;
      }
    );
    this.props.filterState.filterData.address.states = this.props.filterState.filterData.address.states.map(
      (item) => {
        item.checked = false;
        return item;
      }
    );
    this.props.filterState.filterData.address.verifiedAddress.checked = false;
    this.props.filterState.filterData.address.city = "";
    this.props.filterState.filterData.address.country = "";
    this.props.filterState.filterData.address.zip = "";
    this.props.filterState.filterData.address.nullPrimary = null;
    this.props.filterState.filterData.address.nullPrimaryAddress = this.props.filterState.filterData.address.nullPrimaryAddress.map(
      (item) => {
        item.checked = false;
        return item;
      }
    );
    this.props.filterState.filterData.nullPrimaryPhone = this.props.filterState.filterData.nullPrimaryPhone.map(
      (item) => {
        item.checked = false;
        return item;
      }
    );
    this.props.filterState.filterData.nullPrimaryEmail = this.props.filterState.filterData.nullPrimaryEmail.map(
      (item) => {
        item.checked = false;
        return item;
      }
    );
    this.props.filterState.filterData.veteran = this.props.filterState.filterData.veteran.map(
      (item) => {
        item.checked = false;
        return item;
      }
    );

    this.props.filterState.filterData.retiredMilitary = this.props.filterState.filterData.retiredMilitary.map(
      (item) => {
        item.checked = false;
        return item;
      }
    );
    this.props.filterState.filterData.facilityRoutingSymbol = null;
    this.props.filterState.filterData.orgCode = null;
    this.props.filterState.filterData.hourlySalary = null;
    this.props.filterState.filterData.annualSalary = null;
    this.props.filterState.filterData.email = null;
    this.props.filterState.filterData.plannedRetirement = null;
    this.props.filterState.filterData.retirement = null;
    this.props.filterState.filterData.scd = null;
    this.props.filterState.filterData.eodDate = null;
    this.props.filterState.filterData.natcabuDate = null;
    this.props.filterState.filterData.buDate = null;
    this.props.filterState.filterData.unionStartDate = null;
    this.props.filterState.filterData.unionJoinDate = null;
    this.props.filterState.filterData.finance.fromDuesAmount = null;
    this.props.filterState.filterData.finance.toDuesAmount = null;
    this.props.filterState.filterData.finance.duesBalance.checked = false;

    var apply = {
      searchType: 9,
      isApplied: true,
      isSearched: false,
      selectedItems: ["Active"],
    };
    this.props.filterState.filterData.searchApplied.push(apply);
    this.props.setFilterData({
      ...this.props.filterState.filterData,
    });

    if (this.props.filterState.isAdvancedFilterSelected) {
      this.props.onResetTable();
    }

    //this.getNewFilteredData();
    this.props.setAdvanceFilterLoader(true);
    this.props.getNewFilteredData(this.props.filterState.filterData, true);
    this.onReturnNo();
  }
  onCancel() {
    //console.log("Cancel called");
    $("html").removeClass("removeYScroll");
    this.stopBodyScrolling(false);
    if (!_.isEmpty(this.props.filterState.filterData.searchApplied)) {
      this.props.filterState.filterData.searchApplied = this.props.filterState.filterData.searchApplied.map(
        (item) => {
          if (item.isSearched) {
            item.isApplied = true;
          } else if (item.searchType === 9) {
            item.isApplied = true;
          } else {
            item.isApplied = false;
            switch (item.searchType) {
              case 4:
                //filteredText = "Age";
                store.dispatch(reset("ageForm"));
                this.props.filterState.filterData.age = null;
                break;
              case 7:
                //filteredText = "Employee Common ID";
                store.dispatch(reset("memberECIForm"));
                this.props.filterState.filterData.memberECI = [];
                break;
              case 8:
                //filteredText = "Facility";
                if (this.props.filterState.filterData.facility) {
                  this.props.filterState.filterData.facility = this.props.filterState.filterData.facility.map(
                    (item) => {
                      item.checked = false;
                      return item;
                    }
                  );
                }

                break;
              case 9:
                if (this.props.filterState.filterData.memberStatus) {
                  this.props.filterState.filterData.memberStatus = this.props.filterState.filterData.memberStatus.map(
                    (item) => {
                      item.memberStatus === "Active"
                        ? (item.checked = true)
                        : (item.checked = false);

                      return item;
                    }
                  );
                } else {
                  //console.log("member status else");
                }

                break;
              case 10:
                //filteredText = "Member Type";
                if (this.props.filterState.filterData.memberType) {
                  this.props.filterState.filterData.memberType = this.props.filterState.filterData.memberType.map(
                    (item) => {
                      item.checked = false;
                      return item;
                    }
                  );
                }

                break;
              case 11:
                //filteredText = "Region";
                if (this.props.filterState.filterData.region) {
                  this.props.filterState.filterData.region = this.props.filterState.filterData.region.map(
                    (item) => {
                      item.checked = false;
                      return item;
                    }
                  );
                }

                break;
              case 12:
                //filteredText = "Member Number";
                store.dispatch(reset("memberNoForm"));
                this.props.filterState.filterData.memberNumber = null;
                break;
              case 14:
                //filteredText = "Name";
                store.dispatch(reset("nameForm"));
                this.props.filterState.filterData.name = null;
                break;
              case 15:
                //filteredText = "Shirt Size";
                if (this.props.filterState.filterData.shirtSize) {
                  this.props.filterState.filterData.shirtSize = this.props.filterState.filterData.shirtSize.map(
                    (item) => {
                      item.checked = false;
                      return item;
                    }
                  );
                }

                break;
              case 16:
                //filteredText = "Gender";
                if (this.props.filterState.filterData.gender) {
                  this.props.filterState.filterData.gender = this.props.filterState.filterData.gender.map(
                    (item) => {
                      item.checked = false;
                      return item;
                    }
                  );
                }

                break;
              case 17:
                //filteredText = "PAC";
                store.dispatch(reset("pacForm"));
                this.props.filterState.filterData.pac = null;
                break;
              case 18:
                //filteredText = "DRF";
                store.dispatch(reset("drfForm"));
                this.props.filterState.filterData.drf = null;
                break;
              case 19:
                this.props.filterState.filterData.dutyStation = this.props.filterState.filterData.dutyStation.map(
                  (item) => {
                    item.checked = false;
                    return item;
                  }
                );
                break;
              case 20:
                this.props.filterState.filterData.unit = this.props.filterState.filterData.unit.map(
                  (item) => {
                    item.checked = false;
                    return item;
                  }
                );
                break;
              case 21:
                this.props.filterState.filterData.occupationSeries = this.props.filterState.filterData.occupationSeries.map(
                  (item) => {
                    item.checked = false;
                    return item;
                  }
                );
                break;
              case 22:
                this.props.filterState.filterData.careerLevel = this.props.filterState.filterData.careerLevel.map(
                  (item) => {
                    item.checked = false;
                    return item;
                  }
                );
                break;
              case 23:
                this.props.filterState.filterData.payGrade = this.props.filterState.filterData.payGrade.map(
                  (item) => {
                    item.checked = false;
                    return item;
                  }
                );
                break;
              case 24:
                this.props.filterState.filterData.flsaCategory = this.props.filterState.filterData.flsaCategory.map(
                  (item) => {
                    item.checked = false;
                    return item;
                  }
                );
                break;
              case 25:
                this.props.filterState.filterData.communication = this.props.filterState.filterData.communication.map(
                  (item) => {
                    item.checked = false;
                    return item;
                  }
                );
                break;
              case 26:
                this.props.filterState.filterData.address.addressTypes = this.props.filterState.filterData.address.addressTypes.map(
                  (item) => {
                    item.checked = false;
                    return item;
                  }
                );
                this.props.filterState.filterData.address.states = this.props.filterState.filterData.address.states.map(
                  (item) => {
                    item.checked = false;
                    return item;
                  }
                );
                this.props.filterState.filterData.address.city = "";
                this.props.filterState.filterData.address.country = "";
                this.props.filterState.filterData.address.zip = "";
                break;
              case 27:
                this.props.filterState.filterData.veteran = this.props.filterState.filterData.veteran.map(
                  (item) => {
                    item.checked = false;
                    return item;
                  }
                );
                break;
              case 28:
                this.props.filterState.filterData.retiredMilitary = this.props.filterState.filterData.retiredMilitary.map(
                  (item) => {
                    item.checked = false;
                    return item;
                  }
                );
                break;
              case 29:
                store.dispatch(reset("facilityRoutingSymbolForm"));
                this.props.filterState.filterData.facilityRoutingSymbol = null;
                break;
              case 30:
                store.dispatch(reset("orgCodeForm"));
                this.props.filterState.filterData.orgCode = null;
                break;
              case 31:
                store.dispatch(reset("hourlySalaryForm"));
                this.props.filterState.filterData.hourlySalary = null;
                break;
              case 32:
                store.dispatch(reset("annualSalaryForm"));
                this.props.filterState.filterData.annualSalary = null;
                break;
              case 33:
                store.dispatch(reset("emailForm"));
                this.props.filterState.filterData.email = null;
                break;
              case 34:
                store.dispatch(reset("plannedRetirementForm"));
                this.props.filterState.filterData.plannedRetirement = null;
                break;
              case 50:
                store.dispatch(reset("mandatoryRetirement"));
                this.props.filterState.filterData.plannedRetirement = null;
                break;
              case 35:
                store.dispatch(reset("sCDForm"));
                this.props.filterState.filterData.scd = null;
                break;
              case 36:
                store.dispatch(reset("eODDateForm"));
                this.props.filterState.filterData.eodDate = null;
                break;
              case 37:
                store.dispatch(reset("nATCABUDateForm"));
                this.props.filterState.filterData.natcabuDate = null;
                break;
              case 38:
                store.dispatch(reset("bUDateForm"));
                this.props.filterState.filterData.buDate = null;
                break;
              case 39:
                store.dispatch(reset("unionStartDateForm"));
                this.props.filterState.filterData.unionStartDate = null;
                break;
              case 40:
                store.dispatch(reset("unionJoinDateForm"));
                this.props.filterState.filterData.unionJoinDate = null;
                break;
              case 41:
                store.dispatch(reset("retirementForm"));
                this.props.filterState.filterData.retirement = null;
                break;
              case 42:
                store.dispatch(reset("teamPositionForm"));
                this.props.filterState.filterData.teamPosition = null;
                break;
              case 55:
                store.dispatch(reset("activeDatePositionForm"));
                this.props.filterState.filterData.activeTeamPositionDate = null;
                break;
              case 43:
                this.props.filterState.filterData.employer = this.props.filterState.filterData.employer.map(
                  (item) => {
                    item.checked = false;
                    return item;
                  }
                );
                break;
              case 56:
                this.props.filterState.filterData.congressionalDistrict = this.props.filterState.filterData.congressionalDistrict.map(
                  (item) => {
                    item.checked = false;
                    return item;
                  }
                );
                break;
            }
          }

          return item;
        }
      );
      this.props.setFilterData({
        ...this.props.filterState.filterData,
      });

      this.props.setAdvanceFilterLoader(true);
      // console.log(
      //   "Before cancel request:" +
      //     JSON.stringify(this.props.filterState.filterData.searchApplied)
      // );
      this.props.getNewFilteredData(this.props.filterState.filterData, false);
      this.onReturnNo();
    }
    const { routeFromSaveSearchDDL, savedSearchState } = this.props;
    if (routeFromSaveSearchDDL || savedSearchState.filtersDDLClicked) {
      $(".loading").show();
      setTimeout(() => {
        this.props.history.push("/directory/myfilters", {
          routeFromAdFilterOnCancel: true,
        });
        $(".loading").hide();
      }, 6000);
    }
  }
  onReturnNo() {
    /// API
    if (!_.isEmpty(this.props.filterState.filterData.searchApplied)) {
      this.props.getMemberTotalCount(this.props.filterState.filterData);
    } else {
      this.props.setReturnResultNo(0);
    }
  }
  isAnyFormInvalid(formName) {
    if (isInvalid(formName)(this.props.currentState)) {
      return true;
    } else {
      return false;
    }
  }
  onSearch(e) {
    this.props.ResetlocalPaging();
    /// API
    const Forms = {
      Age: "ageForm",
      ECI: "memberECIForm",
      MemberNo: "memberNoForm",
      PAC: "pacForm",
      DRF: "drfForm",
      NAME: "nameForm",
      FacilityRoutingSymbolForm: "facilityRoutingSymbolForm",
      OrgCode: "orgCodeForm",
      HourlySalaryForm: "hourlySalaryForm",
      AnnualSalaryForm: "annualSalaryForm",
      EmailForm: "emailForm",
      PlannedRetirementForm: "plannedRetirementForm",
      RetirementForm: "retirementForm",
      SCDForm: "sCDForm",
      EODDateForm: "eODDateForm",
      NATCABUDateForm: "nATCABUDateForm",
      BUDateForm: "bUDateForm",
      UnionStartDateForm: "unionStartDateForm",
      UnionJoinDateForm: "unionJoinDateForm",
      TeamPositionForm: "teamPositionForm",
      ActiveDatePositionForm: "activeDatePositionForm",
    };

    let result = false;
    _.forEach(Forms, (value) => {
      if (this.isAnyFormInvalid(value)) {
        result = true;
      }
    });

    if (result) {
      toast.warn("Please correct the invalid Filter Types fields.");
      return false;
    }

    if (
      // !_.isEmpty(this.props.filterState.filterData.searchApplied) &&
      this.props.filterState.returnResultNo !== 0
    ) {
      _.forEach(this.props.filterState.filterData.searchApplied, (value) => {
        this.setAppliedFormValues(value.searchType);
      });
      this.props.setFilterResultPageNo(1);
      this.props.setFilterResultPageSize(25);
      $(".loading").show();
      axios
        .post(
          "/api/Member/AdvancedFilter?pageOffset=0&pageFetch=25&sortName=firstnameasc",
          this.props.filterState.filterData
        )
        .then(({ data }) => {
          if (data.status && data.status.toLowerCase() == "success") {
            //Sets the Al-Mashooor Users.Js Table for Members
            ///New Work FilterTab

            this.props.onChangeTable(data);
            this.props.setAllowFilterUpdateMembersTab(true);
            this.props.setFilterResult(data);
            if (this.props.advancedFilterOpenedInTab) {
              this.props.setIsAdvancedFilterOpenedInTab(true);
              this.props.setCurrentFilterConfigTab(
                FilterEmailConfigTabs.Members
              ); // Set Member Filter Tab to display Searched Members
            }
            this.props.setAdvancedSearch(true);
            this.props.setIsBasicSearchSelected(false);

            $(".cancelBtn").click();
            $("html").removeClass("removeYScroll");
            this.props.filterState.filterData.searchApplied = !_.isEmpty(
              this.props.filterState.filterData.searchApplied
            )
              ? this.props.filterState.filterData.searchApplied.map((item) => {
                  if (item.isApplied) {
                    item.isSearched = true;
                  } else {
                    item.isSearched = false;
                  }
                  return item;
                })
              : null;

            //console.log("here after" + JSON.stringify(this.props.filterState.filterData));
            this.props.setFilterData({
              ...this.props.filterState.filterData,
            });
          } else {
            //  console.log(data);
            toast.error(data.message);
          }
          //  console.log("After search" + JSON.stringify(this.props.filterState.filterData.searchApplied))
          $(".loading").hide();
        });
    } else {
      toast.warn("There are no records against this filter criteria.");
    }
  }

  setAppliedFormValues(id) {
    id = id.toString();
    switch (id) {
      case "14": //Name
        this.props.filterState.filterData.name = this.props.formValues.nameForm.values;

        break;
      case "4": //Age
        this.props.filterState.filterData.age = {
          FromAge: this.props.formValues.ageForm.values.Age.min,
          ToAge: this.props.formValues.ageForm.values.Age.max,
        };
        break;
      case "17": //PAC
        this.props.filterState.filterData.pac = {
          FromPAC: this.props.formValues.pacForm.values.PAC.min,
          ToPAC: this.props.formValues.pacForm.values.PAC.max,
        };
        break;

      case "18":
        this.props.filterState.filterData.drf = {
          FromDRF: this.props.formValues.drfForm.values.DRF.min,
          ToDRF: this.props.formValues.drfForm.values.DRF.max,
        };
        break;
      case "7": //ECI
        this.props.filterState.filterData.memberECI = this.props.formValues.memberECIForm.values.memberECI;

        break;
      case "12": // Member No
        this.props.filterState.filterData.memberNumber = this.props.formValues.memberNoForm.values.memberNumbers;
        break;
      case "29":
        this.props.filterState.filterData.facilityRoutingSymbol = this.props.formValues.facilityRoutingSymbolForm.values.FacilityRoutingSymbol;
        break;
      case "30":
        this.props.filterState.filterData.orgCode = this.props.formValues.orgCodeForm.values.OrgCode;
        break;
      case "31":
        this.props.filterState.filterData.hourlySalary = {
          FromHourlySalary: this.props.formValues.hourlySalaryForm.values
            .HourlySalary.min,
          ToHourlySalary: this.props.formValues.hourlySalaryForm.values
            .HourlySalary.max,
        };
        break;
      case "32":
        this.props.filterState.filterData.annualSalary = {
          FromAnnualSalary: this.props.formValues.annualSalaryForm.values
            .AnnualSalary.min,
          ToAnnualSalary: this.props.formValues.annualSalaryForm.values
            .AnnualSalary.max,
        };
        break;
      case "33":
        this.props.filterState.filterData.email = this.props.formValues.emailForm.values.Email;
        break;
      case "34":
        this.props.filterState.filterData.plannedRetirement = this.props.formValues.plannedRetirementForm.values;
        break;
      case "50":
        this.props.filterState.filterData.mandatoryRetirement = this.props.formValues.mandatoryRetirementForm.values;
        break;

      case "35":
        this.props.filterState.filterData.scd = this.props.formValues.sCDForm.values;
        break;

      case "36":
        this.props.filterState.filterData.eodDate = this.props.formValues.eODDateForm.values;
        break;

      case "37":
        this.props.filterState.filterData.natcabuDate = this.props.formValues.nATCABUDateForm.values;
        break;

      case "38":
        this.props.filterState.filterData.buDate = this.props.formValues.bUDateForm.values;
        break;

      case "39":
        this.props.filterState.filterData.unionStartDate = this.props.formValues.unionStartDateForm.values;
        break;

      case "40":
        this.props.filterState.filterData.unionJoinDate = this.props.formValues.unionJoinDateForm.values;
        break;
      case "41":
        this.props.filterState.filterData.retirement = this.props.formValues.retirementForm.values;
      case "42":
        this.props.filterState.filterData.teamPosition = this.props.formValues.teamPositionForm.values;
        break;
      case "55":
        this.props.filterState.filterData.activeTeamPositionDate = this.props.formValues.activeDatePositionForm.values;
        break;
      ////FE Equipment Advance Filter Tabs Apply Filter Work
      case FE_AdvanceFilter_NewTabs.MEM_AF_INV_DESC: //description
        this.props.filterState.filterData.fe_inventory_desc = this.props.formValues.fe_mem_descriptionForm.values.Description;

        break;
      case FE_AdvanceFilter_NewTabs.MEM_AF_INV_BOOKVAL: //BookValue
        this.props.filterState.filterData.fe_inv_book_vals = {
          FromBookValue: this.props.formValues.fe_mem_bookValsForm.values
            .BookValue.min,
          ToBookValue: this.props.formValues.fe_mem_bookValsForm.values
            .BookValue.max,
        };
      default:
        break;
    }
  }
  mobileHeaderBtns() {
    return (
      <div
        className="modal-header customMobileHeader pt-2 pl-0 pb-0 pr-0"
        style={{ background: "#759fbc" }}
      >
        <div className="mobileModalHeader col-5 pl-0 pr-0 pb-0">
          <h6
            className="modal-title advancefilterMT mt-0 headerMobileColor pl-2"
            style={{ display: "block ruby", position: "fixed" }}
          >
            ADVANCED FILTER
          </h6>
        </div>
        <div className="mobileHeaderBtns" style={{ marginTop: "-0.1rem" }}>
          <TagsActionButtons isMobile={true} />
        </div>
      </div>
    );
  }
  webModalHeader() {
    return (
      <div className="modal-header pt-0 pb-0" style={{ display: "block" }}>
        <div className="row header-back">
          <Col xs="3" sm="3" md="3" xl="3" lg="3" className="webHeader">
            <h5 className="modal-title mt-0">ADVANCED FILTER</h5>
          </Col>
          <Col xs="9" sm="9" md="9" xl="9" lg="9" />
        </div>
      </div>
    );
  }
  onReturnNo = () => {
    /// API
    if (!_.isEmpty(this.props.filterState.filterData.searchApplied)) {
      this.props.getMemberTotalCount(this.props.filterState.filterData);
    } else {
      this.props.setReturnResultNo(0);
    }
  };
  onAccordionClick = (id) => {
    const currentActiveAccordion = this.props.filterState.activeTab;
    if (id !== currentActiveAccordion) {
      this.props.setActiveTab(id);
    } else {
      this.props.setActiveTab("0");
    }
  };
  onChildAccordionClick = (id) => {
    const currentChildActiveAccordion = this.props.filterState.childAccordion;
    if (id !== currentChildActiveAccordion) {
      this.props.activeFacilityChildAccordion(id);
    } else {
      this.props.activeFacilityChildAccordion("0");
    }
  };

  onDesktopAccordionClick = (id) => {
    const currentActiveAccordion = this.props.filterState.collaps;
    if (id !== currentActiveAccordion) {
      if (id === "1") {
        this.props.setActiveTab("9");
      }
      this.props.setCollapseTab(id);
    } else {
      this.props.setCollapseTab("0");
      this.props.setActiveTab("0");
    }
  };
  onParentAccordionClick = (id) => {
    const currentActiveAccordion = this.props.filterState.collaps;
    if (id !== currentActiveAccordion) {
      this.props.setCollapseTab(id);
    } else {
      this.props.setCollapseTab("0");
      this.props.setActiveTab("0");
    }

    this.resize();
  };
  stopBodyScrolling = (bool) => {
    if (bool === true) {
      document.body.addEventListener("touchmove", this.freezeVp, false);
    } else {
      document.body.removeEventListener("touchmove", this.freezeVp, false);
    }
  };
  freezeVp = function(e) {
    e.preventDefault();
  };
  //Default Members Advanced filter Modal
  fullModalHTML = () => {
    return (
      <AUX>
        <div
          className={`modal fade AdvanceFilterModal`}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="Advanced Filter"
          aria-hidden="true"
          id="AdvanceFilterModal"
        >
          {this.onlyModalBodyHTML(false)}
        </div>
      </AUX>
    );
  };
  /////// *******************Used for Edit Saved Filter Tab*************************/////////
  onlyModalBodyHTML = (isEditFilterModal) => {
    return (
      <div
        className={`modal-dialog modal-dialog-margin  modal-xl ${window.innerWidth >
          897 && "modal-dialog-centered modal-dialog"}  ${window.innerWidth <=
          897 && "mobile-modal-dialog"} `}
      >
        <div className="modal-content">
          {window.innerWidth <= 897
            ? this.mobileHeaderBtns()
            : this.webModalHeader()}

          <div
            className={`modal-body pt-0 ${window.innerWidth <= 897 &&
              "pb-1 modal-body-custom"}`}
          >
            <div />
            <Row>
              {this.props.filterState.showWebSideBar ? (
                <DesktopSideBar
                  onAccordionClick={this.onDesktopAccordionClick}
                  onChildAccordionClick={this.onChildAccordionClick}
                  isEditFilterModal={isEditFilterModal}
                />
              ) : (
                <MobileSideBar
                  onAccordionClick={this.onAccordionClick}
                  onParentAccordionClick={this.onParentAccordionClick}
                />
              )}

              {this.props.filterState.showWebSideBar ? (
                <DesktopTabContents
                  dontAllowEditFilter={this.props.dontAllowEditFilter}
                />
              ) : null}
            </Row>

            {/* Mobile tags works start  */}

            {/* Tags for advance filter */}
            {window.innerWidth <= 897 && (
              <>
                <Row className="mobFilterSpan">
                  <Col className="p-0 smallFont">
                    <SelectedFilter isMobile={true} />
                  </Col>
                </Row>
                <div className="mobileUIFilter">
                  <Row className="bottomBorderMobile">
                    <Col className="mb-2 mt-2 mr-2 ml-2 p-0 tagsScroll smallFont">
                      <Tags />
                    </Col>
                  </Row>
                </div>

                <div className="footerBtns">
                  {/* <Row>
                        <Col className="pl-0 pr-0 pt-1 pb-1">
                          <div className="mobileHeaderBtns ButtonsRadius">
                            <TagsActionButtons isMobile={true} />
                          </div>
                        </Col>
                      </Row> */}

                  <div
                    className={
                      window.innerWidth <= 897
                        ? "col-md-12 col-sm-12 col-xs-12 col-lg-12 col-xl-12 text-center"
                        : "col-12 text-right p-4"
                    }
                  >
                    <button
                      type="button"
                      className="cancelBtn"
                      data-dismiss="modal"
                      aria-hidden="true"
                      style={{ display: "none" }}
                    />
                    {!this.props.advancedFilterOpenedInTab && (
                      <button
                        className={`btn btn-danger cancelModal ${window.innerWidth >=
                          897 &&
                          "w-md waves-effectwaves-light"}  m-1 m-sm-1 ${smallBtns}`}
                        data-dismiss="modal"
                        aria-hidden="true"
                        onClick={() => this.onCancel()}
                      >
                        Cancel {/* Mobile Cancel Button */}
                      </button>
                    )}

                    <button
                      onClick={() => this.resetALLFilters()}
                      className={`btn btn-primary btn-success ${window.innerWidth >=
                        897 &&
                        "w-md waves-effect waves-light"} m-1 m-sm-1 ${smallBtns}`}
                      type="button"
                    >
                      Reset All
                    </button>

                    {!this.props.filterState.loadingAdvanceFilter &&
                      window.innerWidth > 897 && (
                        <button
                          onClick={() => this.onSearch()}
                          className={`btn btn-success ${window.innerWidth >=
                            897 &&
                            "w-md waves-effect waves-light"}  m-1 m-sm-1 ${smallBtns}`}
                          type="button"
                        >
                          Search
                        </button>
                      )}

                    {window.innerWidth <= 897 && (
                      <button
                        onClick={() => this.onSearch()}
                        className={`btn btn-success ${window.innerWidth >=
                          897 &&
                          "w-md waves-effect waves-light"}  m-1 m-sm-1 ${smallBtns}`}
                        type="button"
                      >
                        Search
                      </button>
                    )}
                    {this.props.filterState.loadingAdvanceFilter &&
                      window.innerWidth > 897 &&
                      loadingBtn}
                  </div>
                  <Row>
                    <div className="col-12 text-center">
                      <span className="MobiletotalCountMmeber">
                        {this.props.filterState.returnResultNo +
                          "Members will be returned"}
                      </span>
                    </div>
                  </Row>
                </div>
              </>
            )}
            {/* END Mobile tags works */}

            {window.innerWidth > 897 && (
              <Row>
                <div
                  className={
                    window.innerWidth <= 760
                      ? "col-md-12 col-sm-12 col-xs-12 col-lg-12 col-xl-12 text-center"
                      : "col-12 text-right p-4"
                  }
                >
                  {window.innerWidth >= 760 && (
                    <span className="totalCountMmeber">
                      {this.props.filterState.returnResultNo +
                        " Members will be returned"}
                    </span>
                  )}

                  <button
                    type="button"
                    className="cancelBtn"
                    data-dismiss="modal"
                    aria-hidden="true"
                    style={{ display: "none" }}
                  />
                  {/* //Don't show Cancel button for Edit Filter Modal// */}
                  {!this.props.advancedFilterOpenedInTab && (
                    <button
                      className={`btn btn-danger w-md waves-effect cancelModal waves-light m-1 m-sm-1 ${smallBtns}`}
                      data-dismiss="modal"
                      aria-hidden="true"
                      onClick={() => this.onCancel()}
                    >
                      Cancel
                    </button>
                  )}

                  <button
                    onClick={() => this.resetALLFilters()}
                    className={`btn btn-primary btn-success w-md waves-effect waves-light m-1 m-sm-1 ${smallBtns}`}
                    disabled={this.props.dontAllowEditFilter}
                    type="button"
                  >
                    Reset All
                  </button>

                  {!this.props.filterState.loadingAdvanceFilter && (
                    <button
                      onClick={() => this.onSearch()}
                      className={`btn btn-success w-md waves-effect waves-light m-1 m-sm-1 ${smallBtns}`}
                      type="button"
                      disabled={this.props.dontAllowEditFilter}
                    >
                      Search
                    </button>
                  )}
                  {this.props.filterState.loadingAdvanceFilter && loadingBtn}
                </div>
              </Row>
            )}
          </div>
        </div>
      </div>
    );
  };
  render() {
    const { showOnlyBody } = this.props;
    return showOnlyBody ? this.onlyModalBodyHTML(true) : this.fullModalHTML();
  }
}
const smallBtns = window.innerWidth <= 897 ? "btn-filter-sm" : "";
const loadingBtn = (
  <button class="btn btn-success m-1 m-sm-1" type="button">
    <span
      class="spinner-grow spinner-grow-sm"
      role="status"
      aria-hidden="true"
    />
    Loading...
  </button>
);
export default connect(
  (state) => ({
    filterState: state.advancedFilter,
    formValues: state.form,
    currentState: state,
    savedSearchState: state.saveSearch,
  }),
  { ...actionFilter, ...savedSearchAction, ...mailGunEmailActions }
)(AdvanceFilter);
