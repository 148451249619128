import React, { Component, Fragment } from "react";
import AUX from "../../../hoc/Aux_";
import { Button } from "reactstrap";
import $ from "jquery";
import _ from "lodash";
import { toast } from "react-toastify";
import "../../../assets/css/TeamStyle.css";
import Services from "../PermissionService";
import { connect } from "react-redux";
import PermissionAction from "../../../store/permission/action";

import AuthService from "../../Auth/AuthService";
import PermissionFeature from "../PermissionFeature";
class DefaultPermissionContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      teamName: "",
      type: this.props.type,
      parentTeamId: this.props.parentTeamId,
      permissionTeamId: this.props.permissionTeamId,
      originalPermissions: [],
      permissions: [],
    };

    this.clonePermission = this.clonePermission.bind(this);
    this.buttonLoader = this.buttonLoader.bind(this);

    this.permissions = this.permissions.bind(this);
    this.verifyData = this.verifyData.bind(this);
    this.cancelModal = this.cancelModal.bind(this);
    this.cancelButtonBottom = this.cancelButtonBottom.bind(this);
    this.cancelButtonLoader = this.cancelButtonLoader.bind(this);
    this.savePermissionData = this.savePermissionData.bind(this);
    this.handleSavePermission = this.handleSavePermission.bind(this);
    this.handleResetPermission = this.handleResetPermission.bind(this);
    this.handleChangePermission = this.handleChangePermission.bind(this);
    this.handleConfigurePermissionSection = this.handleConfigurePermissionSection.bind(
      this
    );
    this.loadData = this.loadData.bind(this);
  }

  ConvertPermissionToNewScheme(permissions) {
    let originalPermissions = [];
    const createPermissions = (permissions) => {
      permissions.map((item) => {
        originalPermissions.push(Object.assign({}, item));
        if (item.childs) {
          createPermissions(item.childs);
        }
      });
    };
    createPermissions(permissions);
    return originalPermissions;
  }

  clonePermission = (originalPermissions) =>
    originalPermissions.map((item) =>
      (({ isView, isAdd, isUpdate, isDelete, id }) => {
        return {
          id: item.userOwnPermissionID,
          permissionFeatureId: item.id,
          isView,
          isAdd,
          isUpdate,
          isDelete,
        };
      })(item)
    );

  handleCancelPermission(e) {
    //e.preventdefault();
    this.handleResetPermission();
    //window.$("#DPModal").modal("hide");
    this.props.closeDefaultPermissionModal();
  }

  handleResetPermission(e) {
    //e.preventdefault();
    this.state.permissions = this.clonePermission(
      this.state.originalPermissions
    );

    this.setState({
      permissions: this.state.permissions,
    });
  }

  handleChangePermission(e) {
    let Index = -1;
    Index = e.target.dataset.index;
    let disable = e.target.dataset.disabled;
    var permissions = this.state.permissions;
    let ListId = $(e.target)
      .parents(".permissionsList")
      .attr("id");
    let checked = false;
    if (disable == "false") {
      checked = e.target.checked;
      permissions[Index][e.target.dataset.permission] = checked;
      $(e.target).attr("data-checked", checked);
    }
    if (
      ((navigator.platform.toUpperCase().indexOf("MAC") >= 0 &&
        e.nativeEvent.metaKey) ||
        (navigator.platform.toUpperCase().indexOf("MAC") == -1 &&
          e.nativeEvent.ctrlKey)) &&
      !_.isEmpty(ListId)
    ) {
      if (disable == "true") {
        checked = $(e.target).attr("data-checked") == "false";
        $(e.target).attr("data-checked", checked);
      }
      ListId = "#" + ListId;
      const toggleChild = (parentid) => {
        $(
          ListId +
            " [data-parent='" +
            parentid +
            "'][data-permission='" +
            e.target.dataset.permission +
            "']"
        ).each(function(i) {
          let parentid2 = $(this).attr("data-index");
          let disableChild = $(this).attr("data-disabled");
          permissions[parentid2][e.target.dataset.permission] =
            disableChild == "false" ? checked : false;
          $(this).attr("data-checked", checked);
          if (
            $(
              ListId +
                " [data-parent='" +
                parentid2 +
                "'][data-permission='" +
                e.target.dataset.permission +
                "']"
            ).length > 0
          ) {
            toggleChild(parentid2);
          }
        });
      };
      toggleChild(Index);
    }
    this.setState({ permissions: this.state.permissions });
  }

  handleSavePermission = async (e) => {
    $(".loading").show();
    let data = await Services.EditUserOwnPermission(this.state.permissions);
    if (!_.isEmpty(data)) {
      if (data.statusCode == 200) {
        toast.success("Own Permission edited successfully!");
        //this.props.setReloadPosition();
        this.handleConfigurePermissionSection();
        this.props.closeDefaultPermissionModal();
        //            window.$("#DPModal").modal("hide");
      }
    }

    $(".loading").hide();
  };

  performSlide(parentid) {
    let element = $(
      ".slide-default-collapsers[data-slide-Id='slide-default" + parentid + "']"
    );
    let expand = element.attr("data-slide-expand");

    const collapseChild = (parentid, expand) => {
      let element = $(
        ".slide-default-collapsers[data-slide-Id='" + parentid + "']"
      );

      if (expand) {
        $(
          ".slide-default-collapsers[data-slide-parentId='" + parentid + "']"
        ).addClass("perm-row-hide");
      } else {
        let expandChild = element.attr("data-slide-expand");

        if (expandChild == "true") {
          $(
            ".slide-default-collapsers[data-slide-parentId='" + parentid + "']"
          ).removeClass("perm-row-hide");
        }
        //in case false
        else {
          expand = true;
        }
      }
      $(
        ".slide-default-collapsers[data-slide-parentId='" + parentid + "']"
      ).each(function(i) {
        let parentid2 = $(this).attr("data-slide-Id");
        if (
          $(
            ".slide-default-collapsers[data-slide-parentId='" + parentid2 + "']"
          ).length > 0
        ) {
          collapseChild(parentid2, expand);
        }
      });
    };

    if (expand == "true") {
      $(
        ".slide-default-collapsers[data-slide-parentId='slide-default" +
          parentid +
          "']"
      ).addClass("perm-row-hide");
      element.attr("data-slide-expand", false);
    } else {
      $(
        ".slide-default-collapsers[data-slide-parentId='slide-default" +
          parentid +
          "']"
      ).removeClass("perm-row-hide");
      element.attr("data-slide-expand", true);
    }
    collapseChild("slide-default" + parentid, expand == "true" ? true : false);
  }

  loadPermission = (
    permissions,
    originalPermissions,
    uncheckClass,
    handleChangePermission,
    hasParent
  ) => {
    //(row == isView)            update delete add

    return (
      <div className="mb-3 permissionsList" id="userOwnPermission">
        <div className="w-100">
          <div className="row mx-0">
            <div className="perm-col-1"></div>
            <div className="perm-col-2 text-center">VIEW</div>
            <div className="perm-col-2 text-center">ADD</div>
            <div className="perm-col-2 text-center">UPDATE</div>
            <div className="perm-col-2 text-center">DELETE</div>
          </div>
          <div>
            {permissions.map((item, idx) => {
              let rowClasses = "";

              switch (this.state.originalPermissions[idx].featureType) {
                case 1:
                  rowClasses = " bg-dark text-white";
                  break;
                case 2:
                  rowClasses = " bg-perm-info text-white";
                  break;
                case 3:
                  rowClasses = " bg-primary text-white";
                  break;
                case 4:
                  rowClasses = "";
                  break;
                default:
              }

              let tempData = _.cloneDeep(this.state.originalPermissions)
                .splice(0, idx)
                .reverse();
              let lessIndex = tempData.findIndex(
                (x) =>
                  x.featureType <
                  this.state.originalPermissions[idx].featureType
              );

              let currentParent = lessIndex != -1 ? idx - lessIndex - 1 : -1;
              rowClasses =
                rowClasses +
                (currentParent == -1 ||
                this.state.originalPermissions[idx].featureType == 3
                  ? ""
                  : " perm-row-hide");
              let expand = idx == 0 ? true : false;
              let viewCond =
                (originalPermissions[idx].isView &&
                  this.state.originalPermissions[idx].isViewAvail &&
                  hasParent) ||
                (!hasParent && this.state.originalPermissions[idx].isViewAvail);
              let addCond =
                (originalPermissions[idx].isAdd &&
                  this.state.originalPermissions[idx].isAddAvail &&
                  hasParent) ||
                (!hasParent && this.state.originalPermissions[idx].isAddAvail);
              let updCond =
                (originalPermissions[idx].isUpdate &&
                  this.state.originalPermissions[idx].isUpdateAvail &&
                  hasParent) ||
                (!hasParent &&
                  this.state.originalPermissions[idx].isUpdateAvail);
              let delCond =
                (originalPermissions[idx].isDelete &&
                  this.state.originalPermissions[idx].isDeleteAvail &&
                  hasParent) ||
                (!hasParent &&
                  this.state.originalPermissions[idx].isDeleteAvail);
              let viewChildCheck = false;
              let addChildCheck = false;
              let updChildCheck = false;
              let delChildCheck = false;
              let lastIndex = this.state.originalPermissions.length - 1;
              if (idx != lastIndex) {
                let tempData2 = _.cloneDeep(
                  this.state.originalPermissions
                ).splice(idx + 1, lastIndex);

                let lessIndex2 = tempData2.findIndex(
                  (x) =>
                    x.featureType <=
                    this.state.originalPermissions[idx].featureType
                );
                if (lessIndex2 == -1) {
                  lessIndex2 = tempData2.filter(
                    (x) =>
                      x.featureType >
                      this.state.originalPermissions[idx].featureType
                  ).length;
                }
                //console.log(lessIndex2);
                tempData2 = _.cloneDeep(permissions).splice(
                  idx + 1,
                  lessIndex2
                );
                viewChildCheck =
                  tempData2.findIndex((x) => x.isView == true) != -1;
                addChildCheck =
                  tempData2.findIndex((x) => x.isAdd == true) != -1;
                updChildCheck =
                  tempData2.findIndex((x) => x.isUpdate == true) != -1;
                delChildCheck =
                  tempData2.findIndex((x) => x.isDelete == true) != -1;
              }

              return (
                <Fragment>
                  <div
                    className={
                      (idx != 0 ? "slide-default-collapsers " : "") +
                      "perm-row row mx-0" +
                      rowClasses
                    }
                    onClick={(e) => {
                      if (idx != 0) {
                        this.performSlide(idx);
                      }
                    }}
                    data-slide-expand={expand}
                    data-slide-Id={idx != 0 ? "slide-default" + idx : ""}
                    data-slide-parentId={
                      currentParent != -1 && "slide-default" + currentParent
                    }
                  >
                    <div className="perm-col-1 p-2">
                      {this.state.originalPermissions[idx].featureName}
                    </div>

                    <div className="perm-col-2 text-center p-2">
                      <div
                        class={"round-check" + (viewCond ? uncheckClass : "")}
                      >
                        <input
                          type="checkbox"
                          checked={item.isView}
                          data-disabled={!viewCond}
                          data-checked={item.isView}
                          data-child-check={viewChildCheck}
                          data-index={idx}
                          data-permission="isView"
                          data-parent={currentParent}
                          onChange={handleChangePermission}
                          id={"SubFeaturesDefault-isView-" + idx}
                          name={"SubFeaturesDefault-isView-" + idx}
                        />
                        <label for={"SubFeaturesDefault-isView-" + idx}></label>
                      </div>
                    </div>
                    <div className="perm-col-2 text-center p-2">
                      <div
                        class={"round-check" + (addCond ? uncheckClass : "")}
                      >
                        <input
                          type="checkbox"
                          checked={item.isAdd}
                          //         disabled = {!addCond}
                          data-index={idx}
                          data-permission="isAdd"
                          data-disabled={!addCond}
                          data-child-check={addChildCheck}
                          data-checked={item.isAdd}
                          data-parent={currentParent}
                          onChange={handleChangePermission}
                          id={"SubFeaturesDefault-isAdd-" + idx}
                          name={"SubFeaturesDefault-isAdd-" + idx}
                        />
                        <label for={"SubFeaturesDefault-isAdd-" + idx}></label>
                      </div>
                    </div>
                    <div className="perm-col-2 text-center p-2">
                      <div
                        class={"round-check" + (updCond ? uncheckClass : "")}
                      >
                        <input
                          type="checkbox"
                          checked={item.isUpdate}
                          data-index={idx}
                          data-permission="isUpdate"
                          data-disabled={!updCond}
                          data-child-check={updChildCheck}
                          data-checked={item.isUpdate}
                          data-parent={currentParent}
                          onChange={handleChangePermission}
                          id={"SubFeaturesDefault-isUpdate-" + idx}
                          name={"SubFeaturesDefault-isUpdate-" + idx}
                        />
                        <label
                          for={"SubFeaturesDefault-isUpdate-" + idx}
                        ></label>
                      </div>
                    </div>
                    <div className="perm-col-2 text-center p-2">
                      <div
                        class={"round-check" + (delCond ? uncheckClass : "")}
                      >
                        <input
                          type="checkbox"
                          checked={item.isDelete}
                          data-index={idx}
                          data-permission="isDelete"
                          data-parent={currentParent}
                          data-child-check={delChildCheck}
                          data-disabled={!delCond}
                          data-checked={item.isDelete}
                          onChange={handleChangePermission}
                          id={"SubFeaturesDefault-isDelete-" + idx}
                          name={"SubFeaturesDefault-isDelete-" + idx}
                        />
                        <label
                          for={"SubFeaturesDefault-isDelete-" + idx}
                        ></label>
                      </div>
                    </div>
                  </div>
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  sectionLoader = () => {
    if (!_.isEmpty(this.state.permissions)) {
      return this.permissions();
    }
  };

  cancelModal = (e) => {
    this.handleCancelPermission();
  };
  verifyData = (e) => {
    this.verifyPermissions();
  };
  buttonLoader = () => {
    return this.savePermissionButton();
  };

  cancelButtonLoader = () => {
    return (
      <button type="button" class="close text-white" onClick={this.cancelModal}>
        <span aria-hidden="true">&times;</span>
      </button>
    );
  };

  handleConfigurePermissionSection = async (e) => {
    //$(".loading").show();

    let data = await Services.UserOwnPermissions();
    if (!_.isEmpty(data)) {
      this.state.originalPermissions = this.ConvertPermissionToNewScheme(
        data.result
      );
      this.state.originalPermissions = this.ConvertPermissionToNewScheme(
        this.state.originalPermissions.filter((x) => x.id == 44)
      );
      await this.setState({
        originalPermissions: this.state.originalPermissions,
      });
      this.props.setUserOwnRecordData(this.state.originalPermissions);
    }
    this.handleResetPermission();
    //$(".loading").hide();
  };

  permissions = () => {
    return this.loadPermission(
      this.state.permissions,
      this.state.originalPermissions,
      " uncheck-light",
      this.handleChangePermission,
      this.state.type === "childteam"
    );
  };

  cancelButtonBottom = () => {
    return (
      <button
        className="btn btn-danger btn-x-sm btn-sm w-md waves-effect waves-light"
        type="button"
        onClick={this.cancelModal}
      >
        Cancel
      </button>
    );
  };

  savePermissionButton = () => {
    return (
      <Fragment>
        <hr />
        <div className="row pt-3">
          <div className="col-4"></div>
          <div className="col-8">
            <div className="text-right">
              {this.cancelButtonBottom()}
              {AuthService.canUpdate(PermissionFeature.DefaultPermission) && (
                <button
                  onClick={this.savePermissionData}
                  className="btn btn-success btn-x-sm btn-sm ml-2 w-md waves-effect waves-light"
                  type="submit"
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  savePermissionData = (e) => {
    this.handleSavePermission();
  };
  componentWillReceiveProps(nextProps) {}
  loadData() {
    if (this.props.permissionState.loadDataOwnPermission) {
      if (!_.isEmpty(this.props.permissionState.userOwnRecordData)) {
        this.state.originalPermissions = this.props.permissionState.userOwnRecordData;
        this.setState({
          originalPermissions: this.state.originalPermissions,
        });
        this.handleResetPermission();
      } else {
        this.handleConfigurePermissionSection();
      }
      this.props.completeLoadDataOwnPermissiion();
    }
  }
  componentDidMount() {
    //    this.handleConfigurePermissionSection();
    // this.GetPermissions();
  }

  render() {
    this.loadData();
    return (
      <AUX>
        {this.sectionLoader()}

        {this.buttonLoader()}
      </AUX>
    );
  }
}

export default connect((state) => ({ permissionState: state.permission }), {
  ...PermissionAction,
})(DefaultPermissionContent);
