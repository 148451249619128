/// <reference path="../../../public/assets/js/bootstrap.bundle.min.js" />
/// <reference path="../../../public/assets/js/bootstrap.bundle.min.js" />
import React, { Component, useRef, useStat } from "react";
import { withRouter } from "react-router-dom";
import GrievanceHeader from "./General/GrievanceHeader";
import GrievanceAction from "../../store/grievance/action";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { MDBInput } from "mdbreact";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import AUX from "../../hoc/Aux_";
import authAxios from "../Shared/auth-header";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Services from "./GrievanceService";
import moment from "moment";
import $ from "jquery";
import "../Grievances/grievances.css";
import _ from "lodash";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";
import { PdfDocument } from "../../modules/Grievances/General/GrievanceStepPDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PARPdfDocument } from "../../modules/Grievances/General/GrievancePARPDF";
import GrievanceDetailsOnlyModal from "./General/GrievanceDetailsOnlyModal";
import GrievanceSearchModal from "./General/GrivanceSearchModal";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import GrievanceGridDuplicateForm from "./General/GrievanceGridDuplicateForm";
import GrievanceEditForm from "./General/GrievanceEditForm";
import { PARMultiPdfDocument } from "./General/GrievanceMultiPARPDF";
import Tooltip from "./General/Tooltip";
const filter = createFilterOptions();
class Grievances extends Component {
  pdfRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      sizePerPage: 10,
      page: 1,
      pdfData: {},
      parPDFRegionId: null,
      parPDFRegion: null,
      show: false,
      showPdf: false,
      customSortApplied: false,
      isClean: true,
      showClearButton: false,
      searchTerm: "",
      searchType: "",
      grievancesData: [],
      completeGrievancesList: [],
      filteredGrievances: [],
      ActiveTab: -1,
      regions: [],
      region: {},
      assignedGRT: {},
      assignedLR: {},
      assignedUserList: [],
      assignedGRTUserList: [],
      facilities: [],
      facility: {},
      contractArticles: [],
      contractArticle: {},
      contractList: [],
      articlesList: [],
      parFAAContacts: [],
      grievanceViewOnlyData: [],
      selectedGrievanceForDetail: "",
      selectedGrievanceStatus: "",
      searchTermStatus: "",
      searchTermRegion: "",
      searchTermFacility: "",
      searchTermLR: "",
      searchTermArticle: "",
      searchTermContract: "",
      searchTermGRT: "",
      grievanceCount: 0,

      showDuplicateGrievanceModal: false,
      showEditGrievanceModal: false,
      grievanceIdForDup: 0,
      currentDuplicateGrievanceId: 0,
      currentDuplicateStepId: 0,
      currentEditGrievanceId: 0,
      expanded: [],
      selectedGrievanceForPAR: [],
      selectedGrievancePARDATA: [],
      searchkeyword: "",
      stepsList: ["Step 1", "Step 2", "PAR", "Arbitration", "Expedited"],
      statuses: [
        { statusId: 1, status: "Draft" },
        { statusId: 2, status: "Active" },
        { statusId: 3, status: "Held in Abeyance" },
        { statusId: 4, status: "Remanded" },
        { statusId: 5, status: "Sustained" },
        { statusId: 6, status: "Denied" },
        { statusId: 7, status: "Withdrawn" },
        { statusId: 8, status: "Settled" },
      ],
      isConfirmModalOpen: false,
      isSearchModalOpen: false,
    };
    this.rowClasses = (row, rowIndex) => {
      let classes = null;
      if (row.status == "Draft") {
        classes = "bg-light-blue";
      }
      if (row.canEdit) {
        classes = "c-pointer";
      }
      if (!row.canEdit) {
        classes = "bg-light-grey";
      }
      return classes;
    };

    this.RemotePagination = this.RemotePagination.bind(this);
    this.SizePerPageChange = this.SizePerPageChange.bind(this);
    this.filterMyRequest = this.filterMyRequest.bind(this);
    this.viewAllLink = this.viewAllLink.bind(this);
    this.getColumn = this.getColumn.bind(this);
    this.sortDataArticles = this.sortDataArticles.bind(this);
    this.handleClearSearch = this.handleClearSearch.bind(this);
    this.changeActiveTab = this.changeActiveTab.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.expandRow = this.expandRow.bind(this);
    this.OnPageChange = this.OnPageChange.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.expandFirst = this.expandFirst.bind(this);
    this.expandAll = this.expandAll.bind(this);
    this.handlePARMultiPDFDownload = this.handlePARMultiPDFDownload.bind(this);
    this.loadDraftRequestData = this.loadDraftRequestData.bind(this);
    this.closeEditGrievanceModal = this.closeEditGrievanceModal.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.loadAllContracts = this.loadAllContracts.bind(this);
    this.filterArticlesBasedOnContracts = this.filterArticlesBasedOnContracts.bind(this);
  }
  closeDuplicateGrievanceModal = () => {
    this.setState({ showDuplicateGrievanceModal: false });
  };
  closeEditGrievanceModal = () => {
    this.setState({ showEditGrievanceModal: false });
  };

  handlePARMultiPDFDownload = () => {
    const pdf = this.pdfRef.current;
    pdf.save();
  };

  expandFirst() {
    let expanded = [];
    if (
      this.state.filteredGrievances &&
      !_.isEmpty(this.state.filteredGrievances)
    ) {
      expanded = [...expanded, this.state.filteredGrievances[0].status];
    }

    this.setState({
      expanded: expanded,
    });
    setTimeout(() => {
      this.setState({ show: true });
    }, 2000);
  }
  expandAll() {
    let expanded = [];
    if (
      this.state.filteredGrievances &&
      !_.isEmpty(this.state.filteredGrievances)
    ) {
      this.state.filteredGrievances.forEach((item) => {
        expanded = [...expanded, item.status];
      });
    }

    this.setState({
      expanded: expanded,
    });
    setTimeout(() => {
      this.setState({ show: true });
    }, 2000);
  }
  changeActiveTab(value) {
    this.setState({ ActiveTab: value });
  }
  toggleConfirmModal = () => {
    this.setState({ isConfirmModalOpen: !this.state.isConfirmModalOpen });
  };
  toggleSearchModel = () => {
    this.setState({ isSearchModalOpen: !this.state.isSearchModalOpen });
  };
  loadDraftRequestData = async (id) => {
    let result = await Services.LoadGrievanceDataById(id);
    if (result != null) {
      let {
        grievant,
        representative,
        articles,
        steps,
        recipient,
      } = result.data;
      const DraftKey = "CreateGrievanceForm";
      let articlesData = null;
      if (articles && articles != null && articles.length > 0) {
        articlesData = articles.map((x) => ({
          contractId: x.contractId,
          id: x.id,
          name: "Article " + x.articleIndex + " - " + x.articleName,
        }));
      }
      let greivantdata =
        grievant && grievant != null && grievant != undefined
          ? {
            id: grievant.id,
            memberNumber: grievant.membernumber,
            name:
              grievant.firstname +
              " " +
              (grievant.middlename ? grievant.middlename + " " : "") +
              grievant.lastname,
            firstName: grievant.firstname,
            lastName: grievant.lastname,
            nickName: grievant.nickname,
            previousLastName: grievant.previouslastname,
            email: null,
            phoneTypeID: null,
            phone: null,
            regionID: null,
            region: null,
            facilityID: null,
            facility: null,
            memberType: grievant.membertype
              ? grievant.membertype.description
              : null,
            memberTypeID: grievant.membertypeid,
            status: grievant.status,
            faaID: null,
          }
          : null;
      let representativeData =
        representative && representative != null && representative != undefined
          ? {
            id: representative.id,
            name:
              representative.firstname +
              " " +
              (representative.middlename
                ? representative.middlename + " "
                : "") +
              representative.lastname,
          }
          : null;
      let recipientData =
        recipient && recipient != null && recipient != undefined
          ? {
            id: recipient.id,
            name: recipient.recipientName,
          }
          : null;
      let createFormVariable = {
        id: id,
        grievant: greivantdata,
        articles: articlesData,
        representative: representativeData,
        recipient: recipientData,
        contract: "",
        grievant_err: "",
        representative_err: "",
        recipient_err: "",
        dateOfVoilation_err: "",
        dateSubmitted_err: "",
        contract_err: "",
        statementOfFacts_err: "",
        subject_err: "",
        remedySought_err: "",
        facility_err: "",
        files: [],
        filesTable: [],
      };
      let stepsData =
        steps && steps != undefined && steps != null && steps.length > 0
          ? {
            currentStep: steps[0].stepName,
            isAdverse: steps[0].isAdverse,
            isMeeting: steps[0].isMeeting,
            dateOfVoilation:
              steps[0].dateOfVoilation != "1/1/0001"
                ? steps[0].dateOfVoilation
                : null,
            dateSubmitted:
              steps[0].dateSubmitted != "1/1/0001"
                ? steps[0].dateSubmitted
                : null,
            meetingDate:
              steps[0].meetingDate != "1/1/0001"
                ? steps[0].meetingDate
                : null,
            subject: steps[0].subject,
            FAANumber: steps[0].fAANumber,
            statementOfFacts: steps[0].statementOfFacts,
            stepId: steps[0].stepId,
            remedySought: steps[0].remedySought,
          }
          : null;
      if (stepsData) {
        createFormVariable = { ...createFormVariable, ...stepsData };
      }
      localStorage.setItem(DraftKey, JSON.stringify(createFormVariable));
      this.props.history.push("/Grievances/addGrievance");
      this.changeActiveTab(2);
    }
  };
  loadAllRequests() {
    $(".loading").show();
    let grievancesList = [];
    Services.LoadAllGrievances(true).then((result) => {
      if (!_.isEmpty(result.data)) {
        _.forEach(result.data, function (el, index, arr) {
          grievancesList.push({
            dateFiled: el.dateFiled,
            facilityId: el.facilityId,
            facility: isMobile ? el.facilityCode : el.facilityName,
            grievanceId: el.grievanceId,
            region: el.regionName,
            assignedUserName: el.assignedUserName,
            assignedGRTUserName: el.assignedGRTUserName,
            assignedGRTUserId: el.assignedGRTUserId,
            assignedUserId: el.assignedUserId,
            articleName: el.articleName,
            articles: el.articles,
            articleId: el.articleId,
            contractId: el.contractId,
            gId: el.id,
            grievant: el.grievantName,
            regionId: el.regionId,
            status: el.status,
            step: el.step,
            canEdit: el.canEdit,
            representativeName: el.representativeName,
            subject: el.grievanceSubject,
            remainingDays: parseInt(el.daysRemainingForNextDueDate),
            pdfData: el.pdfData ? el.pdfData : null,
          });
        });
        this.setState({
          filteredGrievances: this.arrangeDataWithStatus(
            _.cloneDeep(grievancesList),
            10,
            1
          ),
          completeGrievancesList: grievancesList,
          grievancesData: grievancesList,
          sizePerPage: 10,
          customSortApplied: true,
          page: 1,
          grievanceCount: grievancesList.length,
        });
      }
      this.expandFirst();
      $(".loading").hide();
    });

    this.setState({
      filteredGrievances: this.arrangeDataWithStatus(
        _.cloneDeep(grievancesList),
        this.state.sizePerPage,
        1
      ),
      page: 1,
      grievancesData: grievancesList,
    });
    this.expandFirst();
  }
  arrangeDataWithStatus(List, sizePerPage, page) {
    let start = (page - 1) * sizePerPage,
      end = (page - 1) * sizePerPage - 1 + sizePerPage + 1;
    $(".loading").show();
    let List2 = List;
    if (this.state.customSortApplied == false) {
      List2 = _.orderBy(
        List2,
        ["status", "remainingDays", "gId"],
        ["asc", "asc", "desc"]
      );
    }
    List2 = List2.slice(start, end);
    let result = this.state.statuses
      .map((x) => {
        //let sizePerPage = this.state.sizePerPage;
        //let page = this.state.page,
        let data = List2.filter((y) => y.status == x.status);
        if (data && data.length > 0) {
          return {
            status: x.status,
            grievances: data,
          };
        }
      })
      .filter((el) => el);
    $(".loading").hide();
    return result;
  }
  loadMembersByRegion(regionId, searchTerm, searchType, region) {
    Services.GetLRRepresentatives(regionId).then((result) => {
      if (!_.isEmpty(result.data)) {
        var UserList = [];
        result.data.map((item) => {
          UserList.push({
            assignedUserName: item.firstname + " " + item.lastname,
          });
        });
        this.setState({
          assignedUserList: UserList,
          searchType: searchType,
          searchTerm: searchTerm,
          region: region,
        });
        //this.state.assignedUserList = UserList;
      }
    });
  }
  loadGRTMembers() {
    Services.GetGRTRepresentatives().then((result) => {
      if (!_.isEmpty(result.data)) {
        var GRTUserList = [];
        result.data.map((item) => {
          GRTUserList.push({
            assignedGRTUserName: item.firstname + " " + item.lastname,
          });
        });
        this.setState({
          assignedGRTUserList: GRTUserList,
        });
      }
    });
  }
  loadRegions() {
    let regionsList = [];
    authAxios.get("/api/Grievance/GetRegions").then(({ data }) => {
      if (!_.isEmpty(data.data)) {
        _.forEach(data.data, function (el, index, arr) {
          regionsList.push({
            regionId: el.id,
            region: el.code,
          });
        });
        this.setState({
          regions: regionsList,
        });
        let facilityList = [];
        authAxios.get("api/Grievance/GetFacilities/0").then(({ data }) => {
          if (!_.isEmpty(data.data)) {
            _.forEach(data.data, function (el, index, arr) {
              facilityList.push({
                facilityId: el.id,
                facility: el.code,
              });
            });
            if (!_.isEqual(this.state.facilities, facilityList)) {
              this.setState({
                facilities: facilityList,
              });
            }
          }
        });
      }
    });
  }
  loadArticles() {
    let articlesList = [];
    authAxios.get("/api/Grievance/GetAllArticles").then(({ data }) => {
      if (!_.isEmpty(data.data)) {
        this.setState({
          articlesList: data.data,
        });

      }
    });
  }
  async filterArticlesBasedOnContracts(contractIdString) {
    let articlesList = [];
    const contractId = parseInt(contractIdString, 10);
    if (!_.isEmpty(this.state.articlesList)) {

      articlesList = this.state.articlesList.filter(el => el.contractId === contractId) // Filter the articles by contractId
        .map(el => ({
          articleId: el.id,        // Map to the required format
          articleName: "Article - " + el.articleIndex + ", " + el.articleName.trim() // Trim any extra spaces
        }));

    }
    await this.setState({
      contractArticles: articlesList,
      searchTermContract: contractId
    });
  }
  async loadAllContracts() {
    let contractList = [];
    let data = await Services.loadAllContracts();
    if (!_.isEmpty(data.data)) {
      _.forEach(data.data, function (el, index, arr) {
        contractList.push({
          id: el.id,
          contractName: el.contractName,
        });
      });
      this.setState({
        contractList: contractList,
      });
    }

  }
  async loadParFAAContact() {
    let contactList = await Services.LoadPARFAAContacts();
    if (contactList != null) {
      await this.setState({ parFAAContacts: contactList });
    }

  }
  componentDidMount() {
    this.loadRegions();
    this.loadGRTMembers();
    this.loadAllRequests();
    this.loadArticles();
    this.loadAllContracts()
    this.loadParFAAContact();
    var grievanceCreateSuccessMsg = this.props.history.location.toastMsg;
    if (!_.isEmpty(grievanceCreateSuccessMsg)) {
      $(".loading").hide();
      toast.success(grievanceCreateSuccessMsg);
      this.setState({ ActiveTab: 1 });
    }
  }
  rowEvents = {
    onClick: (e, row, rowIndex) => {
      e.preventDefault();
      e.nativeEvent.stopImmediatePropagation();
      this.props.history.push({
        pathname: "/grievances/" + row.grievanceId,
        type: "create",
        uid: row.id,
      });
    },
  };
  getMeetingDate = (steps, currentStepName) => {
    var meetingStep = steps.filter(function (el) {
      return el.stepName == currentStepName + " Meeting";
    });
    if (meetingStep.length > 0) {
      return meetingStep[0].meetingDate;
    } else return "";
  };
  getResponseDate = (steps, currentStepName) => {
    var responseStep = steps.filter(function (el) {
      return el.stepName == currentStepName + " Response";
    });
    if (responseStep.length > 0) {
      return responseStep[0].responseDate;
    } else return "";
  };
  reActiveGrievance = (grievanceGeneratedId) => { };
  openInformationModal = (grievanceId) => {
    $(".loading").show();
    Services.LoadGrievanceDataById(grievanceId).then((result) => {
      var viewGrievanceStepObjects = [];
      var stepsCreated = "";
      this.state.stepsList.forEach((element) => {
        result.data.steps.forEach((step) => {
          if (
            step.stepName.indexOf(element) > -1 &&
            stepsCreated.indexOf(element) == -1
          ) {
            var viewGrievanceObject = {
              subject: step.subject,
              recipientName: result.data.recipient.recipientName,
              violation: step.articleVoilated,
              grievant:
                result.data.grievant.firstname +
                " " +
                result.data.grievant.lastname,
              statementOfFact: step.statementOfFacts,
              remedySought: step.remedySought,
              stepName: step.stepName,
              isMeeting: step.isMeeting ? "True" : "False",
              fAANumber: step.fAANumber,
              isAdverse: step.isAdverse ? "True" : "False",
              meetingDate: !step.isMeeting
                ? ""
                : this.getMeetingDate(result.data.steps, step.stepName),
              responseDate: this.getResponseDate(
                result.data.steps,
                step.stepName
              ),
              stepType: step.stepType,
              withdrawReason: result.data.WithdrawReason,
            };
            stepsCreated += step.stepName;
            viewGrievanceStepObjects.push(viewGrievanceObject);
          }
        });
      });
      this.setState({
        isConfirmModalOpen: !this.state.isConfirmModalOpen,
        grievanceViewOnlyData: viewGrievanceStepObjects,
        selectedGrievanceStatus: result.data.grievanceStatus,
        selectedGrievanceForDetail: result.data.grievanceGeneratedId,
      });
      $(".loading").hide();
    });
  };
  handleDuplicateClick = async (e, id) => {
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
    if (id != 0) {
      await Services.GetStepIdByGrievanceId(id).then((data) => {
        this.setState({
          currentDuplicateGrievanceId: id,
          currentDuplicateStepId: data.id,
          showDuplicateGrievanceModal: true,
        });
      });
    }
  };
  handleEditClick = async (e, id) => {
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
    if (id != 0) {
      await this.setState({
        currentEditGrievanceId: id,
        showEditGrievanceModal: true,
      });
    }
  };
  sortData = (grievancesData, sortField, sortOrder) => {
    const data = grievancesData;

    return data.sort((a, b) => {
      const aValue = a[sortField];
      const bValue = b[sortField];

      // Implement your custom sorting logic here
      // This example uses localeCompare for alphanumeric sorting
      return sortOrder === "asc"
        ? aValue.localeCompare(bValue, "en", {
          numeric: true,
          sensitivity: "base",
        })
        : bValue.localeCompare(aValue, "en", {
          numeric: true,
          sensitivity: "base",
        });
    });
  };
  sortDataArticles = (grievancesData, sortOrder) => {
    const data = grievancesData;

    return data.sort((a, b) => {
      // Assuming each item in completeGrievancesList has an 'article' property
      const articleIndexA =
        a.articles && !_.isEmpty(a.articles) ? a.articles[0].articleIndex : 0;
      const articleIndexB =
        b.articles && !_.isEmpty(b.articles) ? b.articles[0].articleIndex : 0;
      if (sortOrder === "asc") {
        return articleIndexA - articleIndexB;
      } else {
        return articleIndexB - articleIndexA;
      }
    });
  };
  handleTableChange = async (
    type,
    { sortField, sortOrder, data, page, sizePerPage }
  ) => {
    ///debugger;
    if (type === "sort") {
      let sortedData = [];
      if (sortField == "gId") {
        sortedData = this.sortData(
          this.state.grievancesData,
          sortField,
          sortOrder
        );
        this.state.completeGrievancesList = this.sortData(
          this.state.completeGrievancesList,
          sortField,
          sortOrder
        );
      } else if (sortField == "articleName") {
        sortedData = this.sortDataArticles(
          this.state.grievancesData,
          sortOrder
        );
        this.state.completeGrievancesList = this.sortDataArticles(
          this.state.completeGrievancesList,

          sortOrder
        );
      } else {
        sortedData = _.orderBy(
          this.state.grievancesData,
          [sortField],
          [sortOrder]
        );
        this.state.completeGrievancesList = _.orderBy(
          this.state.completeGrievancesList,
          [sortField],
          [sortOrder]
        );
      }
      await this.setState({
        completeGrievancesList: this.state.completeGrievancesList,
        grievancesData: this.state.grievancesData,
        filteredGrievances: this.arrangeDataWithStatus(
          sortedData,
          this.state.sizePerPage,
          this.state.page
        ),
        customSortApplied: true,
      });
    }
  };
  expandRow = () => {
    return {
      renderer: (row) => {
        return (
          <>
            {!_.isEmpty(row.grievances) &&
              row.grievances.map((item, index) => (
                <div
                  key={"expend-" + row.status + "-" + index}
                  className="d-flex px-0 w-100 border-top c-pointer border-bottom"
                  onClick={(e) => {
                    if (row.status == "Draft") {
                      this.loadDraftRequestData(item.grievanceId);
                    } else {
                      if (e.target.hasAttribute("data-grievanceid")) {
                        // do nothing every thing will be done on checkbox event
                      } else {
                        this.grievancePage(e, item.grievanceId);
                      }
                    }
                  }}
                >
                  {isMobile ? (
                    <>
                      <div
                        className="px-3 py-2 border-right"
                        style={{ width: "25%" }}
                      >
                        {item.grievant}
                      </div>
                      <div
                        className="px-3 py-2 border-left border-right"
                        style={{ width: "30%" }}
                      >
                        {moment(item.dateFiled).isValid()
                          ? moment(item.dateFiled).format("MM/DD/YYYY")
                          : ""}
                      </div>
                      <div
                        className="px-3 py-2 border-left border-right"
                        style={{ width: "22%" }}
                      >
                        {item.region}
                      </div>
                      <div
                        className="px-3 py-2 border-left border-right"
                        style={{ width: "23%" }}
                      >
                        {item.facility}
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="px-3 py-2 border-right"
                        style={{ width: "4%" }}
                      >
                        {((item.region && _.some(this.state.parFAAContacts, x =>
                          (x.regionCode || "").toLowerCase().trim() === (item.region || "").toLowerCase().trim()
                        )) && (!this.state.parPDFRegionId || (this.state.parPDFRegionId && this.state.parPDFRegionId == item.regionId))) ? (
                          <input
                            type="checkbox"
                            data-grievanceId={item.grievanceId}
                            onClick={(e) => {
                              if (e.target.checked) {
                                this.setState({ parPDFRegionId: item.regionId, parPDFRegion: item.region });
                              }


                              this.updateGrievancePARList(item.grievanceId);
                            }}
                            class="1"
                            checked={!_.isEmpty(this.state.selectedGrievanceForPAR) ? _.includes(this.state.selectedGrievanceForPAR, item.grievanceId) : false}
                          />
                        ) : (<></>)}
                      </div>
                      <div
                        className="px-3 py-2 border-right"
                        style={{ width: "6%" }}
                      >
                        {item.grievant}
                      </div>
                      <div
                        className="px-3 py-2 border-left border-right"
                        style={{ width: "10%" }}
                      >
                        {moment(item.dateFiled).isValid()
                          ? moment(item.dateFiled).format("MM/DD/YYYY")
                          : ""}
                      </div>
                      <div
                        className="px-3 py-2 border-left border-right"
                        style={{ width: "9%" }}
                      >
                        {item.region}
                      </div>
                      <div
                        className="px-3 py-2 border-left border-right"
                        style={{ width: "8%" }}
                      >
                        {item.facility}
                      </div>
                      <div
                        className="px-3 py-2 border-left border-right"
                        style={{ width: "8.5%" }}
                      >
                        {item.step}
                      </div>
                      <div
                        className="px-3 py-2 border-left border-right"
                        style={{ width: "10%" }}
                      >
                        {item.subject}
                      </div>
                      <div
                        className="px-3 py-2 border-left border-right"
                        style={{ width: "12%" }}
                      >
                        {item.representativeName}
                      </div>
                      <div
                        className="px-3 py-2 border-left border-right"
                        style={{ width: "10%" }}
                      >
                        {item.articles &&
                          !_.isEmpty(item.articles) &&
                          item.articles.map((x) => (
                            <Tooltip text={x.articleName}>
                              <span
                                className="badge badge-pill c-pointer badge-success"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  e.nativeEvent.stopImmediatePropagation();
                                }}
                              >
                                Article - {x.articleIndex}{" "}
                              </span>
                            </Tooltip>
                          ))}
                      </div>
                      {(() => {
                        if (!item.remainingDays) {
                          return (
                            <div
                              className="px-3 py-2 border-left border-right"
                              style={{ width: "7%" }}
                            >
                              No Record
                            </div>
                          );
                        }
                        if (item.remainingDays < 5) {
                          return (
                            <div
                              className="px-3 py-2 border-left border-right"
                              style={{
                                backgroundColor: "red",
                                color: "white",
                                width: "7%",
                              }}
                            >
                              {item.remainingDays} Days
                            </div>
                          );
                        } else if (item.remainingDays < 10) {
                          return (
                            <div
                              className="px-3 py-2 border-left border-right bg-warning text-light"
                              style={{ width: "7%" }}
                            >
                              {item.remainingDays} Days
                            </div>
                          );
                        } else {
                          return (
                            <div
                              className="px-3 py-2 border-left border-right"
                              style={{ width: "7%" }}
                            >
                              {item.remainingDays} Days
                            </div>
                          );
                        }
                      })()}

                      <div
                        className="px-3 py-2 border-left border-right"
                        style={{ width: "10%" }}
                      >
                        {item.gId}
                      </div>

                      <div
                        className="px-2 text-center py-2 border-left"
                        style={{ width: "5%" }}
                      >
                        {this.state.show ? (
                          <span
                            onClick={(e) => {
                              e.preventDefault();
                              e.nativeEvent.stopImmediatePropagation();
                              e.stopPropagation();
                            }}
                          >
                            <i
                              onClick={(e) => {
                                this.handleEditClick(e, item.grievanceId);
                              }}
                              className="pr-2 fas fa-edit  text-primary c-pointer"
                            />
                          </span>
                        ) : (
                          <i className="fas fa-spinner" />
                        )}
                        {this.state.show ? (
                          <>
                            <i
                              onClick={(e) => {
                                this.handleDuplicateClick(e, item.grievanceId);
                              }}
                              className="pr-2 fas fa-files-o text-primary c-pointer"
                            />
                          </>
                        ) : (
                          <i className="fas fa-spinner" />
                        )}

                        {this.state.show ? (
                          item.pdfData ? (
                            <PDFDownloadLink
                              onClick={(e) => {
                                e.preventDefault();
                                e.nativeEvent.stopImmediatePropagation();
                              }}
                              document={<PdfDocument data={item.pdfData} />}
                              fileName={item.gId + ".pdf"}
                            >
                              <i class="fas fa-file-pdf" />
                            </PDFDownloadLink>
                          ) : (
                            ""
                          )
                        ) : (
                          <i className="fas fa-spinner" />
                        )}
                      </div>
                    </>
                  )}
                </div>
              ))}
          </>
        );
      },
      onExpand: async (row, isExpand, rowIndex, e) => {
        await this.setState({ show: false });
        if (isExpand) {
          await this.setState(() => ({
            expanded: [...this.state.expanded, row.status],
          }));
        } else {
          await this.setState(() => ({
            expanded: this.state.expanded.filter((x) => x !== row.status),
          }));
        }
        setTimeout(() => {
          this.setState({ show: true });
        }, 2000);
      },
      expanded: this.state.expanded,
      onlyOneExpanding: true,
    };
  };
  grievancePage = (e, id) => {
    if (
      e.target.className.indexOf("pdf") == -1 &&
      e.target.className.indexOf("fa-files-o") < 0
    ) {
      this.props.history.push({
        pathname: "/grievances/" + id,
        type: "create",
        uid: id,
      });
    }
  };
  async updateGrievancePARList(id) {
    var source = this.state.selectedGrievanceForPAR;
    if (source.indexOf(id) > -1) {
      _.remove(source, x => x === id);
    } else {
      source.push(id);
    }
    if (_.isEmpty(source)) {

      this.setState({ parPDFRegionId: null, parPDFRegion: null });
    }
    var result = await Services.FetchGrievancesPDFData(source.join(","));
    if (!_.isEmpty(result.removeIds)) {
      result.removeIds.forEach((id, index) => {
        _.remove(source, x => x === id);
      });
    }
    this.setState({ selectedGrievancePARDATA: result.data, selectedGrievanceForPAR: source });
  }
  RemotePagination(
    columns,
    keyFields,
    data,
    SizePerPageChange,
    sizePerPage,
    rowClasses
  ) {
    const customTotal = (from, to, size) => (
      <span
        className="react-bootstrap-table-pagination-total"
        style={{ float: "left", marginTop: "-1px", marginRight: "4px" }}
      >
        {from}
        {" - "}
        {to} of {size} {"   "}
        <b style={{ marginLeft: "4px" }}> Show: </b>
      </span>
    );
    const defaultPaginationConfigs = paginationFactory({
      paginationTotalRenderer: customTotal,
      onSizePerPageChange: this.SizePerPageChange,
      onPageChange: this.OnPageChange,
      sizePerPage: this.state.sizePerPage,
      page: this.state.page,
      totalSize: this.state.grievanceCount,
      showTotal: true,
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
        {
          text: "25",
          value: 25,
        },
        {
          text: "30",
          value: 30,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "All",
          value: this.state.grievanceCount, //data.length,
        },
      ],
    });

    return (
      <div>
        <BootstrapTable
          remote
          //keyField={keyFields}
          keyField={"status"}
          data={data}
          columns={columns}
          onTableChange={this.handleTableChange}
          classes="expandable"
          pagination={defaultPaginationConfigs}
          rowClasses={rowClasses}
          expandRow={this.expandRow()}
        ///defaultSorted={[{ dataField: "gId", order: "desc" }]}
        />
      </div>
    );
  }

  async getPDFData(grievanceId) {
    var url = `api/Grievance/GetPDFDataByGrievanceId/${grievanceId}`;
    await authAxios.get(url).then(({ data: response }) => {
      this.setState({
        pdfData: response.data,
        showPdf: true,
      });
    });
  }
  handleDelete(row, event) {
    Services.DeleteGrievance(row.grievanceId).then((data) => {
      if (!_.isEmpty(data.status)) {
        if (data.status == "Success") {
          toast.success(data.message);
          this.loadAllRequests();
        } else {
          toast.error(data.message);
        }
      }
    });
  }

  getColumn() {
    return isMobile
      ? [
        {
          dataField: "status",
          text: "Grievant",
          headerAttrs: {
            width: "25%",
          },
          headerClasses: "bg-primary text-white px-sm-3 px-2 py-2",
          sort: true,
          onSort: async (field, order) => {
            this.state.grievancesData = _.orderBy(
              this.state.grievancesData,
              ["grievant"],
              [order]
            );
            await this.setState({
              grievancesData: this.state.grievancesData,
              filteredGrievances: this.arrangeDataWithStatus(
                this.state.grievancesData,
                this.state.sizePerPage,
                this.state.page
              ),
            });
          },
          classes:
            "c-pointer px-3 py-2 border-right-0 bg-secondary text-white",
          formatter: null,
        },
        {
          text: "Date Filed",
          dataField: "dateFiled",
          headerAttrs: {
            width: "30%",
          },
          headerClasses: "bg-primary text-white px-sm-3 px-2 py-2",
          sort: true,
          classes:
            "c-pointer px-3 py-2 border-right-0 border-left-0 bg-secondary text-white",
          formatter: (cellContent, row) => {
            return <></>;
          },
        },
        {
          dataField: "region",
          text: "Region",
          headerAttrs: {
            width: "22%",
          },
          headerClasses: "bg-primary text-white px-sm-3 px-2 py-2",
          sort: true,

          classes:
            "c-pointer px-3 py-2 border-right-0 border-left-0  bg-secondary text-white",
          formatter: (cellContent, row) => {
            return <></>;
          },
        },

        {
          dataField: "facility",
          text: "Facility",
          headerAttrs: {
            width: "23%",
          },
          headerClasses: "bg-primary text-white px-sm-3 px-2 py-2",
          sort: true,
          classes:
            "c-pointer px-3 py-2 border-left-0 bg-secondary text-white",
          formatter: (cellContent, row) => {
            return <></>;
          },
        },
      ]
      : [
        {
          dataField: "status",
          text: "Grievant",
          headerAttrs: {
            width: "9%",
          },
          headerClasses: "bg-primary text-white px-sm-3 px-2 py-2",
          sort: true,

          onSort: async (field, order) => {
            this.state.grievancesData = _.orderBy(
              this.state.grievancesData,
              ["grievant"],
              [order]
            );
            await this.setState({
              grievancesData: this.state.grievancesData,
              filteredGrievances: this.arrangeDataWithStatus(
                this.state.grievancesData,
                this.state.sizePerPage,
                this.state.page
              ),
            });
          },
          classes:
            "c-pointer px-3 py-2 border-right-0 bg-secondary text-white",
          formatter: null,
        },
        {
          dataField: "dateFiled",
          text: "Date Filed",

          headerAttrs: {
            width: "8%",
          },
          headerClasses: "bg-primary text-white px-sm-3 px-2 py-2",
          sort: true,
          classes:
            "px-3 c-pointer py-2 border-left-0 border-right-0 bg-secondary text-white",
          formatter: (cellContent, row) => {
            return <></>;
          },
        },
        {
          dataField: "region",
          text: "Region",
          headerAttrs: {
            width: "7%",
          },

          headerClasses: "bg-primary text-white px-sm-3 px-2 py-2",
          sort: true,

          classes:
            "px-3 c-pointer py-2 border-left-0 border-right-0 bg-secondary text-white",
          formatter: (cellContent, row) => {
            return <></>;
          },
        },
        {
          dataField: "facility",
          text: "Facility",
          headerAttrs: {
            width: "7%",
          },
          headerClasses: "bg-primary text-white px-sm-3 px-2 py-2",
          sort: true,
          classes:
            "px-3 c-pointer py-2 border-left-0 border-right-0 bg-secondary text-white",
          formatter: (cellContent, row) => {
            return <></>;
          },
        },
        {
          dataField: "step",

          text: "Step",

          headerClasses: "bg-primary text-white px-sm-3 px-2 py-2",
          headerAttrs: {
            width: "7%",
          },
          sort: true,
          classes:
            "px-3 py-2 c-pointer border-left-0 border-right-0 bg-secondary text-white",
          formatter: (cellContent, row) => {
            return <></>;
          },
        },
        {
          dataField: "subject",

          text: "Subject",

          headerClasses: "bg-primary text-white px-sm-3 px-2 py-2",
          headerAttrs: {
            width: "8%",
          },
          sort: true,
          classes:
            "px-3 py-2 c-pointer border-left-0 border-right-0 bg-secondary text-white",
          formatter: (cellContent, row) => {
            return <></>;
          },
        },
        {
          text: "Representative",
          headerAttrs: {
            width: "10%",
          },
          dataField: "representativeName",
          headerClasses: "bg-primary text-white px-sm-3 px-2 py-2",
          sort: true,
          classes:
            "px-3 py-2 c-pointer border-left-0 border-right-0 bg-secondary text-white",
          formatter: (cellContent, row) => {
            return <></>;
          },
        },
        {
          dataField: "articleName",
          text: "Contract Article",
          headerAttrs: {
            width: "8%",
          },
          headerClasses: "bg-primary text-white px-sm-3 px-2 py-2",
          sort: true,
          classes:
            "px-3 py-2 c-pointer border-left-0 border-right-0 bg-secondary text-white",
          formatter: (cellContent, row) => {
            return <></>;
          },
        },
        {
          dataField: "remainingDays",
          text: "Remaining Days",
          headerAttrs: {
            width: "6%",
          },
          headerClasses: "bg-primary text-white px-sm-3 px-2 py-2",
          sort: true,
          classes:
            "px-3 py-2 c-pointer border-left-0 border-right-0 bg-secondary text-white",
          formatter: (cellContent, row) => {
            return <></>;
          },
        },
        {
          dataField: "gId",
          text: "ID",
          headerClasses: "bg-primary text-white px-sm-3 px-2 py-2",
          sort: true,
          headerAttrs: {
            width: "8%",
          },
          classes:
            "px-3 py-2 c-pointer border-left-0 border-right-0 bg-secondary text-white",
          formatter: (cellContent, row) => {
            return <></>;
          },
        },
        {
          text: "",
          headerClasses: "bg-primary text-white px-sm-3 px-2 py-2",
          headerAttrs: {
            width: "5%",
          },
          sort: false,
          classes:
            "px-3 py-2 c-pointer border-left-0 border-right-0 bg-secondary text-white",
          formatter: (cellContent, row) => {
            return <></>;
          },
        },
      ];
  }

  SizePerPageChange = (sizePerPage) => {
    this.setState({
      sizePerPage: sizePerPage,
      page: 1,
      filteredGrievances: this.arrangeDataWithStatus(
        _.cloneDeep(this.state.grievancesData),
        sizePerPage,
        1
      ),
    });
  };
  OnPageChange = (page) => {
    $(".loading").show();
    this.state.filteredGrievances = this.arrangeDataWithStatus(
      _.cloneDeep(this.state.grievancesData),
      this.state.sizePerPage,
      page
    );
    this.setState({
      page: page,
      filteredGrievances: this.state.filteredGrievances,
    });
    $(".loading").hide();
  };
  viewAllLink = (data) => {
    this.setState({ sizePerPage: data.length });
  };

  filterMyRequest = async (searchTerm, searchType, obj) => {
    let sTerm = _.toLower(searchTerm);
    var searchStatus,
      searchkeyword,
      searchRegion,
      searchFacility,
      searchArticle = "";
    let searchContract = this.state.searchTermContract;
    var currentGrievanceData = this.state.completeGrievancesList;
    switch (searchType) {
      case "keyword":
        this.state.searchkeyword = sTerm;
        searchkeyword = sTerm;
        break;
      case "status":
        this.state.searchTermStatus = sTerm;
        searchStatus = sTerm;
        break;
      case "region":
        this.state.searchTermRegion = sTerm;
        searchRegion = sTerm;
        break;

      case "contract":
        {
          this.state.searchTermContract = sTerm;
          searchContract = sTerm;
          this.state.searchTermArticle = "";
          searchArticle = "";
          await this.setState({ searchTermArticle: this.state.searchTermArticle, searchTermArticle: this.state.searchTermArticle });
          await this.filterArticlesBasedOnContracts(searchContract);
          break;
        }


      case "article":
        this.state.searchTermArticle = sTerm;
        searchArticle = sTerm;
        break;
      case "facility":
        this.state.searchTermFacility = sTerm;
        searchFacility = sTerm;
        break;
      default:
        break;
    }
    if (searchkeyword) {
      currentGrievanceData = _.filter(currentGrievanceData, function (item) {
        return _.toLower(item.subject).indexOf(sTerm) > -1;
      });
    }
    if (searchStatus) {
      currentGrievanceData = _.filter(currentGrievanceData, function (item) {
        return _.toLower(item.status).indexOf(sTerm) > -1;
      });
    }
    if (searchRegion) {
      currentGrievanceData = _.filter(currentGrievanceData, function (item) {
        return _.toLower(item.region).indexOf(sTerm) > -1;
      });
    }
    if (searchFacility) {
      currentGrievanceData = _.filter(currentGrievanceData, function (item) {
        return _.toLower(item.facility).indexOf(sTerm) > -1;
      });
    }
    if (searchArticle) {
      currentGrievanceData = _.filter(currentGrievanceData, function (item) {
        return (item.articles &&
          !_.isEmpty(item.articles) &&
          item.articles.some((article) => {
            return article.id === parseInt(sTerm);
          })
        );
      });
    }
    if (searchContract) {
      currentGrievanceData = _.filter(currentGrievanceData, function (item) {
        return (item.articles &&
          !_.isEmpty(item.articles) &&
          item.articles.some((article) => {
            return article.contractId === parseInt(searchContract);
          })
        );
      });

    }



    if (searchFacility) this.state.facility = obj;
    if (searchArticle) this.state.contractArticle = obj;
    //if (searchRegion) {
    //  var selectedRegion = _.filter(this.state.regions, function(item) {
    //    return _.toLower(item.region).indexOf(sTerm) > -1;
    //  });
    //  this.loadMembersByRegion(
    //    selectedRegion[0].regionId,
    //    sTerm,
    //    searchType,
    //    obj
    //  );
    //}
    this.setState({
      filteredGrievances: this.arrangeDataWithStatus(
        _.cloneDeep(currentGrievanceData),
        this.state.sizePerPage,
        1
      ),
      grievancesData: currentGrievanceData,
      page: 1,
      grievanceCount: currentGrievanceData.length,
    });
    this.expandAll();
  };

  handleClearSearch() {
    $("#status").val("0");
    $("#region").val("0");
    this.setState({
      searchTerm: "",
      searchType: "",
      searchTermStatus: "",
      searchTermRegion: "",
      searchTermFacility: "",
      searchTermArticle: "",
      searchTermContract: "",
      contractArticles: [],
      searchkeyword: "",
      isClean: true,
      facility: {},
      region: {},
      showClearButton: false,
      facilities: this.state.facilities,
      filteredGrievances: this.arrangeDataWithStatus(
        this.state.completeGrievancesList,
        this.state.sizePerPage,
        1
      ),
      page: 1,
      grievancesData: this.state.completeGrievancesList,
      grievanceCount: this.state.completeGrievancesList.length,
    });
    this.expandFirst();
  }

  openFilterModal() {
    this.setState({
      isSearchModalOpen: true,
    });
  }

  render() {
    Font.register({
      family: "Open Sans",
      fonts: [
        {
          src:
            "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
        },
        {
          src:
            "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-300.ttf",
          fontWeight: "light",
        },
      ],
    });

    const styles = makeStyles({
      GrienvantSecondFooter: {
        marginTop: 10,
        textDecoration: "underline",
        fontSize: 13,
        fontWeight: "500",
      },
      grievanceHeaderLeft: {
        width: "100%",
        float: "left",
      },
      grievanceHeaderRight: {
        width: "50%",
        marginLeft: 330,
        marginTop: -30,
        float: "right",
      },
      headerImageView: {
        marginLeft: 100,
      },
      GrienvantFooter: {
        marginTop: 30,
      },
      image: {
        width: 80,
        height: 80,
        float: "left",
      },
      floatLeft: {
        float: "left",
      },
      sec: {
        //marginTop: 80
      },
      Dear: {
        marginTop: 30,
        marginBottom: 30,
      },
      afterSalution: {
        marginTop: 10,
      },
      afterDateText: {
        marginTop: 30,
      },
      dateText: {
        marginTop: 50,
      },
      logoImage: {
        width: 80,
        height: 80,
      },
      body: {
        paddingTop: 35,
        paddingBottom: 65,
        fontSize: 12,
        paddingHorizontal: 35,
      },
      title: {
        fontSize: 24,
        textAlign: "left",
        marginTop: 20,
        fontFamily: "Oswald",
      },
      author: {
        fontSize: 12,
        textAlign: "center",
        marginBottom: 40,
      },
      subtitle: {
        fontSize: 18,
        margin: 12,
        fontFamily: "Oswald",
      },
      text: {
        margin: 12,
        fontSize: 14,
        textAlign: "justify",
        fontFamily: "Times-Roman",
      },

      header: {
        fontSize: 12,
        marginBottom: 20,
        textAlign: "center",
        color: "grey",
      },
      pageNumber: {
        position: "absolute",
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey",
      },
    });
    let classes = makeStyles({
      option: {
        fontSize: 10,
        "& > span": {
          marginRight: 10,
          fontSize: 10,
        },
        backgroundColor: "white",
      },
      customTextField: {
        "& input::placeholder": {
          fontSize: "15px",
        },
      },
    });
    return (
      <AUX>
        <GrievanceHeader
          changeActiveTab={this.changeActiveTab}
          ActiveTab={this.state.ActiveTab}
        />
        <div className="pac-container container-fluid">
          <div className="row">
            <div className="col-md-12" style={{ paddingTop: "25px" }}>
              <div className="col-md-3 float-left">
                <form role="search" className="app-search mt-0">
                  <MDBInput
                    name="keyword"
                    size="lg"
                    hint="Search Subject"
                    variant="outlined"
                    style={{ width: "100%" }}
                    value={this.state.searchkeyword}
                    onChange={(e) => {
                      if (e.target.value) {
                        e.preventDefault();
                        this.filterMyRequest(
                          e.target.value,
                          "keyword",
                          e.target.value
                        );
                      } else {
                        this.handleClearSearch();
                      }
                    }}
                    placeholder="Search By Subject"
                    type="text"
                  />
                </form>
              </div>
              <div className="col-md-6 float-left">
                <button
                  type="button"
                  className="c-pointer mb-1 px-3 btn btn-sm btn-outline-success pl-2"
                  style={{
                    marginTop: "25px",
                    marginLeft: "35px",
                    height: "45px",
                  }}
                  onClick={() => {
                    this.openFilterModal(true);
                  }}
                >
                  <i class="cursorPointer pr-1 fab fa-searchengin" />
                  More Filters
                </button>
                <div className="float-left" />
              </div>
              <div className="col-md-3 float-left">
                <PDFDownloadLink
                  document={
                    <PARMultiPdfDocument
                      data={this.state.selectedGrievancePARDATA}
                    />
                  }
                  fileName={"PAR-" + this.state.parPDFRegion + "-" + new Date().toISOString().split('T')[0] + ".pdf"}
                  className="d-inline-block"
                >
                  {({ blob, url, loading, error }) => {
                    return (
                      <div>
                        <button
                          style={{
                            display:
                              this.state.selectedGrievanceForPAR.length > 0
                                ? "block"
                                : "none",
                            marginTop: "40px",
                          }}
                          className="btn btn-success btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light mb-2"
                        >
                          Download PAR
                        </button>
                      </div>
                    );
                  }}
                </PDFDownloadLink>
              </div>
            </div>
          </div>
          <div className="page-title-box" />
          <div className="row" id="tableRow">
            <div className="col-12" style={{ overflow: "scroll" }}>
              {!_.isEmpty(this.state.filteredGrievances) ? (
                this.RemotePagination(
                  this.getColumn(),
                  "status",
                  this.state.filteredGrievances, //this.filterMyRequest(),
                  this.SizePerPageChange,
                  this.state.sizePerPage,
                  this.rowClasses
                )
              ) : (
                <p className="mt-4 text-center">No Grievances Are Available</p>
              )}
            </div>
          </div>
          <GrievanceDetailsOnlyModal
            modal={this.state.isConfirmModalOpen}
            toggle={this.toggleConfirmModal}
            data={this.state.grievanceViewOnlyData}
            grievanceId={this.state.selectedGrievanceForDetail}
            grievanceStatus={this.state.selectedGrievanceStatus}
            reActive={this.reActiveGrievance}
          />
          <GrievanceSearchModal
            modal={this.state.isSearchModalOpen}
            toggle={this.toggleSearchModel}
            statuses={this.state.statuses}
            facilities={this.state.facilities}
            searchTermArticle={this.state.searchTermArticle}
            searchTermContract={this.state.searchTermContract}
            articlesList={this.state.contractArticles}
            articleValue={this.state.contractArticle}
            regions={this.state.regions}
            contractList={this.state.contractList}
            filterMyRequest={this.filterMyRequest}
            facilityValue={this.state.facility}
            regionValue={this.state.region}
            grievanceCount={this.state.grievanceCount}
            handleClearSearch={this.handleClearSearch}
          />
        </div>
        <GrievanceGridDuplicateForm
          GrievanceId={this.state.currentDuplicateGrievanceId}
          ShowModal={this.state.showDuplicateGrievanceModal}
          OnClose={this.closeDuplicateGrievanceModal}
        />
        <GrievanceEditForm
          GrievanceId={this.state.currentEditGrievanceId}
          ShowModal={this.state.showEditGrievanceModal}
          OnClose={this.closeEditGrievanceModal}
        />
      </AUX>
    );
  }
}
export default withRouter(
  connect((state) => ({ grievanceState: state.grievances }), {
    ...GrievanceAction,
  })(Grievances)
);
