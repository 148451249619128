import React, { Component, Fragment } from "react";
import $ from "jquery";
import { MDBInput } from "mdbreact";
import InputMask from "react-input-mask";
import AUX from "../../../../hoc/Aux_";
import _ from "lodash";
import AuthService from "../../../Auth/AuthService";
import PermissionFeature from "../../../Permissions/PermissionFeature";
import Services from "./EditUserService";
import { toast } from "react-toastify";

class Membership extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bargainingUnitStatus: [],
      memberTypes: [],
      membershipObj: {
        memberType: "",
        joinUnionDate: "",
        bargainingUnitStatus: "",
      },
    };
    this.resetMembership = this.resetMembership.bind(this);
    this.getBargainingUnitStatus = this.getBargainingUnitStatus.bind(this);
    this.getMemberTypes = this.getMemberTypes.bind(this);
    this.changeHandlerMemberType = this.changeHandlerMemberType.bind(this);
    this.changeHandlerBargainingUnitStatus = this.changeHandlerBargainingUnitStatus.bind(
      this
    );
    this.changeHandlerJU_date = this.changeHandlerJU_date.bind(this);
    this.handleFormSubmitMembership = this.handleFormSubmitMembership.bind(
      this
    );
  }
  componentDidMount() {
    this.resetMembership();
    this.getBargainingUnitStatus();
    this.getMemberTypes();
  }
  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.Membership) !=
      JSON.stringify(this.props.Membership)
    ) {
      this.resetMembership();
    }
  }
  changeHandlerJU_date = (selectedDate) => {
    this.state.membershipObj.joinUnionDate = selectedDate;

    this.state.membershipObj.joinUnionDate_err = this.dateValidation(
      selectedDate
    );
    this.setState({ membershipObj: this.state.membershipObj });
  };

  dateValidation = (date) => {
    if (!_.isEmpty(date)) {
      let dateReg = new RegExp(
        "^(0[1-9]|1[012])[-/.](0[1-9]|[12][0-9]|3[01])[-/.](19|20)\\d\\d$"
      );

      if (dateReg.test(date)) {
        return "";
      } else {
        return "Invalid date formate";
      }
    } else {
      return "";
    }
  };
  changeHandlerBargainingUnitStatus(e) {
    this.state.membershipObj.bargainingUnitStatus = e.target.value;
    this.setState({ membershipObj: this.state.membershipObj });
  }
  changeHandlerMemberType(e) {
    this.state.membershipObj.memberType = e.target.value;
    this.state.membershipObj.memberType_err = this.validateMemberType(
      e.target.value
    );
    this.setState({ membershipObj: this.state.membershipObj });
  }
  validateMemberType(value) {
    if (
      !(
        (value &&
          this.state.memberTypes.findIndex((x) => x.id == parseInt(value, 10)) >
            -1) ||
        !value
      )
    ) {
      return "Please provide valid member type";
    } else {
      return "";
    }
  }
  async handleFormSubmitMembership(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    $(".loading").show();
    let MembershipObj = this.state.membershipObj;
    MembershipObj.joinUnionDate_err = this.dateValidation(
      MembershipObj.joinUnionDate
    );
    MembershipObj.memberType_err = this.validateMemberType(
      MembershipObj.memberType
    );
    if (
      MembershipObj.joinUnionDate_err == "" &&
      MembershipObj.memberType_err == ""
    ) {
      let data = await Services.UpdateMembership({
        id: this.props.memberId,
        membertypeid: MembershipObj.memberType,
        unionjoineddate: MembershipObj.joinUnionDate,
        status: MembershipObj.bargainingUnitStatus,
      });
      if (data) {
        $("#editMembershipInline").addClass("d-none");
        $("#membershipInline").removeClass("d-none");
        this.props.reloadData();
      }
    }
    this.setState({ membershipObj: MembershipObj });
    $(".loading").hide();
  }
  async getBargainingUnitStatus() {
    let data = await Services.BargainingUnitStatus();
    if (data && data.statusCode == 200) {
      this.state.bargainingUnitStatus = data.data;
      this.setState({ bargainingUnitStatus: this.state.bargainingUnitStatus });
    }
  }
  async getMemberTypes() {
    let data = await Services.MemberTypes();
    if (data && data.statusCode == 200) {
      this.state.memberTypes = data.data;
      this.setState({ memberTypes: this.state.memberTypes });
    }
  }
  resetMembership() {
    this.setState({
      membershipObj: _.clone(this.props.Membership),
    });
  }
  render() {
    const {
      memberType,
      joinUnionDate,
      bargainingUnitStatus,
    } = this.state.membershipObj;
    let memberTypeObj = null;
    if (
      !_.isEmpty(this.state.memberTypes) &&
      _.isNumber(memberType) &&
      memberType > -1
    ) {
      memberTypeObj = this.state.memberTypes.find((x) => x.id == memberType);
    }
    let memberTypeVal = _.isObject(memberTypeObj)
      ? memberTypeObj.description
      : "none";
    let MObj = this.state.membershipObj;
    let MemberShipSectionPerm =
      AuthService.canOwnView(
        PermissionFeature.MembershipMemberType,
        this.props.memberid
      ) ||
      AuthService.canMemberView(PermissionFeature.MembershipMemberType) ||
      AuthService.canOwnView(
        PermissionFeature.DatesJoinedUnionDate,
        this.props.memberid
      ) ||
      AuthService.canMemberView(PermissionFeature.DatesJoinedUnionDate) ||
      AuthService.canOwnView(
        PermissionFeature.PersonalInfoBargainingUnitStatus,
        this.props.memberid
      ) ||
      AuthService.canMemberView(
        PermissionFeature.PersonalInfoBargainingUnitStatus
      );
    let MemberShipSectionUpdatePerm =
      (AuthService.canOwnUpdate(
        PermissionFeature.Member,
        this.props.memberid
      ) ||
        AuthService.canMemberUpdate(PermissionFeature.Member)) &&
      (AuthService.canOwnUpdate(
        PermissionFeature.MembershipMemberType,
        this.props.memberid
      ) ||
        AuthService.canMemberUpdate(PermissionFeature.MembershipMemberType) ||
        AuthService.canOwnUpdate(
          PermissionFeature.DatesJoinedUnionDate,
          this.props.memberid
        ) ||
        AuthService.canMemberUpdate(PermissionFeature.DatesJoinedUnionDate) ||
        AuthService.canOwnUpdate(
          PermissionFeature.PersonalInfoBargainingUnitStatus,
          this.props.memberid
        ) ||
        AuthService.canMemberUpdate(
          PermissionFeature.PersonalInfoBargainingUnitStatus
        ));

    return (
      <AUX>
        {MemberShipSectionPerm && (
          <div className="row col-12 px-0 mx-0 order-4 pl-md-2 ps-row-mac ps-column-mac">
            <div className="card directory-card border-rounded shadow w-100">
              <h5 className="card-header mt-0">
                <i className="fas fa-fist-raised mr-2" />
                Membership
              </h5>
              <div className="card-body pb-3 pt-1 px-1">
                <div className="Membership">
                  <Fragment>
                    <div className="row mx-0 pb-1" id="membershipInline">
                      <div className="col-lg-12 order-1 pdMemType">
                        <div className="parentDivMt py-1 memtype">
                          {(AuthService.canOwnView(
                            PermissionFeature.MembershipMemberType,
                            this.props.memberid
                          ) ||
                            AuthService.canMemberView(
                              PermissionFeature.MembershipMemberType
                            )) && (
                            <>
                              <span className="card-subtitle mb-2 text-muted">
                                <strong>Member Type: </strong>
                              </span>
                              <span className="card-text">{memberTypeVal}</span>
                            </>
                          )}
                          {MemberShipSectionUpdatePerm && (
                            <i
                              onClick={() => {
                                $("#editMembershipInline").removeClass(
                                  "d-none"
                                );
                                $("#membershipInline").addClass("d-none");
                              }}
                              // style={{ zIndex: 1 }}
                              className="fas fa-pencil-alt editMemPen position-relative mb-n3 c-pointer float-right"
                            ></i>
                          )}
                        </div>
                        {(AuthService.canOwnView(
                          PermissionFeature.DatesJoinedUnionDate,
                          this.props.memberid
                        ) ||
                          AuthService.canMemberView(
                            PermissionFeature.DatesJoinedUnionDate
                          )) && (
                          <div className="py-1">
                            <span className="card-subtitle mb-2 text-muted">
                              <strong>Join Union Date: </strong>
                            </span>
                            <span className="card-text">
                              {joinUnionDate == null || joinUnionDate == ""
                                ? "none"
                                : joinUnionDate}
                            </span>
                          </div>
                        )}
                        {(AuthService.canOwnView(
                          PermissionFeature.PersonalInfoBargainingUnitStatus,
                          this.props.memberid
                        ) ||
                          AuthService.canMemberView(
                            PermissionFeature.PersonalInfoBargainingUnitStatus
                          )) && (
                          <div className="py-1">
                            <span className="card-subtitle mb-2 text-muted">
                              <strong>Bargaining Unit Status: </strong>
                            </span>
                            <span className="card-text">
                              {bargainingUnitStatus == null ||
                              bargainingUnitStatus == ""
                                ? "none"
                                : bargainingUnitStatus}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    {MemberShipSectionUpdatePerm && (
                      <div
                        className="row d-none mx-0 pb-1"
                        id="editMembershipInline"
                      >
                        <div className="col-12 pt-2">
                          <i
                            onClick={() => {
                              $("#editMembershipInline").addClass("d-none");
                              $("#membershipInline").removeClass("d-none");
                              this.resetMembership();
                            }}
                            style={{ zIndex: 1 }}
                            className="fas fa-times position-relative mb-n3 c-pointer float-right"
                          ></i>
                        </div>
                        <div className="form-horizontal w-100">
                          <div className="row mx-0">
                            <div className="col-lg-12">
                              {(AuthService.canOwnView(
                                PermissionFeature.MembershipMemberType,
                                this.props.memberid
                              ) ||
                                AuthService.canMemberView(
                                  PermissionFeature.MembershipMemberType
                                )) && (
                                <div className="py-1">
                                  <span className="card-subtitle align-self-end pr-1 mb-2 text-secondary">
                                    Member Type:
                                  </span>
                                  {AuthService.canOwnUpdate(
                                    PermissionFeature.MembershipMemberType,
                                    this.props.memberid
                                  ) ||
                                  AuthService.canMemberUpdate(
                                    PermissionFeature.MembershipMemberType
                                  ) ? (
                                    <div className="">
                                      <div class="md-form form-group mt-0 mb-2">
                                        <select
                                          //className="browser-default custom-select"
                                          onChange={
                                            this.changeHandlerMemberType
                                          }
                                          name="memberType"
                                          className="form-control border-left-0 border-top-0 border-right-0 mt-2 py-0 mb-0"
                                        >
                                          <option
                                            value=""
                                            select={_.isEmpty(memberType)}
                                          >
                                            Select Member Type
                                          </option>
                                          {this.state.memberTypes.map(
                                            (item, index) => {
                                              return (
                                                <option
                                                  value={item.id}
                                                  selected={
                                                    memberType == item.id
                                                  }
                                                >
                                                  {item.description}
                                                </option>
                                              );
                                            }
                                          )}
                                        </select>

                                        <span className="text-danger">
                                          {MObj.memberType_err}
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    <span className="card-text">
                                      {memberTypeVal}
                                    </span>
                                  )}
                                </div>
                              )}

                              {(AuthService.canOwnView(
                                PermissionFeature.DatesJoinedUnionDate,
                                this.props.memberid
                              ) ||
                                AuthService.canMemberView(
                                  PermissionFeature.DatesJoinedUnionDate
                                )) && (
                                <div className="py-1">
                                  <span className="card-subtitle pr-1 mb-2 text-secondary">
                                    Join Union Date:
                                  </span>
                                  {AuthService.canOwnUpdate(
                                    PermissionFeature.DatesJoinedUnionDate,
                                    this.props.memberid
                                  ) ||
                                  AuthService.canMemberUpdate(
                                    PermissionFeature.DatesJoinedUnionDate
                                  ) ? (
                                    <div className="md-form form-group mt-0 mb-2">
                                      <InputMask
                                        value={
                                          joinUnionDate != null
                                            ? joinUnionDate
                                            : ""
                                        }
                                        onChange={(e) =>
                                          this.changeHandlerJU_date(
                                            e.target.value
                                          )
                                        }
                                        name="joinUnionDate"
                                        data-type="P"
                                        mask="99/99/9999"
                                        className="form-control"
                                        type="text"
                                        placeholder="mm/dd/yyyy"
                                      />
                                      <span className="text-danger">
                                        {MObj.joinUnionDate_err}
                                      </span>
                                    </div>
                                  ) : (
                                    <span className="card-text">
                                      {joinUnionDate == null ||
                                      joinUnionDate == ""
                                        ? "none"
                                        : joinUnionDate}
                                    </span>
                                  )}
                                </div>
                              )}
                              {(AuthService.canOwnView(
                                PermissionFeature.PersonalInfoBargainingUnitStatus,
                                this.props.memberid
                              ) ||
                                AuthService.canMemberView(
                                  PermissionFeature.PersonalInfoBargainingUnitStatus
                                )) && (
                                <div className="py-1">
                                  <span className="card-subtitle align-self-end pr-1 mb-2 text-secondary">
                                    Bargaining Unit Status:
                                  </span>
                                  {AuthService.canOwnUpdate(
                                    PermissionFeature.PersonalInfoBargainingUnitStatus,
                                    this.props.memberid
                                  ) ||
                                  AuthService.canMemberUpdate(
                                    PermissionFeature.PersonalInfoBargainingUnitStatus
                                  ) ? (
                                    <div className="">
                                      <div class="md-form form-group mt-0 mb-2">
                                        <select
                                          onChange={
                                            this
                                              .changeHandlerBargainingUnitStatus
                                          }
                                          name="bargainingUnitStatus"
                                          className="form-control border-left-0 border-top-0 border-right-0 mt-2 py-0 mb-0"
                                        >
                                          <option
                                            value=""
                                            selected={_.isEmpty(
                                              bargainingUnitStatus
                                            )}
                                          >
                                            Select Bargaining Unit Status
                                          </option>

                                          {this.state.bargainingUnitStatus.map(
                                            (item, index) => {
                                              return (
                                                <option
                                                  value={
                                                    item.bargainingUnitStatus
                                                  }
                                                  selected={
                                                    bargainingUnitStatus ==
                                                    item.bargainingUnitStatus
                                                  }
                                                >
                                                  {item.bargainingUnitStatus}
                                                </option>
                                              );
                                            }
                                          )}
                                        </select>
                                      </div>
                                    </div>
                                  ) : (
                                    <span className="card-text">
                                      {bargainingUnitStatus == null ||
                                      bargainingUnitStatus == ""
                                        ? "none"
                                        : bargainingUnitStatus}
                                    </span>
                                  )}
                                </div>
                              )}
                            </div>

                            <div className="col-12 py-3 text-center">
                              <button
                                onClick={this.handleFormSubmitMembership}
                                className="btn btn-success btn-sm mr-2 w-md waves-effect waves-light mt-1"
                              >
                                Save
                              </button>
                              <button
                                className="btn btn-sm btn-danger w-md waves-effect waves-light mt-1"
                                type="button"
                                id="memberDetailCancelBtn"
                                onClick={() => {
                                  $("#editMembershipInline").addClass("d-none");
                                  $("#membershipInline").removeClass("d-none");
                                  this.resetMembership();
                                }}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Fragment>
                </div>
              </div>
            </div>
          </div>
        )}
      </AUX>
    );
  }
}

export default Membership;
