import React, { Component, Fragment } from "react";
import AUX from "../../../hoc/Aux_";
import "../../../assets/css/TeamStyle.css";
import _ from "lodash";
import Services from "../PermissionService";
import $ from "jquery";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import DefaultPermissionContent from "./DefaultPermissionContent";
import EditDefaultPermission from "./EditDefaultPermission";
import { connect } from "react-redux";
import PermissionAction from "../../../store/permission/action";
class DefaultPermissionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loadData: false,
      ModalHeading: "DPModal",
    };
    this.cancelModal = this.cancelModal.bind(this);
    //this.openModal = this.openModal.bind(this);
  }
  componentWillUnmount() {}
  componentDidMount() {
    //this.handleConfigurePermissionDetail();
  }

  componentWillReceiveProps(nextProps) {}

  cancelModal() {
    this.props.closeDefaultPermissionModal();
  }

  render() {
    return (
      <AUX>
        <Modal
          id={this.state.ModalHeading}
          className="styled-modal"
          isOpen={this.props.permissionState.openDefaultPermissionModal}
          size="lg"
          backdrop="static"
          keyboard={false}
        >
          <ModalHeader
            toggle={this.cancelModal}
            className="modal-header bg-secondary py-2"
          >
            DEFAULT PERMISSION
          </ModalHeader>
          <ModalBody className="modal-body py-2" id="defaulPermissionBody">
            <ul className="nav nav-tabs mb-3" id="defaulPermissionTab">
              <li className="nav-item w-50">
                <a
                  className="nav-link  text-center py-2 active"
                  data-toggle="tab"
                  href="#s1"
                >
                  <i className="mr-1 fas fa-lock"></i> Edit Default Permission
                </a>
              </li>
              <li className="nav-item w-50">
                <a
                  className="nav-link  text-center py-2"
                  data-toggle="tab"
                  href="#s2"
                >
                  <i className="mr-1 fas fa-user"></i> User's Own Record
                </a>
              </li>
            </ul>

            <div className="tab-content">
              <div
                className="tab-pane px-0 container fade in show active"
                id="s1"
              >
                <EditDefaultPermission />
              </div>
              <div className="tab-pane px-0 container fade" id="s2">
                <DefaultPermissionContent />
              </div>
            </div>
          </ModalBody>
        </Modal>
      </AUX>
    );
  }
}

export default connect((state) => ({ permissionState: state.permission }), {
  ...PermissionAction,
})(DefaultPermissionModal);
