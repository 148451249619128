import axios from "../../modules/Shared/auth-header";
import { toast } from "react-toastify";
import _ from "lodash";
export default {
  UploadFile: async (data) => {
    if (data) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  LoadPayfileIndicators: async () => {
    try {
      const { data } = await axios.get(
        "api/PayfileIndicator/PayfileIndicators"
      ); // Replace with your actual API endpoint

      if (data && data.statusCode == 200) {
        return data.result;
      } else {
        toast.error(data.message);
        return null;
      }
    } catch (error) {
      console.error("Error loading table data:", error);
      throw error;
    }
  },
  LastStatusUpdateDate: async () => {
    try {
      const { data } = await axios.get(
        "api/PayfileIndicator/LastStatusChangeDate"
      ); // Replace with your actual API endpoint

      if (data && data.statusCode == 200) {
        return data.result;
      } else {
        toast.error(data.message);
        return null;
      }
    } catch (error) {
      console.error("Error loading Last Change Date:", error);
      throw error;
    }
  },

  SaveStatusChange: async (rowData) => {
    try {
      const { data } = await axios.put(
        `api/PayfileIndicator/PayfileIndicatorStatus`,
        rowData
      ); // Replace with your actual API endpoint
      if (data && data.statusCode == 200) {
        toast.success(data.message);
        return true;
      } else {
        toast.error(data.message);
        return null;
      }
    } catch (error) {
      console.error("Error saving status change:", error);
      throw error;
    }
  },

  LoadStatusOptions: async () => {
    try {
      const { data } = await axios.get(
        "api/PayfileIndicator/PayfileIndicatorStatus"
      ); // Replace with your actual API endpoint
      if (data && data.statusCode == 200) {
        return data.result;
      } else {
        toast.error(data.message);
        return null;
      }
    } catch (error) {
      console.error("Error loading status options:", error);
      throw error;
    }
  },

  DeletePayfileIndicatorStatus: async (rowId) => {
    try {
      const { data } = await axios.delete(
        `api/PayfileIndicator/PayfileIndicator/${rowId}`
      ); // Replace with your actual API endpoint
      if (data && data.statusCode == 200) {
        toast.success(data.message);
        return true;
      } else {
        toast.error(data.message);
        return null;
      }
    } catch (error) {
      console.error("Error deleting row:", error);
      throw error;
    }
  },
  PayFileUpdate: async (payfile) => {
    let url = `/api/Payfile/PayFileTwoManual`;
    var { data } = await axios.post(url, payfile);
    if (data && data.statusCode == 200) {
      toast.success("Jobs Updated SuccessFully!");
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  FinanceUpdate: async (Obj) => {
    let url = `/api/FinanceFile/FinanceFileManual`;
    var { data } = await axios.post(url, Obj);
    if (data && data.statusCode == 200) {
      toast.success(data.message);
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  DeleteEmployeeJobs: async (ECI, fileId, status) => {
    if (ECI == null || fileId == null) {
      return null;
    }
    let url = `api/Payfile/DeletePayFileTwo?ECI=${ECI}&FileId=${fileId}&Status=${status}`;
    var { data } = await axios.delete(url);
    if (data && data.statusCode == 200) {
      toast.success(data.message);
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  DeleteFinanceFileEntry: async (Id) => {
    if (Id == null) {
      return null;
    }
    let url = `api/FinanceFile/FinanceFileEntry?ID=${Id}`;
    var { data } = await axios.delete(url);
    if (data && data.statusCode == 200) {
      toast.success("Finance deleted successfully!");
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  IgnoreEmployeeJobs: async (ECI, fileId) => {
    if (ECI == null || fileId == null) {
      return null;
    }
    let url = `api/Payfile/IgnorePayFileTwo?ECI=${ECI}&FileId=${fileId}`;
    var { data } = await axios.delete(url);
    if (data.statusCode == 200) {
      toast.success("Imported entries ignored successfully!");
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  IgnoreFinance: async (Id, fileId) => {
    if (Id == null) {
      return null;
    }
    let url = `api/financefile/IgnoreFinance?id=${Id}`;
    var { data } = await axios.delete(url);
    if (data.statusCode == 200) {
      toast.success(data.message);
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  DeleteJob: async (PayFileId) => {
    let url = `api/Permission/DeletePosition?teamPositionID=${PayFileId}`;
    var { data } = await axios.delete(url);
    if (data.statusCode == 200) {
      return data;
    } else {
      toast.error("Invalid Request");
      return null;
    }
  },
  LoadMembers: async (searchTerms, offset, fetch) => {
    let url =
      offset === null || fetch === null
        ? `api/Payfile/Members?searchTerms=` + searchTerms + `&offset=0&fetch=5`
        : `api/Payfile/Members?searchTerms=` +
          searchTerms +
          `&offset=` +
          offset +
          `&fetch=` +
          fetch;
    var { data } = await axios.get(url);
    if (data.statusCode) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  LoadFinanceMembers: async (searchTerms, offset, fetch) => {
    let url =
      offset === null || fetch === null
        ? `api/FinanceFile/Members?searchTerms=` +
          searchTerms +
          `&offset=0&fetch=5`
        : `api/FinanceFile/Members?searchTerms=` +
          searchTerms +
          `&offset=` +
          offset +
          `&fetch=` +
          fetch;
    var { data } = await axios.get(url);
    if (data.statusCode) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  LoadFinanceData: async (searchTerms, id, offset, fetch) => {
    let url =
      offset === null || fetch === null
        ? `api/FinanceFile/List?searchTerms=` +
          searchTerms +
          `&fileID=` +
          id +
          `&offset=0&fetch=20`
        : `api/FinanceFile/List?searchTerms=` +
          searchTerms +
          `&fileID=` +
          id +
          `&offset=` +
          offset +
          `&fetch=` +
          fetch;
    var { data } = await axios.get(url);
    if (data.statusCode) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  LoadPayFileTwoDetail: async (id) => {
    let url = `api/Payfile/PayfileDetail?fileID=` + id;
    var { data } = await axios.get(url);
    if (data.statusCode) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  LoadFinanceDetail: async (searchTerms, id) => {
    let url =
      `api/FinanceFile/Detail?searchTerms=` + searchTerms + `&fileID=` + id;
    var { data } = await axios.get(url);
    if (data.statusCode) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  LoadPayFileTwoData: async (searchTerms, id, offset, fetch) => {
    let url =
      offset === null || fetch === null
        ? `api/Payfile/PayfileTwoList?searchTerms=` +
          searchTerms +
          `&fileID=` +
          id +
          `&offset=0&fetch=20`
        : `api/Payfile/PayfileTwoList?searchTerms=` +
          searchTerms +
          `&fileID=` +
          id +
          `&offset=` +
          offset +
          `&fetch=` +
          fetch;
    var { data } = await axios.get(url);
    if (data.statusCode) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  LoadFinanceData: async (searchTerms, id, offset, fetch) => {
    let url =
      offset === null || fetch === null
        ? `api/FinanceFile/List?searchTerms=` +
          searchTerms +
          `&fileID=` +
          id +
          `&offset=0&fetch=20`
        : `api/FinanceFile/List?searchTerms=` +
          searchTerms +
          `&fileID=` +
          id +
          `&offset=` +
          offset +
          `&fetch=` +
          fetch;
    var { data } = await axios.get(url);
    if (data.statusCode) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  LoadPayFileTwoPGData: async (searchTerms, id, status, offset, fetch) => {
    if (id == null || status == null) {
      return null;
    }
    offset = offset == null ? 0 : offset;
    fetch = fetch == null ? 20 : fetch;
    let url =
      `api/Payfile/PayfileTwoPagination?searchTerms=` +
      searchTerms +
      `&status=` +
      status +
      `&fileID=` +
      id +
      `&offset=` +
      offset +
      `&fetch=` +
      fetch;
    var { data } = await axios.get(url);
    if (data && data.statusCode == 200) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  LoadFinancePGData: async (searchTerms, id, status, offset, fetch) => {
    if (id == null || status == null) {
      return null;
    }
    offset = offset == null ? 0 : offset;
    fetch = fetch == null ? 20 : fetch;
    let url =
      `api/FinanceFile/PaginationData?searchTerms=` +
      searchTerms +
      `&status=` +
      status +
      `&fileID=` +
      id +
      `&offset=` +
      offset +
      `&fetch=` +
      fetch;
    var { data } = await axios.get(url);
    if (data && data.statusCode == 200) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  PayFileTwoExport: async (searchTerms, id, status, mode) => {
    if (id == null || status == null || mode == null) {
      return null;
    }
    let url =
      `api/Payfile/` +
      (mode == "excel" ? "PayfileTwoExcel" : "PayfileTwoPDF") +
      `?searchTerms=` +
      searchTerms +
      `&status=` +
      status +
      `&fileID=` +
      id;
    var { data } = await axios.get(url);
    if (data && data.statusCode == 200) {
      if (!_.isEmpty(data.result)) {
        return data;
      } else {
        return null;
      }
    } else {
      toast.error(data.message);
      return null;
    }
  },
  LoadUpdateJob: async (fileId, memberId, payfileTwoId) => {
    if (fileId == null || memberId == null) {
      return null;
    }
    let url =
      `api/Payfile/PayfileTwoUpdatedJobs?payfiletwoID=` +
      payfileTwoId +
      `&memberID=` +
      memberId +
      `&fileID=` +
      fileId;

    var { data } = await axios.get(url);
    if (data && data.statusCode == 200) {
      if (!_.isEmpty(data.result)) {
        return data;
      } else {
        return null;
      }
    } else {
      toast.error(data.message);
      return null;
    }
  },
  LoadBargainingUnit: async () => {
    let url = `api/Payfile/NatcaBargainingUnits`;

    var { data } = await axios.get(url);
    if (data && data.statusCode == 200) {
      if (!_.isEmpty(data.result)) {
        return data;
      } else {
        return null;
      }
    } else {
      toast.error(data.message);
      return null;
    }
  },
  GenerateFinanceFilePDF: async (searchTerms, status, fileId) => {
    let uniqueID = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );

    let url = `/api/FinanceFile/PDF?searchTerms=${searchTerms}&status=${status}&fileID=${fileId}&uniqueID=${uniqueID}`;

    //var { data } = await axios.post(url, checkedIds, { params: { uniqueID } });
    var { data } = await axios.get(url);
    if (data.statusCode && data.statusCode == 500) {
      toast.error(data.message);
      return null;
    } else {
      if (data) {
        //const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = axios.defaults.baseURL + url;
        // link.target = "_blank";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  },
  GenerateFinanceFileExcel: async (searchTerms, status, fileId) => {
    let uniqueID = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );

    let url = `/api/FinanceFile/Excel?searchTerms=${searchTerms}&status=${status}&fileID=${fileId}&uniqueID=${uniqueID}`;

    //var { data } = await axios.post(url, checkedIds, { params: { uniqueID } });
    var { data } = await axios.get(url);
    if (data.statusCode && data.statusCode == 500) {
      toast.error(data.message);
      return null;
    } else {
      if (data) {
        //const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = axios.defaults.baseURL + url;
        // link.target = "_blank";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  },
  GenerateExcel: async (
    searchTerms,
    facilityId = "",
    employerId = "",
    payperiod = "",
    duesPostedType = "",
    errorfilter = "",
    year = "",
    memberId = "",
    quarter = "",
    mode = "",
    sort = ""
  ) => {
    let uniqueID = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );

    let url =
      `/api/Finance/Excel?searchTerms=` +
      searchTerms +
      `&facilityId=` +
      facilityId +
      `&employerId=` +
      employerId +
      `&payperiod=` +
      payperiod +
      `&duesPostedType=` +
      duesPostedType +
      `&errorfilter=` +
      errorfilter +
      `&year=` +
      year +
      `&memberId=` +
      memberId +
      `&quarter=` +
      quarter +
      `&mode=` +
      mode +
      `&sort=` +
      sort +
      "&uniqueID=" +
      uniqueID;
    //var { data } = await axios.post(url, checkedIds, { params: { uniqueID } });
    var { data } = await axios.get(url);
    if (data.statusCode && data.statusCode == 500) {
      toast.error(data.message);
      return null;
    } else {
      if (data) {
        console.log(data);
        //const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = axios.defaults.baseURL + url;
        // link.target = "_blank";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  },
  LoadUpdateField: async (fileId, memberId) => {
    if (fileId == null || memberId == null) {
      return null;
    }
    let url =
      `api/Payfile/PayfileTwoFields?memberID=` + memberId + `&fileID=` + fileId;

    var { data } = await axios.get(url);
    if (data && data.statusCode == 200) {
      if (!_.isEmpty(data.result)) {
        return data;
      } else {
        return null;
      }
    } else {
      toast.error(data.message);
      return null;
    }
  },
  LoadUpdateFinanceField: async (FinanceFileEntryId) => {
    if (FinanceFileEntryId == null) {
      return null;
    }
    let url =
      `api/FinanceFile/UpdatedFields?financefileentryID=` + FinanceFileEntryId;

    var { data } = await axios.get(url);
    if (data && data.statusCode == 200) {
      if (!_.isEmpty(data.result)) {
        return data;
      } else {
        return null;
      }
    } else {
      toast.error(data.message);
      return null;
    }
  },
  LoadUpdateFinance: async (FinanceFileEntryId) => {
    if (FinanceFileEntryId == null) {
      return null;
    }
    let url =
      `api/FinanceFile/UpdatedFinance?financefileentryID=` + FinanceFileEntryId;

    var { data } = await axios.get(url);
    if (data && data.statusCode == 200) {
      if (!_.isEmpty(data.result)) {
        return data;
      } else {
        return null;
      }
    } else {
      toast.error(data.message);
      return null;
    }
  },

  FinanceMemberInfo: async (memberId) => {
    if (memberId == null) {
      return null;
    }
    let url = `api/Payfile/PayfileTwoMemberInfo?memberId=` + memberId;

    var { data } = await axios.get(url);
    if (data && data.statusCode == 200) {
      if (!_.isEmpty(data.result)) {
        return data;
      } else {
        return null;
      }
    } else {
      toast.error(data.message);
      return null;
    }
  },
  PayfileTwoMemberInfo: async (memberId) => {
    if (memberId == null) {
      return null;
    }
    let url = `api/Payfile/PayfileTwoMemberInfo?memberId=` + memberId;

    var { data } = await axios.get(url);
    if (data && data.statusCode == 200) {
      if (!_.isEmpty(data.result)) {
        return data;
      } else {
        return null;
      }
    } else {
      toast.error(data.message);
      return null;
    }
  },
  PayfileTwoUpdates: async (ECI) => {
    if (ECI == null) {
      return null;
    }
    let url = `api/Payfile/PayfileTwoUpdates?eci=` + ECI;

    var { data } = await axios.get(url);
    if (data && data.statusCode == 200) {
      if (!_.isEmpty(data.result)) {
        return data;
      } else {
        return null;
      }
    } else {
      toast.error(data.message);
      return null;
    }
  },
  FinanceFileEntry: async (FFI) => {
    if (FFI == null) {
      return null;
    }
    let url = `api/FinanceFile/SingleObject?ID=` + FFI;

    var { data } = await axios.get(url);
    if (data && data.statusCode == 200) {
      if (!_.isEmpty(data.result)) {
        return data;
      } else {
        return null;
      }
    } else {
      toast.error(data.message);
      return null;
    }
  },
  MemberJobHistory: async (memberId) => {
    if (memberId == null) {
      return null;
    }
    let url = `api/Payfile/PayfileTwoMemberJobHistory?memberId=` + memberId;

    var { data } = await axios.get(url);
    if (data && data.statusCode == 200) {
      if (!_.isEmpty(data.result)) {
        return data;
      } else {
        return null;
      }
    } else {
      toast.error(data.message);
      return null;
    }
  },
};
