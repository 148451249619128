import React, { Component } from "react";
import _ from "lodash";
import InputRange from "react-input-range";
import { connect } from "react-redux";
import actionFilter from "../../../store/advancedfilter/action";
import { Row, Col } from "reactstrap";

export class Finance extends Component {
  onChangeRange(e) {
    this.props.filterState.filterData.finance.fromDuesAmount = e.min;
    this.props.filterState.filterData.finance.toDuesAmount = e.max;
    this.props.setFilterData({
      ...this.props.filterState.filterData,
    });
  }
  onChange(e) {
    this.props.filterState.filterData.finance.duesBalance.checked =
      e.target.checked;
    this.props.setFilterData({
      ...this.props.filterState.filterData,
    });
  }
  onChangeDuesBalance(e) {
    this.props.filterState.filterData.finance.DuesBalance.checked =
      e.target.checked;
    this.props.setFilterData({
      ...this.props.filterState.filterData,
    });
  }

  render() {
    var finance =
      this.props.filterState.filterData &&
      this.props.filterState.filterData.finance;

    let duesBalance =
      this.props.filterState.filterData &&
      this.props.filterState.filterData.finance &&
      this.props.filterState.filterData.finance.duesBalance
        ? this.props.filterState.filterData.finance.duesBalance
        : null;
    let valueDues =
      finance &&
      finance.toDuesAmount != null &&
      finance.fromDuesAmount != null &&
      finance.toDuesAmount >= 0 &&
      finance.toDuesAmount <= this.props.filterState.filterData.duesMax &&
      finance.fromDuesAmount >= 0
        ? { min: finance.fromDuesAmount, max: finance.toDuesAmount }
        : { min: 0, max: this.props.filterState.filterData.duesMax };
    return (
      <>
        {this.props.isMobile !== true && (
          <>
            <h4>Finance Dues</h4>
            <hr />
          </>
        )}
        <Row className="mx-0 p-4">
          <Col xs="12" className="mb-3">
            <div>
              <div className="form-group">
                <label
                  className={"h6 " + (this.props.isMobile && "mb-5 text-white")}
                >
                  Dues Amount:
                </label>
                {this.props.isMobile !== true ? (
                  <div class="mb-3">
                    <label>Min </label>
                    <label style={{ float: "right" }}>Max</label>
                  </div>
                ) : (
                  <label> </label>
                )}
                <div class="range-slider">
                  <InputRange
                    formatLabel={(value) => `$${value}`}
                    maxValue={this.props.filterState.filterData.duesMax}
                    minValue={0}
                    value={valueDues}
                    onChange={(e) => this.onChangeRange(e)}
                  />
                </div>
              </div>
            </div>
          </Col>
          {duesBalance && (
            <Col xs="12">
              <div className="">
                <span
                  className={
                    "m-2 card-text custom-checkbox " +
                    (this.props.isMobile && "ml-sm-0")
                  }
                >
                  <input
                    onChange={(e) => this.onChange(e)}
                    name="DuesBalance"
                    htmlFor="DuesBalance"
                    id="DuesBalance"
                    type="checkbox"
                    checked={duesBalance.checked}
                    //          style={{ zIndex: 1 }}
                    className="custom-control-input pr-2"
                  />
                  <label
                    className={
                      "h6 custom-control-label " +
                      (this.props.isMobile && "ml-3 ml-sm-2 text-white")
                    }
                    htmlFor={"DuesBalance"}
                    //style={{ paddingTop: 2, zIndex: 0 }}
                  >
                    Members' Owe more than 0$ balance.
                    <small className="totalCount">({duesBalance.total})</small>
                  </label>
                </span>
              </div>
            </Col>
          )}
        </Row>
      </>
    );
  }
}

export default connect((state) => ({ filterState: state.advancedFilter }), {
  ...actionFilter,
})(Finance);
