import axios from "../../modules/Shared/auth-header";
import $ from "jquery";
import DataUploadStatus from "../../modules/Admin/DataUploadStatus";
import { toast } from "react-toastify";
import _ from "lodash";

const actions = {
  PAYFILE_ACTIVE_TAB: "PAYFILE_ACTIVE_TAB",
  SET_REVIEW_SCREEN_DATA: "SET_REVIEW_SCREEN_DATA",
  SET_TOTAL_DATA_COUNT: "SET_TOTAL_DATA_COUNT",
  SET_PAGINATION_INFO: "SET_PAGINATION_INFO",
  SET_SORT_ORDER: "SET_SORT_ORDER",
  RESET_REVIEW_SCREEN_DATA: "RESET_REVIEW_SCREEN_DATA",
  SET_LOADING: "SET_LOADING",
  SET_FILE_ID: "SET_FILE_ID",
  PROCESS_UPDATE: "PROCESS_UPDATE",
  PROCESS_UPDATE_SKIP: "PROCESS_UPDATE_SKIP",
  PROCESS_DELETE_SKIP: "PROCESS_DELETE_SKIP",
  SET_CURRENT_PAY_PERIOD: "SET_CURRENT_PAY_PERIOD",
  SET_MAIN_SUMMARY_LIST: "SET_MAIN_SUMMARY_LIST",
  SET_SUMMARY_LIST_DATA_SIZE: "SET_SUMMARY_LIST_DATA_SIZE",
  SET_SUMMARY_LIST_LOADING: "SET_SUMMARY_LIST_LOADING",
  SET_SUMMARY_PAGINATION_INFO: "SET_SUMMARY_PAGINATION_INFO",
  PROCESS_SKIP_UPDATE: "PROCESS_SKIP_UPDATE",
  UPDATE_FILE_PROGRESS: "UPDATE_FILE_PROGRESS",
  CHANGE_FILE_STATUS: "CHANGE_FILE_STATUS",
  WS_CONNECT: "WS_CONNECT",
  WS_CONNECTING: "WS_CONNECTING",
  WS_CONNECTED: "WS_CONNECTED",
  WS_DISCONNECT: "WS_DISCONNECT",
  WS_DISCONNECTED: "WS_DISCONNECTED",
  ANY_FILE_UNFINISHED: "ANY_FILE_UNFINISHED",
  RELOAD_PAYFILE_INDICATOR_LAST_UPDATE_FINISH:
    "RELOAD_PAYFILE_INDICATOR_LAST_UPDATE_FINISH",
  SET_CONNECTION_ID: "SET_CONNECTION_ID",
  RELOAD_PAYFILE_INDICATOR_LAST_UPDATE: "RELOAD_PAYFILE_INDICATOR_LAST_UPDATE",
  wsConnect: (host) => (dispatch) => {
    dispatch({
      type: actions.WS_CONNECT,
      payload: host,
    });
  },
  wsConnecting: (host) => (dispatch) => {
    dispatch({
      type: actions.WS_CONNECTING,
      payload: host,
    });
  },
  wsConnected: (host) => (dispatch) => {
    dispatch({
      type: actions.WS_CONNECTED,
      payload: host,
    });
  },
  wsDisconnect: (host) => (dispatch) => {
    dispatch({
      type: actions.WS_DISCONNECT,
      payload: host,
    });
  },
  wsDisconnected: (host) => (dispatch) => {
    dispatch({
      type: actions.WS_DISCONNECTED,
      payload: host,
    });
  },
  setConnectionId: (id) => (dispatch) => {
    dispatch({
      type: actions.SET_CONNECTION_ID,
      payload: id,
    });
  },
  payFileActiveTab: (tab) => (dispatch) => {
    dispatch({
      type: actions.PAYFILE_ACTIVE_TAB,
      payload: tab,
    });
  },
  setTotalDataCount: (count, type) => (dispatch) => {
    dispatch({
      type: actions.SET_TOTAL_DATA_COUNT,
      payload: { count, tableType: type },
    });
  },
  setLoading: (val, type) => (dispatch) => {
    dispatch({
      type: actions.SET_LOADING,
      payload: { val, type },
    });
  },

  setCurrentFileId: (id) => (dispatch) => {
    dispatch({
      type: actions.SET_FILE_ID,
      payload: id,
    });
  },
  getSummaryList: (body) => (dispatch) => {
    dispatch({
      type: actions.SET_SUMMARY_LIST_LOADING,
      payload: true,
    });
    axios
      .post("/api/DataImport/ImportSummarySearchRecords", body)
      .then(({ data }) => {
        if (data.status && data.status.toLowerCase() === "success") {
          dispatch({
            type: actions.SET_MAIN_SUMMARY_LIST,
            payload: data,
          });
          dispatch({
            type: actions.SET_SUMMARY_LIST_LOADING,
            payload: false,
          });
        } else {
          toast.error(data.message);
        }
      });
  },
  reloadPayfileIndidator: () => (dispatch) => {
    dispatch({
      type: actions.RELOAD_PAYFILE_INDICATOR_LAST_UPDATE,
      payload: true,
    });
  },
  finishedReloadPayfileIndidator: () => (dispatch) => {
    dispatch({
      type: actions.RELOAD_PAYFILE_INDICATOR_LAST_UPDATE_FINISH,
      payload: false,
    });
  },
  changeFileStatus: (fileId, fileStatus) => (dispatch) => {
    dispatch({
      type: actions.CHANGE_FILE_STATUS,
      payload: { file_id: fileId, fileStatus: fileStatus },
    });
    dispatch({
      type: actions.ANY_FILE_UNFINISHED,
    });
  },
  closeWebSocket: () => (dispatch, getState) => {
    let { isPendingProcess } = getState().payfile;
    if (!isPendingProcess) {
      dispatch({
        type: actions.WS_DISCONNECT,
      });
    }
  },
  fetchPayFileOneReviewRecords: (formData) => (dispatch) => {
    dispatch({
      type: actions.SET_LOADING,
      payload: { val: true, type: formData.recordtype },
    });
    axios
      .post("/api/dataimport/GetPayFileOneReviewRecords/", formData)
      .then(({ data }) => {
        if (data && data.status.toLowerCase() == "success") {
          dispatch({
            type: actions.SET_REVIEW_SCREEN_DATA,
            payload: { data, type: formData.recordtype },
          });

          dispatch({
            type: actions.SET_LOADING,
            payload: { val: false, type: formData.recordtype },
          });
        } else {
          console.log(data.status);
        }
      })
      .catch((error) => {
        console.log("error block", error);
      });
  },

  searchPayFileOneReviewRecords: (formData) => (dispatch) => {
    if (_.isEmpty(formData.fileId)) {
      console.log("fileId is empty: ", formData.fileId);
      return false;
    }

    formData.recordsTypeList.forEach((element) => {
      dispatch({
        type: actions.SET_LOADING,
        payload: { val: true, type: element },
      });
    });

    axios
      .post("/api/dataimport/SearchPayFileOneReviewRecords/", formData)
      .then(({ data }) => {
        if (data && data.status.toLowerCase() == "success") {
          data.data.forEach((element) => {
            dispatch({
              type: actions.SET_REVIEW_SCREEN_DATA,
              payload: { data: element, type: element.recordType },
            });
          });

          formData.recordsTypeList.forEach((element) => {
            dispatch({
              type: actions.SET_LOADING,
              payload: { val: false, type: element },
            });
          });
        } else {
          console.log(data.status);
        }
      })
      .catch((error) => {
        console.log("error block", error);
      });
  },
  setTablePaginationInfo: (pageNo, sizePerPage, tableType) => (dispatch) => {
    var payLoadData = {
      pageNo,
      sizePerPage,
      tableType,
    };
    dispatch({
      type: actions.SET_PAGINATION_INFO,
      payload: payLoadData,
    });
  },
  setSortOrder: (sortBy, orderBy, tableType) => (dispatch) => {
    var payLoadData = {
      sortBy,
      orderBy,
      tableType,
    };
    dispatch({
      type: actions.SET_SORT_ORDER,
      payload: payLoadData,
    });
  },
  resetReviewScreenData: () => (dispatch) => {
    dispatch({
      type: actions.RESET_REVIEW_SCREEN_DATA,
    });
  },
  ///Called from Delete icon of Errors Record Expandable View
  ignoreUpdateError: (formData) => (dispatch, getState) => {
    const {
      errorPageNo,
      errorSizePerPage,
      orderErrorBy,
      sortErrorBy,
      sortRemovedBy,
      orderRemovedBy,
    } = getState().payfile;
    dispatch({
      type: actions.PROCESS_UPDATE_SKIP,
    });
    $(".loading").show();
    let Url = `/api/DataImport/MarkErrorAsIgnore/`; //Update; isdelete work
    axios.post(Url, formData).then(({ data }) => {
      $(".loading").hide();
      if (data.status) {
        if (data.status.toLowerCase() === "success") {
          toast.success(data.message);
          var activeSections = [];

          activeSections.push(DataUploadStatus.Error);
          //activeSections.push(DataUploadStatus.Delete);
          const requestData = {
            fileId: formData.importedfileId,
            Keyword: "",
            recordsTypeList: activeSections,
            PageNo: errorPageNo,
            PageSize: errorSizePerPage,
            OperatorType: "AND",
            SortBy: sortErrorBy,
            SortOrder: orderErrorBy,
            //"firstnameAsc",
          };

          let action = actions.searchPayFileOneReviewRecords(requestData);
          dispatch(action);

          ///Also Refresh Delete Records Section

          const DeleteSectionRequestData = {
            fileId: formData.importedfileId,
            Keyword: "",
            recordsTypeList: [DataUploadStatus.Delete],
            PageNo: 1,
            PageSize: 10,
            OperatorType: "AND",
            SortBy: sortRemovedBy,
            SortOrder: orderRemovedBy,
            //SortOrder: "firstnameAsc", //"firstnameAsc",
          };
          let reloadDeleteRecords = actions.searchPayFileOneReviewRecords(
            DeleteSectionRequestData
          );
          dispatch(reloadDeleteRecords);
        } else {
          toast.error(data.message);
          $(".loading").hide();
        }
      } else {
        toast.error(data.message);
        $(".loading").hide();
      }
    });
  },
  ///Called from Delete icon of Skips Record Expandable View

  DeleteIgnore: (formData) => (dispatch, getState) => {
    const {
      ignorePageNo,
      ignoreSizePerPage,
      sortIgnoreBy,
      orderIgnoreBy,
      sortRemovedBy,
      orderRemovedBy,
    } = getState().payfile;
    dispatch({
      type: actions.PROCESS_DELETE_SKIP,
    });
    $(".loading").show();
    let Url = `/api/DataImport/MarkIgnoreAsDelete/`;
    axios.post(Url, formData).then(({ data }) => {
      $(".loading").hide();
      if (data.status) {
        if (data.status.toLowerCase() === "success") {
          toast.success(data.message);

          const requestData = {
            fileId: formData.importedfileId,
            Keyword: "",
            recordsTypeList: [DataUploadStatus.Skip],
            PageNo: ignorePageNo,
            PageSize: ignoreSizePerPage,
            OperatorType: "AND",
            SortBy: sortIgnoreBy,
            SortOrder: orderIgnoreBy, //"firstnameAsc",
          };

          let action = actions.searchPayFileOneReviewRecords(requestData);
          dispatch(action);

          ///Also Refresh Delete Records Section

          const DeleteSectionRequestData = {
            fileId: formData.importedfileId,
            Keyword: "",
            recordsTypeList: [DataUploadStatus.Delete],
            PageNo: 1,
            PageSize: 10,
            OperatorType: "AND",
            SortOrder: orderRemovedBy,
            SortBy: sortRemovedBy, //"firstnameAsc",
          };
          let reloadDeleteRecords = actions.searchPayFileOneReviewRecords(
            DeleteSectionRequestData
          );
          dispatch(reloadDeleteRecords);
        } else {
          toast.error(data.message);
          $(".loading").hide();
        }
      } else {
        toast.error(data.message);
        $(".loading").hide();
      }
    });
  },
  //Called from  Add btn of Skip and Error and Delete table section
  updateIgnoreAsSuccess: (formData) => (dispatch, getState) => {
    const {
      errorPageNo,
      errorSizePerPage,
      sortErrorBy,
      orderErrorBy,
      ignorePageNo,
      ignoreSizePerPage,
      sortIgnoreBy,
      orderIgnoreBy,
      removePageNo,
      removeSizePerpage,
      sortRemovedBy,
      orderRemovedBy,
      sortSuccessBy,
      orderSuccessBy,
    } = getState().payfile;
    dispatch({
      type: actions.PROCESS_SKIP_UPDATE,
    });
    $(".loading").show();
    let Url = `/api/DataImport/MarkIgnoreAsUpdate/`;
    axios.post(Url, formData).then(({ data }) => {
      $(".loading").hide();
      if (data.status) {
        if (data.status.toLowerCase() === "success") {
          toast.success(data.message);

          var activeSections = [];
          let requestData = {
            fileId: formData.importedfileId,
            Keyword: "",
            //SortOrder: "firstnameAsc", //"firstnameAsc",
          };
          if (
            (formData.isNewMember &&
              !formData.isDeleted &&
              !formData.isErrorRecord) ||
            formData.isSkipTable
          ) {
            activeSections.push(DataUploadStatus.Skip);
            requestData = {
              ...requestData,
              PageNo: ignorePageNo,
              PageSize: ignoreSizePerPage,
              recordsTypeList: activeSections,
              SortOrder: orderIgnoreBy,
              SortBy: sortIgnoreBy,
            };
          } else if (formData.isDeleted) {
            /// called from removed section for new
            activeSections.push(DataUploadStatus.Delete);
            requestData = {
              ...requestData,
              PageNo: removePageNo,
              PageSize: removeSizePerpage,
              recordsTypeList: activeSections,
              SortOrder: orderRemovedBy,
              SortBy: sortRemovedBy,
            };
          } else if (formData.isErrorRecord) {
            /// called from error section for new
            activeSections.push(DataUploadStatus.Error);
            requestData = {
              ...requestData,
              PageNo: errorPageNo,
              PageSize: errorSizePerPage,
              recordsTypeList: activeSections,
              SortOrder: orderErrorBy,
              SortBy: sortErrorBy,
            };
          }

          let action = actions.searchPayFileOneReviewRecords(requestData);
          dispatch(action);
          //// #########################Reload Destination Update####################### Records

          const requestDataforUpdates = {
            fileId: formData.importedfileId,
            Keyword: "",
            recordsTypeList: [DataUploadStatus.Update],
            PageNo: 1,
            PageSize: 10,
            OperatorType: "AND",
            SortOrder: orderSuccessBy,
            SortBy: sortSuccessBy, //"firstnameAsc",
          };

          let reloadUpdateSectionAction = actions.searchPayFileOneReviewRecords(
            requestDataforUpdates
          );
          dispatch(reloadUpdateSectionAction);
        } else {
          toast.error(data.message);
          $(".loading").hide();
        }
      } else {
        toast.error(data.message);
        $(".loading").hide();
      }
    });
  },
  //Called from
  //Edit Error Modal PopUp => Import Errors
  //Edit Error Modal PopUp => Removed Updates
  processUpdateError: (formData, myself) => (dispatch, getState) => {
    const {
      errorPageNo,
      sortErrorBy,
      orderErrorBy,
      errorSizePerPage,
      removePageNo,
      orderRemovedBy,
      sortRemovedBy,
      removeSizePerpage,
      orderSuccessBy,
      sortSuccessBy,
    } = getState().payfile;
    dispatch({
      type: actions.PROCESS_UPDATE,
    });
    $(".loading").show();
    let Url = `/api/DataImport/ProcessUpdateError/`;
    axios.post(Url, formData).then(({ data }) => {
      $(".loading").hide();
      if (data.status) {
        if (data.status.toLowerCase() === "success") {
          toast.success(data.message);

          if (myself.props.isRemovedSection) {
            //////////////////////  Called from  Removed Section///////////////////////////
            const requestDataForRemovedSection = {
              fileId: formData.importedfileId,
              Keyword: "",
              recordsTypeList: [DataUploadStatus.Delete],
              PageNo: removePageNo,
              PageSize: removeSizePerpage,
              OperatorType: "AND",
              SortBy: sortRemovedBy,
              SortOrder: orderRemovedBy,
              //SortOrder: "firstnameAsc", //"firstnameAsc",
            };

            let reloadDeleteSection = actions.searchPayFileOneReviewRecords(
              requestDataForRemovedSection
            );
            dispatch(reloadDeleteSection);
            //############ Reload Destination Update Records######
            const requestDataforUpdates = {
              fileId: formData.importedfileId,
              Keyword: "",
              recordsTypeList: [DataUploadStatus.Update],
              PageNo: 1,
              PageSize: 10,
              OperatorType: "AND",

              SortBy: sortSuccessBy,
              SortOrder: orderSuccessBy,
              //SortOrder: "firstnameAsc", //"firstnameAsc",
            };

            let reloadUpdateSectionAction = actions.searchPayFileOneReviewRecords(
              requestDataforUpdates
            );
            dispatch(reloadUpdateSectionAction);
          } else {
            //////////////////////////////////////  Called from  Import Errors////////////////////////////////////
            const requestDataForErrorsSection = {
              fileId: formData.importedfileId,
              Keyword: "",
              recordsTypeList: [DataUploadStatus.Error],
              PageNo: errorPageNo,
              PageSize: errorSizePerPage,
              OperatorType: "AND",
              SortBy: sortErrorBy,
              SortOrder: orderErrorBy,
              //  SortOrder: "firstnameAsc", //"firstnameAsc",
            };

            let reloadErrorSection = actions.searchPayFileOneReviewRecords(
              requestDataForErrorsSection
            );
            dispatch(reloadErrorSection);

            //// #########################Reload Destination Update####################### Records
            const requestDataforUpdates = {
              fileId: formData.importedfileId,
              Keyword: "",
              recordsTypeList: [DataUploadStatus.Update],
              PageNo: 1,
              PageSize: 10,
              OperatorType: "AND",
              SortOrder: orderSuccessBy,
              SortBy: sortSuccessBy, //"firstnameAsc",
            };

            let reloadUpdateSectionAction = actions.searchPayFileOneReviewRecords(
              requestDataforUpdates
            );
            dispatch(reloadUpdateSectionAction);
          }
        } else {
          toast.error(data.message);
          //myself.setState({})
          myself.props.closePayfileErrorModal();
          $(".loading").hide();
        }
      } else {
        toast.error(data.message);
        $(".loading").hide();
      }
    });
  },

  getNextPayPeriod: (payperiod, payyear, myself) => (dispatch) => {
    let Url = `/api/DataImport/FetchCurrentPayPeriod/${payperiod}/${payyear}`;
    axios.get(Url).then(({ data }) => {
      if (data.status) {
        if (data.status.toLowerCase() === "success") {
          dispatch({
            type: actions.SET_CURRENT_PAY_PERIOD,
            payload: data.data,
          });
          var un_used_pay_periods = data.data.unused_pay_period;
          var mycurrentPayPeriod = data.data.current_period.toString();
          if (
            data.data.current_period > 27 &&
            !_.isEmpty(un_used_pay_periods) &&
            un_used_pay_periods.length > 0
          ) {
            $(".payperiodoptions select").val(un_used_pay_periods[0]);
            myself.setState({
              payperiod: un_used_pay_periods[0],
            });
          } else {
            myself.setState({
              payperiod: data.data.current_period.toString(),
            });
            $(".payperiodoptions select").val(mycurrentPayPeriod);
          }

          ///disable checks
          //$("#payperiod > option").each(function() {
          //  $(this).attr("disabled", false);
          //  $(this).css("background", "");
          //});

          // $("#payperiod > option").each(function() {
          //   if (!un_used_pay_periods.includes(Number(this.value))) {
          //     $(this).attr("disabled", "disabled");
          //     $(this).css("background", "aliceblue");
          //   }
          // });
        } else {
          toast.error(data.message);
        }
      } else {
        toast.error(data.message);
      }
    });
  },
  setTotalDataCountSummary: (dataSize) => (dispatch) => {
    dispatch({
      type: actions.SET_SUMMARY_LIST_DATA_SIZE,
      payload: dataSize,
    });
  },
  setLoadingSummary: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_SUMMARY_LIST_LOADING,
      payload: val,
    });
  },

  setSummaryTablePaginationInfo: (pageNo, sizePerPage) => (dispatch) => {
    var payLoadData = {
      pageNo,
      sizePerPage,
    };
    dispatch({
      type: actions.SET_SUMMARY_PAGINATION_INFO,
      payload: payLoadData,
    });
  },

  updateFileProgress: (currentData) => (dispatch) => {
    dispatch({
      type: actions.UPDATE_FILE_PROGRESS,
      payload: currentData,
    });
  },
};

export default actions;
