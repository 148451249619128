import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import AUX from "../../hoc/Aux_";
import FETrackerAction from "../../../src/store/feTracker/action";
import PayfileAction from "../../../src/store/payfile/action";
import { connect } from "react-redux";
import $ from "jquery";
import "./Leftsidebar.css";
import AuthService from "../../modules/Auth/AuthService";
import PermissionServices from "../../modules/Permissions/PermissionService";
import PayfileServices from "../../modules/Payfile/PayfileService";
import PermissionFeature from "../../modules/Permissions/PermissionFeature";
import _ from "lodash";
import moment from "moment";
class SideNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Tab: "/",
      SubTab: "",
      MoreTab: "",
      directory: true,
      admin: true,
      emailList_menu: false,
      account: true,
      permission: true,
      myTeams: false,
      home: true,
      payfileStatusDate: null,
    };
    this.user = AuthService.getProfile().memberData;
    this.loadPayfileLastUpdateDate = this.loadPayfileLastUpdateDate.bind(this);
    this.directory = this.directory.bind(this);
    this.requestMyTeams = this.requestMyTeams.bind(this);
  }
  requestMyTeams = async () => {
    let data = await PermissionServices.TeamMemberExist();
    if (!_.isEmpty(data)) {
      this.state.myTeams = data.result;
      this.setState({ myTeams: this.state.myTeams });
    }
  };

  setActiveTab = (tab, subtab, moretab, e) => {
    console.log("active tab " + tab);
    this.setState({
      Tab: tab,
      SubTab: subtab,
      MoreTab: moretab,
      home: tab == "/",
    });

    window.refresh = Math.random();

    if ($(window).width() < 1025) {
      this.props.toggleSidebarCallback();
    }
  };

  componentDidMount() {
    $("#sidebar-menu a,#sidebar-menu li").removeClass("active");
    let page = window.location.pathname.split("/")[1];
    let subPage = window.location.pathname.split("/")[2];
    if (AuthService.canView(PermissionFeature.PayfileIndicator)) {
      this.loadPayfileLastUpdateDate();
    }

    ///This is throwing exception on javascript while login process
    let loggeduser = AuthService.getProfile();
    if (loggeduser.memberData != null && AuthService.isAuthenticated()) {
      this.requestMyTeams();
    }

    if (page == "/" || page == "") {
      this.setState({ Tab: "/", SubTab: "", home: true });
    } else if (page == "myTeams") {
      this.setState({ Tab: page, SubTab: "", home: false });
    } else if (page === "PACTracker") {
      this.setState({ Tab: page, SubTab: "", home: false });
    } else if (page === "FETracker") {
      this.setState({ Tab: page, SubTab: "", home: false });
    } else if (page === "Grievances") {
      this.setState({ Tab: page, SubTab: "", home: false });
    } else if (page === "Registrations") {
      this.setState({ Tab: page, SubTab: "", home: false });
    } else {
      if (
        subPage == "userAction" ||
        subPage == "region" ||
        subPage == "facility" ||
        subPage == "members"
      ) {
        this.setState({
          Tab: "directory",
          SubTab: "",
          home: false,
        });
      } else if (subPage == "edit-team") {
        this.setState({
          Tab: "admin",
          SubTab: "teams",
          home: false,
        });
      } else if (subPage == "registrations") {
        this.setState({
          Tab: "admin",
          SubTab: "registrations",
          home: false,
        });
      } else if (
        page == "permissions" &&
        (subPage == "teams" || subPage == "positions")
      ) {
        this.setState({
          Tab: "permissions",
          SubTab: "teams",
          home: false,
          permissions_menu: true,
        });
      } else {
        this.setState({
          Tab: page,
          SubTab: subPage,
          home: false,
          [`${page}_menu`]: !this.state.admin_menu,
        });
      }
    }
  }

  componentWillUpdate(e) {
    $("#sidebar-menu a,#sidebar-menu li").removeClass("active");
    const loggeduser = AuthService.getProfile();
    //if (loggeduser.memberData == null) {
    //  //e.history.push("/login");

    //}

    let page = window.location.pathname.split("/")[1];
    if ((page == "/" || page == "") && !this.state.home) {
      this.setState({ Tab: "/", SubTab: "", home: true });
    }
    if (page == "/" || (page == "" && this.user)) {
      e.history.push(`/dashboard/myProfile/${this.user && this.user.id}`);
    }
  }

  directory(match, location) {
    $("#sidebar-menu a,#sidebar-menu li").removeClass("active");
    let page = window.location.pathname.split("/")[1];
    let subPage = window.location.pathname.split("/")[2];

    if (page == "/" || page == "") {
      page = "dashboard";
    }
    $("#sidebar-menu a#" + page).addClass("active");
    if (this.state.SubTab) {
      $("#sidebar-menu .submenu li#" + subPage).addClass("active");
    }
    if (page === "PACTracker") {
      if (this.state.Tab !== page) {
        this.setState({ Tab: page, SubTab: "", home: false });
      }
      $("#sidebar-menu .submenu li#" + subPage).addClass("active");
    }
    if (page === "FETracker") {
      if (this.state.Tab !== page) {
        this.setState({ Tab: page, SubTab: "", home: false });
      }
      $("#FETracker").addClass("active");
    }
    if (page === "Grievances") {
      if (this.state.Tab !== page) {
        this.setState({ Tab: page, SubTab: "", home: false });
      }
      $("#sidebar-menu .submenu li#" + subPage).addClass("active");
    }
    if (page === "Registrations" || page === "MyRegistrations") {
      if (this.state.Tab !== page) {
        this.setState({ Tab: page, SubTab: "", home: false });
      }
      $("#Registrations").addClass("active");
    }
    if (
      page === "admin" &&
      (subPage === "registrations" ||
        subPage === "templates" ||
        subPage === "requests" ||
        subPage === "attendance" ||
        subPage === "questions" ||
        subPage === "discounts")
    ) {
      if (this.state.Tab !== page) {
        this.setState({ Tab: page, SubTab: subPage, home: false });
      }
      $("#admin_registrations").addClass("active");
    }
    if (page == "emailLists" && (subPage == "all" || subPage == "my")) {
      if (subPage != this.state.SubTab) {
        this.setState({ Tab: page, SubTab: subPage, home: false });
      }
      $("#emailList").addClass("active");
    }
    if (page == "Uploads" && subPage == "PayFileOneReview") {
      $("#admin").addClass("active");
      $("#sidebar-menu .submenu li#" + "payfiles").addClass("active");
    }
  }
  loadPayfileLastUpdateDate = async () => {
    let data = await PayfileServices.LastStatusUpdateDate();
    if (data != null) {
      try {
        this.state.payfileStatusDate = moment(new Date()).format("MM/DD/YYYY");
        this.setState({ payfileStatusDate: this.state.payfileStatusDate });
        this.props.finishedReloadPayfileIndidator();
      } catch (e) {
        console.error("Error parsing Last Change Date:", e);
      }
    }
  };
  componentDidUpdate() {
    if (
      this.props.payfile.reloadLastUpdate &&
      AuthService.canView(PermissionFeature.PayfileIndicator)
    ) {
      this.props.finishedReloadPayfileIndidator();
      this.loadPayfileLastUpdateDate();
    }
  }
  hanldeFETrackerNavifation = () => {
    if (AuthService.canView(PermissionFeature.FEAdmin)) {
      this.setActiveTab.bind(this, "FETracker", "", "");
      //this.props.history.push("/FETracker/inventory");
    }
  };

  render() {
    let equipmentPageUrl = "";
    if (this.props.festate.isEVPApprover) {
      equipmentPageUrl = "/FETracker/reviewRequest";
    } else if (this.props.festate.isAccountingPerson) {
      equipmentPageUrl = "/FETracker/inventory";
    } else {
      equipmentPageUrl = "/FETracker/myRequests";
    }

    return (
      <AUX>
        <div>
          {/* <div className="card hovercard">
                        <div className="info cardheader">
                            <div className="title">{this.user && this.user.nickname !== null ? this.user.nickname : (this.user && this.user.firstname !== null ? this.user.firstname : "")}</div>
                            <div className="desc">Member Number: {this.user && this.user.membernumber}</div>
                            <input type="hidden" id="membernumber" value={this.user && this.user.membernumber} />
                        </div>
                    </div> */}
          <input
            type="hidden"
            id="membernumber"
            value={this.user && this.user.membernumber}
          />
          <div
            id="sidebar-menu"
            style={{ marginTop: "32px" }}
            className="pt-5 pt-md-0"
          >
            <ul className="metismenu" id="side-menu">
              <li>
                <NavLink
                  //to="/"
                  to={`/dashboard/myProfile/${this.user && this.user.id}`}
                  id="dashboard"
                  isActive={this.directory}
                  activeClassName="active"
                  className={
                    this.state.Tab == "/" || this.state.Tab == ""
                      ? "waves-effect mlink"
                      : "waves-effect mlink"
                  }
                  onClick={this.setActiveTab.bind(this, "/", "", "")}
                >
                  <i className="fas fa-home" />
                  <span> My Profile </span>
                </NavLink>
              </li>
              {this.state.myTeams &&
                (this.user.membertypeid == 6 ||
                  this.user.membertypeid == 8 ||
                  this.user.membertypeid == 10) && (
                  <li>
                    <NavLink
                      to="/myTeams"
                      id="myTeams"
                      activeClassName="active"
                      className={
                        this.state.Tab == "myTeams"
                          ? "waves-effect mlink"
                          : "waves-effect mlink"
                      }
                      onClick={this.setActiveTab.bind(this, "myTeams", "", "")}
                    >
                      <i className="fas fa-users" />
                      <span> My Team </span>
                    </NavLink>
                  </li>
                )}

              {AuthService.canView(PermissionFeature.Directory) &&
                (this.user.membertypeid == 6 ||
                  this.user.membertypeid == 8 ||
                  this.user.membertypeid == 10) && (
                  <li>
                    <NavLink
                      to="/directory"
                      id="directory"
                      activeClassName="active"
                      className={
                        this.state.Tab == "directory"
                          ? "waves-effect mlink"
                          : "waves-effect mlink"
                      }
                      onClick={this.setActiveTab.bind(
                        this,
                        "directory",
                        "",
                        ""
                      )}
                    >
                      <i className="fa fa-th-list" />
                      <span> Directory </span>
                    </NavLink>
                  </li>
                )}
              {(AuthService.canView(PermissionFeature.EmailLists) ||
                AuthService.canView(PermissionFeature.EmailListAdmin)) &&
                (this.user.membertypeid == 6 ||
                  this.user.membertypeid == 8 ||
                  this.user.membertypeid == 10) && (
                  <li>
                    <Link
                      to="#"
                      id="emailList"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          emailList_menu: !this.state.emailList_menu,
                        });
                      }}
                      className={
                        "emailLists" == "emailLists"
                          ? "waves-effect active"
                          : "waves-effect"
                      }
                    >
                      <i className="fa fa-envelope" />
                      <span> Email Lists </span>
                      <span className="float-right menu-arrow">
                        <i className="mdi mdi-chevron-right" />
                      </span>
                    </Link>
                    {this.state.emailList_menu && (
                      <ul className="submenu" id="emailList_menu_hide">
                        {/* • This page is the Email Listing Screen and is visible
                        to all users */}
                        {//AuthService.canView(PermissionFeature.EmailLists) &&
                          (this.user.membertypeid == 6 ||
                            this.user.membertypeid == 8 ||
                            this.user.membertypeid == 10) && (
                            <li
                              id="myemaillist"
                              className={
                                this.state.SubTab === "my" ? "active" : ""
                              }
                            >
                              <NavLink
                                activeClassName="active"
                                onClick={this.setActiveTab.bind(
                                  this,
                                  "emailLists",
                                  "my",
                                  ""
                                )}
                                to="/emailLists/my"
                                className="mlink"
                              >
                                My Email Lists
                              </NavLink>
                            </li>
                          )}
                        {/* o If ON, a subheader is displayed with two options, My
                        Email Lists and All Email Lists, My Email Lists is
                        selected by default regardless of permission */}
                        {AuthService.canView(
                          PermissionFeature.EmailListAdmin
                        ) && // Permission Path ===> "Email List"/Admin/View
                          (this.user.membertypeid == 6 ||
                            this.user.membertypeid == 8 ||
                            this.user.membertypeid == 10) && (
                            <li
                              id="allemailist"
                              className={
                                this.state.SubTab === "all" ? "active" : ""
                              }
                            >
                              <NavLink
                                activeClassName="active"
                                onClick={this.setActiveTab.bind(
                                  this,
                                  "emailLists",
                                  "all",
                                  ""
                                )}
                                to="/emailLists/all"
                                className="mlink"
                              >
                                All Email Lists
                              </NavLink>
                            </li>
                          )}
                      </ul>
                    )}
                  </li>
                )}
              {AuthService.canView(PermissionFeature.MainAdminLink) &&
                (this.user.membertypeid == 6 ||
                  this.user.membertypeid == 8 ||
                  this.user.membertypeid == 10) && (
                  <li>
                    <Link
                      to="#"
                      id="admin"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          admin_menu: !this.state.admin_menu,
                        });
                      }}
                      className={
                        this.state.Tab === "admin"
                          ? "waves-effect active"
                          : "waves-effect"
                      }
                    >
                      <i className="fas fa-shield-alt" />
                      <span> Admin </span>
                      <span className="float-right menu-arrow">
                        <i className="mdi mdi-chevron-right" />
                      </span>
                    </Link>
                    {this.state.admin_menu && (
                      <ul className="submenu" id="admin_menu_hide">
                        {AuthService.canView(PermissionFeature.AdminUploads) &&
                          (this.user.membertypeid == 6 ||
                            this.user.membertypeid == 8 ||
                            this.user.membertypeid == 10) && (
                            <li
                              id="uploads"
                              className={
                                this.state.SubTab === "payfile" ? "active" : ""
                              }
                            >
                              <NavLink
                                activeClassName="active"
                                onClick={this.setActiveTab.bind(
                                  this,
                                  "admin",
                                  "payfile",
                                  ""
                                )}
                                to="/admin/uploads"
                                className="mlink"
                              >
                                Uploads
                              </NavLink>
                            </li>
                          )}
                        {/* Let's put a Saved Filters option in the Admin menu in
                        the left sidebar that loads ALL saved filters in the
                        system and allows the user to filter by name of saved
                        search and by owners (basic search). This is driven off
                        the Admin permission. */}
                        {AuthService.canView(PermissionFeature.FilterAdmin) &&
                          (this.user.membertypeid == 6 ||
                            this.user.membertypeid == 8 ||
                            this.user.membertypeid == 10) && (
                            <li
                              id="allFilters"
                              className={
                                this.state.SubTab === "allfilters"
                                  ? "active"
                                  : ""
                              }
                            >
                              <NavLink
                                activeClassName="active"
                                onClick={this.setActiveTab.bind(
                                  this,
                                  "admin",
                                  "allfilters",
                                  ""
                                )}
                                to="/admin/allfilters"
                                className="mlink"
                              >
                                All Saved Filters
                              </NavLink>
                            </li>
                          )}
                        {(AuthService.canView(
                          PermissionFeature.REGSuperAdmin
                        ) ||
                          AuthService.canView(
                            PermissionFeature.REGBasicAdmin
                          )) && (
                            <li
                              id="admin_registrations"
                              className={
                                this.state.SubTab === "templates" ? "active" : ""
                              }
                            >
                              <NavLink
                                activeClassName="active"
                                onClick={this.setActiveTab.bind(
                                  this,
                                  "admin",
                                  "templates",
                                  ""
                                )}
                                to="/admin/templates"
                                className="mlink"
                              >
                                Registrations
                              </NavLink>
                            </li>
                          )}
                      </ul>
                    )}
                  </li>
                )}
              {AuthService.canView(PermissionFeature.Teams) &&
                (this.user.membertypeid == 6 ||
                  this.user.membertypeid == 8 ||
                  this.user.membertypeid == 10) && (
                  <li>
                    <NavLink
                      to="/permissions/teams"
                      id="permissions"
                      activeClassName="active"
                      className={
                        this.state.Tab == "permissions"
                          ? "waves-effect mlink"
                          : "waves-effect mlink"
                      }
                      onClick={this.setActiveTab.bind(
                        this,
                        "permissions",
                        "",
                        ""
                      )}
                    >
                      <i className="fas fa-lock" />
                      <span> Teams </span>
                    </NavLink>
                  </li>
                )}
              {AuthService.canView(PermissionFeature.PACTracker) &&
                (this.user.membertypeid == 6 ||
                  this.user.membertypeid == 8 ||
                  this.user.membertypeid == 10) && (
                  <li>
                    <NavLink
                      to="/PACTracker"
                      id="PACTracker"
                      onClick={() => {
                        this.setActiveTab.bind(this, "PACTracker", "", "");
                        this.props.history.push("/PACTracker");
                      }}
                      className={
                        this.state.Tab === "PACTracker"
                          ? "waves-effect mlink"
                          : "waves-effect mlink"
                      }
                    >
                      <i className="fas fa-money-check-alt" />

                      <span>PAC Tracker</span>
                    </NavLink>
                  </li>
                )}

              {AuthService.canView(PermissionFeature.FETracker) &&
                (this.user.membertypeid == 6 ||
                  this.user.membertypeid == 8 ||
                  this.user.membertypeid == 10) && (
                  <li>
                    <NavLink
                      to={equipmentPageUrl}
                      id="FETracker"
                      onClick={this.hanldeFETrackerNavifation}
                      className={
                        this.state.Tab === "FETracker"
                          ? `waves-effect mlink ${equipmentPageUrl.includes("FETracker") &&
                            window.location.pathname
                              .split("/")[1]
                              .includes("FETracker")
                            ? "active"
                            : ""
                          }`
                          : "waves-effect mlink"
                      }
                    >
                      <i className="fas fas fa-laptop" />

                      <span>Equipment</span>
                    </NavLink>
                  </li>
                )}
              {AuthService.canView(PermissionFeature.Grievances) &&
                (this.user.membertypeid == 6 ||
                  this.user.membertypeid == 8 ||
                  this.user.membertypeid == 10) && (
                  <li>
                    <NavLink
                      to="/Grievances"
                      id="Grievances"
                      onClick={() => {
                        this.setActiveTab.bind(this, "Grievances", "", "");
                        this.props.history.push("/Grievances");
                      }}
                      className={
                        this.state.Tab === "Grievances"
                          ? "waves-effect mlink"
                          : "waves-effect mlink"
                      }
                    >
                      <i class="fas fa-clipboard-list fa-6x" />

                      <span>Grievances</span>
                    </NavLink>
                  </li>
                )}

              {AuthService.canMTView() &&
                AuthService.canView(PermissionFeature.Finance) && (
                  <li>
                    <NavLink
                      to="/finance"
                      id="Finance"
                      activeClassName="active"
                      className={
                        this.state.Tab == "finance"
                          ? "waves-effect mlink"
                          : "waves-effect mlink"
                      }
                      onClick={this.setActiveTab.bind(this, "finance", "", "")}
                    >
                      <i className="fas fa-dollar" />
                      <span> Finance</span>
                    </NavLink>
                  </li>
                )}
              {AuthService.canMTView() &&
                AuthService.canView(PermissionFeature.Registrations) && (
                  <li>
                    <NavLink
                      to="/Registrations"
                      id="Registrations"
                      onClick={() => {
                        this.setActiveTab.bind(this, "Registrations", "", "");
                        this.props.history.push("/Registrations");
                      }}
                      className={
                        this.state.Tab === "Registrations"
                          ? "waves-effect mlink"
                          : "waves-effect mlink"
                      }
                    >
                      <i class="fas fa-clipboard-list fa-6x"></i>

                      <span>Registrations</span>
                    </NavLink>
                  </li>
                )}
            </ul>
          </div>
          <div className="clearfix" />
          {this.state.payfileStatusDate &&
            AuthService.canView(PermissionFeature.PayfileIndicator) && (
              <NavLink
                className="row mx-0 mt-2 align-items-center text-white"
                to="/admin/uploads"
              >
                <span className="col-auto ml-1">
                  <i class="fas fa-calendar mb-0 h6 pr-1"></i>
                </span>
                <span
                  id="PayfileIndicator"
                  onClick={() => {}}
                  className={
                    "w-100 col text-white vertical-align-middle h6 " +
                      this.state.Tab ===
                    "PayfileIndicator"
                      ? "waves-effect mlink"
                      : "waves-effect mlink"
                  }
                >
                  <small className="text-secondary text-italic">
                    Payfile Status Date:
                  </small>
                  <br />
                  <span>{this.state.payfileStatusDate}</span>
                </span>
              </NavLink>
            )}
        </div>
      </AUX>
    );
  }
}

//export default withRouter(SideNav);

export default withRouter(
  connect(
    (state) => ({
      festate: state.fetracker,
      payfile: state.payfile,
    }),
    {
      ...FETrackerAction,
      ...PayfileAction,
    }
  )(SideNav)
);
