import actions from "./action";
import DataUploadStatus from "../../modules/Admin/DataUploadStatus";

const initialState = {
  payfileActiveTab: 1,
  summaryRows: [],
  errorRows: [],
  ignoredRows: [],
  successRows: [],
  removedRows: [],
  totalErrorCount: 0,
  totalIgnoredCount: 0,
  totalSuccessCount: 0,
  totalSummaryCount: 0,
  totalRemovedCount: 0,
  sortErrorBy: null,
  sortIgnoreBy: null,
  sortSuccessBy: null,
  sortSummaryBy: null,
  sortRemovedBy: null,
  orderErrorBy: null,
  orderIgnoreBy: null,
  orderSuccessBy: null,
  orderSummaryBy: null,
  orderRemovedBy: null,
  showTotal: true,
  errorSizePerPage: 10,
  ignoreSizePerPage: 10,
  successSizePerPage: 10,
  summarySizePerPage: 10,
  removeSizePerpage: 10,
  errorPageNo: 1,
  ignorePageNo: 1,
  successPageNo: 1,
  summaryPageNo: 1,
  removePageNo: 1,
  fileId: 0,
  isLoadingErrors: false,
  isLoadingSkips: false,
  isLoadingSuccess: false,
  isLoadingSummary: false,
  isLoadingRemove: false,
  currentPayPeriod: 0,
  webSocket: {},
  webSocketState: "",
  webSocketError: "",
  connectionId: "",
  reloadLastUpdate: false,
  isPendingProcess: false,
};

export default function payfile(state = initialState, action) {
  switch (action.type) {
    case actions.SET_CONNECTION_ID:
      return {
        ...state,
        connectionId: action.payload,
      };
    case actions.WS_DISCONNECTED:
      return {
        ...state,
        webSocketError: action.payload,
        webSocketState: "DISCONNECTED",
      };
    case actions.RELOAD_PAYFILE_INDICATOR_LAST_UPDATE:
    case actions.RELOAD_PAYFILE_INDICATOR_LAST_UPDATE_FINISH:
      return {
        ...state,
        reloadLastUpdate: action.payload,
      };
    case actions.WS_CONNECTED:
      return {
        ...state,
        webSocket: action.payload,
        webSocketState: "CONNECTED",
      };
    case actions.PAYFILE_ACTIVE_TAB:
      return {
        ...state,
        payfileActiveTab: action.payload,
      };

    case actions.SET_FILE_ID: {
      return {
        ...state,
        fileId: action.payload,
      };
    }

    case actions.SET_LOADING: {
      var actionPayload = action.payload;
      if (actionPayload.type === DataUploadStatus.Error) {
        return Object.assign({}, state, {
          isLoadingErrors: actionPayload.val,
        });
      }
      if (actionPayload.type === DataUploadStatus.Skip) {
        return Object.assign({}, state, {
          isLoadingSkips: actionPayload.val,
        });
      }
      if (actionPayload.type === DataUploadStatus.Update) {
        return Object.assign({}, state, {
          isLoadingSuccess: actionPayload.val,
        });
      }
      if (actionPayload.type === DataUploadStatus.Delete) {
        return Object.assign({}, state, {
          isLoadingRemove: actionPayload.val,
        });
      }
    }

    case actions.RESET_REVIEW_SCREEN_DATA:
      return {
        ...state,
        errorRows: [],
        ignoredRows: [],
        successRows: [],
        removedRows: [],
        totalErrorCount: 0,
        totalIgnoredCount: 0,
        totalSuccessCount: 0,
        totalRemoveCount: 0,
        errorSizePerPage: 10,
        ignoreSizePerPage: 10,
        successSizePerPage: 10,
        removeSizePerPage: 10,
        errorPageNo: 1,
        ignorePageNo: 1,
        successPageNo: 1,
        removePageNo: 1,
      };

    case actions.SET_REVIEW_SCREEN_DATA: {
      var actionPayload = action.payload;
      if (actionPayload.type === DataUploadStatus.Error) {
        return Object.assign({}, state, {
          errorRows: actionPayload.data.data,
          totalErrorCount: actionPayload.data.totalCount,
        });
      }
      if (actionPayload.type === DataUploadStatus.Skip) {
        return Object.assign({}, state, {
          ignoredRows: actionPayload.data.data,
          totalIgnoredCount: actionPayload.data.totalCount,
        });
      }

      if (actionPayload.type === DataUploadStatus.Update) {
        return Object.assign({}, state, {
          successRows: actionPayload.data.data,
          totalSuccessCount: actionPayload.data.totalCount,
        });
      }
      if (actionPayload.type === DataUploadStatus.Delete) {
        return Object.assign({}, state, {
          removedRows: actionPayload.data.data,
          totalRemovedCount: actionPayload.data.totalCount,
        });
      }
    }
    case actions.SET_TOTAL_DATA_COUNT: {
      var actionPayload = action.payload;
      if (actionPayload.tableType === DataUploadStatus.Error) {
        return Object.assign({}, state, {
          totalErrorCount: actionPayload.count,
        });
      }
      if (actionPayload.tableType === DataUploadStatus.Skip) {
        return Object.assign({}, state, {
          totalIgnoredCount: actionPayload.count,
        });
      }
      if (actionPayload.tableType === DataUploadStatus.Update) {
        return Object.assign({}, state, {
          totalSuccessCount: actionPayload.count,
        });
      }
      if (actionPayload.tableType === DataUploadStatus.Delete) {
        return Object.assign({}, state, {
          totalRemoveCount: actionPayload.count,
        });
      }
    }
    case actions.SET_PAGINATION_INFO: {
      var actionData = action.payload;

      if (actionData.tableType === DataUploadStatus.Error) {
        return Object.assign({}, state, {
          errorSizePerPage: actionData.sizePerPage,
          errorPageNo: actionData.pageNo,
        });
      } else if (actionData.tableType === DataUploadStatus.Skip) {
        return Object.assign({}, state, {
          ignoreSizePerPage: actionData.sizePerPage,
          ignorePageNo: actionData.pageNo,
        });
      } else if (actionData.tableType === DataUploadStatus.Update) {
        return Object.assign({}, state, {
          successSizePerPage: actionData.sizePerPage,
          successPageNo: actionData.pageNo,
        });
      } else if (actionData.tableType === DataUploadStatus.Delete) {
        return Object.assign({}, state, {
          removeSizePerpage: actionData.sizePerPage,
          removePageNo: actionData.pageNo,
        });
      }
    }
    case actions.SET_SORT_ORDER: {
      var actionData = action.payload;

      if (actionData.tableType === DataUploadStatus.Error) {
        return Object.assign({}, state, {
          sortErrorBy: actionData.sortBy,
          orderErrorBy: actionData.orderBy,
        });
      } else if (actionData.tableType === DataUploadStatus.Skip) {
        return Object.assign({}, state, {
          sortIgnoreBy: actionData.sortBy,
          orderIgnoreBy: actionData.orderBy,
        });
      } else if (actionData.tableType === DataUploadStatus.Update) {
        return Object.assign({}, state, {
          sortSuccessBy: actionData.sortBy,
          orderSuccessBy: actionData.orderBy,
        });
      } else if (actionData.tableType === DataUploadStatus.Delete) {
        return Object.assign({}, state, {
          sortRemovedBy: actionData.sortBy,
          orderRemovedBy: actionData.orderBy,
        });
      }
    }
    case actions.SET_CURRENT_PAY_PERIOD:
      return {
        ...state,
        currentPayPeriod: action.payload.current_period,
      };
    case actions.SET_MAIN_SUMMARY_LIST:
      return {
        ...state,
        summaryRows: action.payload.data,
        totalSummaryCount: action.payload.totalCount,
      };
    case actions.SET_SUMMARY_LIST_DATA_SIZE:
      return {
        ...state,
        summarySizePerPage: action.payload,
      };
    case actions.SET_SUMMARY_LIST_LOADING:
      return {
        ...state,
        isLoadingSummary: action.payload,
      };
    case actions.SET_SUMMARY_PAGINATION_INFO: {
      var actionData = action.payload;

      return Object.assign({}, state, {
        summarySizePerPage: actionData.sizePerPage,
        summaryPageNo: actionData.pageNo,
      });
    }
    case actions.CHANGE_FILE_STATUS: {
      var pay_load = action.payload;
      return {
        ...state,
        summaryRows: state.summaryRows.map((item) => {
          // editing one item
          if (item.file_id === pay_load.file_id) {
            return Object.assign({}, item, {
              status: pay_load.fileStatus,
            });
          }
          // return all the ones we're not changing
          return item;
        }),
      };
    }
    case actions.ANY_FILE_UNFINISHED: {
      let anyUnfinishedJob = state.summaryRows.some(
        (item) =>
          item.status !== "NEEDS REVIEW" &&
          item.status !== "COMPLETED" &&
          item.status !== "FAILED"
      );

      return {
        ...state,
        isPendingProcess: anyUnfinishedJob,
      };
    }
    case actions.UPDATE_FILE_PROGRESS: {
      var pay_load = action.payload;
      return {
        ...state,
        summaryRows: state.summaryRows.map((item) => {
          // editing one item
          if (item.file_id === pay_load.file_id) {
            return Object.assign({}, item, {
              total_errors: pay_load.total_errors,
              total_ignored: pay_load.total_ignored,
              total_updates: pay_load.total_updates,
              total_completed: pay_load.total_completed,
            });
          }
          // return all the ones we're not changing
          return item;
        }),
      };
    }

    default:
      return state;
  }
}
