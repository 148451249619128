export default {
  Directory: 1,
  Region: 2,
  RegionDetails: 3,
  RegionCode: 4,
  RegionName: 5,
  RegionRVPEmail: 134,
  RegionRVPPhone: 135,
  Facility: 6,
  FacilityDetails: 7,
  FacilityCode: 8,
  FacilityDescription: 9,
  FacilityRegion: 10,
  FacilityStatus: 11,
  FacilityType: 12,
  FacilityLevel: 13,
  FacilityOwner: 14,
  FacilitySrfStatus: 15,
  FacilityLmNumber: 16,
  FacilityDistrict: 17,
  FacilityServiceArea: 18,
  FacilityFAAAFDFilename: 180,
  FacilityOSHATier: 136,
  FacilityLatitude: 137,
  FacilityLongitude: 138,
  FacilityAddress: 132,
  FacilityAddressLatLong: 350,
  FacilityPhone: 133,
  FacilityFiles: 19,
  FacilityRepresentatives: 147,
  OfficerUpdate: 278,
  FacilityRepresentativesEmail: 236,
  FacilityRepresentativesPhone: 237,
  FilesConstitution: 20,
  FilesMeetingMinutes: 21,
  FilesMous: 22,
  FilesDuesRebate: 23,
  FilesIrs: 24,
  FilesOsha: 25,
  FilesLmNumber: 26,
  FilesFinancialAudit: 27,
  FacilityGrievances: 28,
  FacilityPac: 29,
  PacSummary: 30,
  PacMembers: 31,
  FacilityNceptStaffing: 32,
  NceptStaffingPpt: 33,
  FacilityTrafficCount: 34,
  FacilityOsha: 35,
  OshaReports: 36,
  OshaOshaTier: 37,
  OshaOshaOwner: 38,
  FacilityOwcp: 39,
  FacilityLegislative: 40,
  FacilityMaps: 41,
  FacilityAssets: 42,
  FacilityTrainedActivists: 43,
  FacilityActivityLogs: 149,
  FacilityMembers: 246,
  FacilityMemberSeniority: 245,
  FacilityMemberTieBreaker: 240,
  Member: 44,
  MembersPersonalInfo: 45,
  PersonalInfoMemberNumber: 46,
  PersonalInfoEmployeeCommonId: 47,
  PersonalInfoFirstName: 48,
  PersonalInfoMiddleName: 49,
  PersonalInfoLastName: 50,
  PersonalInfoNickname: 51,
  PersonalInfoPreviousLastName: 52,
  PersonalInfoSpouseName: 53,
  PersonalInfoGender: 54,
  PersonalInfoShirtSize: 55,
  PersonalInfoDateOfBirth: 56,
  DatesPlannedRetirementDate: 57,
  PersonalInfoLifeInsurance: 58,
  PersonalInfoLongTermDisability: 59,
  PersonalInfoFlsaCategory: 60,
  PersonalInfoOpmStatusCode: 61,
  PersonalInfoVetStatus: 62,
  PersonalInfoRetiredMilitaryController: 63,
  PersonalInfoBargainingUnitStatus: 143,
  MembersContact: 64,
  ContactAddresses: 65,
  ContactEmailAddresses: 66,
  ContactPhones: 67,
  MembersMembership: 72,
  MembershipMemberType: 73,
  MembershipMemberStatus: 74,
  MembersAccount: 75,
  AccountUsername: 76,
  AccountPassword: 77,
  AccountStatus: 78,
  AccountIsEnabled: 79,
  AccountIsLocked: 80,
  AccountIsVerified: 139,
  MembersDates: 81,
  DatesEntryOnDutyDate: 82,
  DatesServiceComputationDate: 83,
  DatesBargainingUnitDate: 84,
  DatesNatcaBargainingUnitDate: 85,
  DatesJoinedUnionDate: 86,
  DatesRetirementDate: 142,
  MembersFinancial: 87,
  FinancialPayCard: 277,
  FinancialHourlySalary: 88,
  FinancialYearlySalary: 89,
  FinancialDrfDonation: 90,
  FinancialPacDonation: 91,
  FinancialNcfDonation: 92,
  FinancialExpectedDues: 93,
  FinancialBillingType: 94,
  MembersJobs: 95,
  FinancialPayGrade: 96,
  FinancialCareerLevel: 97,
  FinancialUnit: 98,
  FinancialFacilityAddressRoutingSymbol: 99,
  FinancialOccupationSeries: 100,
  FinancialPositionTitleOpm: 101,
  FinancialBargainingUnitRegion: 102,
  FinancialOrganizationalCode: 103,
  FinancialOrganizationalTitle: 104,
  FinancialDutyStationCode: 105,
  FinancialDutyStationDescription: 106,
  MembersPositions: 107,
  PositionsHistory: 108,
  MembersFilter: 109,
  FilterSavedSearch: 110,
  FilterShareSearch: 111,
  FilterCustomizeFields: 112,
  FilterExportResults: 113,
  FilterBuckets: 114,
  FilterAdmin: 347, // Reference �Create an Admin permission under Directory/Member/Filter
  MembersCommunication: 115,
  MembersReviewPermission: 141,
  MembersActivityLogs: 150,
  CommunicationAllowPostalMail: 68,
  CommunicationAllowPhoneCall: 69,
  CommunicationAllowTextMessages: 70,
  CommunicationAllowEmails: 71,
  CommunicationAllowTextAlert: 168,
  CommunicationAllowLegislative: 169,
  CommunicationAllowFacebook: 279,
  //************** Email List Module**********************
  EmailLists: 118, /// Can View Side Bar "Email" Link (V)
  EmailListsLists: 119, /// Can CRUD Email List(VAUD)
  EmailAddlSubscribersList: 270,
  EmailAuthSendersList: 271,
  //************** Email List Module**********************

  EmailListAdmin: 120, //****Email List/Admin/View Permission****  //(Child of *EmailList*) Can View Side Bar "Admin" Link
  Teams: 121,
  TeamsTeams: 122,
  TeamsPositions: 123,
  TeamsPermissions: 124,
  TeamsViewDeleted: 125,
  DefaultPermission: 148,
  TeamsActivityLogs: 151,
  MainAdminLink: 129, // Childern are 1)Uploads && 2)"Notification Icon" && 3)Registrations
  AdminUploads: 130,
  AdminPayfiles: 131,
  Payfile1ReviewSummary: 192,
  Payfile2ReviewSummary: 193,
  PACTracker: 151,
  Requests: 152,
  PacDetails: 181,
  CandidateName: 153,
  CandidateState: 182,
  CandidateDistrict: 183,
  CandidateParty: 184,
  CampaignPOCNamePhone: 154,
  EventDate: 155,
  NATCAvistsAttendingEvent: 156,
  Flyer: 157,
  PayableTo: 158,
  AmountRequested: 159,
  AmountApproved: 185,
  CheckNumber: 160,
  CheckDate: 186,
  CheckRemarks: 187,
  RequestHistory: 188,
  FinancialEmployer: 190,
  MailingAddress: 161,
  Comments: 162,
  Pictures: 163,
  Notes: 164,
  ReviewRequests: 165,
  PABReview: 166,
  Candidates: 167,
  FilesVirtualAudit: 191,
  ///############FE_TRacker Module Starts Here#####################
  FETracker: 194, // Root Node Equipment
  FEAdmin: 195,
  FESubCategory: 196,
  FEAccounting: 197,
  FEAccountingDetails: 198,
  FEAccountingStatus: 199,
  FEAccountingUser: 200,
  FEAccountingDescription: 201,
  FEAccountingType: 202,
  FEAccountingPurchaser: 203,
  FEAccountingCost: 204,
  FEAccountingPurchaseDate: 205,
  FEAccountingComments: 206,
  FEAccountingSerialNumber: 207,

  FEAccountingStickerNumber: 208,
  FEAccountingWarrantyInformaion: 209,
  FEAccountingBudgetID: 210,
  FEAccountingFiles: 211,
  FEAccountingUsefulLife: 212,
  FEAccountingDisposedReason: 213,
  FEAccountingDisposedAmount: 214,
  FEAccountingDisposedDate: 215,
  FEAccountingDepreciationValues: 216,
  FEAccountingActivityLog: 217,
  FEAccountingBookVal: 242,
  FEAccountingAD: 244,
  FETrackerEquipmentLists: 218,
  FEFacilityEquipment: 219,
  FERegionEquipment: 220,
  FETeamEquipment: 221,
  FEMemberEquipment: 222,
  FETrackerRequest: 223,
  FERequestDetails: 224,
  FERequestStatus: 225,
  FERequestUser: 226,
  FERequestDescription: 227,
  FERequestType: 228,
  FERequestRequester: 229,
  FERequestRequestedAmount: 230,
  FERequestApprovedAmount: 231,
  FERequestRequestDate: 232,
  FERequestComments: 233,
  FERequestBudgetID: 234,
  FERequestActivityLog: 235,
  FEAccountingCategory: 238,
  FERequestCategory: 239,
  FERequestBookValue: 243,
  ///############FE_TRacker Module END Here#####################
  DatesMandatoryRetirementDate: 241,
  ///############Grievance Module START Here#####################
  Grievances: 247,
  GRRequests: 248,
  GRDetails: 249,
  GRGrievant: 250,
  GRRecipient: 251,
  GRRepresentative: 252,
  GRSubject: 253,
  GRFAANumber: 254,
  GRAdverseAction: 255,
  GRRequestMeeting: 256,
  GRContractArticle: 257,
  GRStatementofFact: 258,
  GRRemedySought: 259,
  GRAdditionalFacts: 260,
  GRDateSubmission: 261,
  GRDateVoilation: 262,
  GRMeetingDate: 263,
  GRResponseDate: 264,
  GRAttachments: 265,
  GRComments: 266,
  GRPDFReport: 267,
  GRReviewTab: 268,
  ///############Grievance Module END Here#####################
  ///#LA_Waris Start///////
  FEAccountingNoti: 269,
  FEAccountingRecycle: 272,
  ///#LA_Waris End///////
  NotificationIcon: 273,

  //##########Registrations Module###################
  Registrations: 280,
  REGSuperAdmin: 281,
  REGBasicAdmin: 282,
  ///#######START OF FINANCE##############
  Finance: 283,
  FinancesRecords: 284,
  FinanceAmount: 285,
  FinanceExpAmount: 286,
  FinancePayPeriod: 287,
  FinanceDuesPostedType: 288,
  FinanceSummary: 289,
  FinanceDuesrebate: 290,
  FinanceDuesCollectionType: 291,
  FinanceType: 292,
  FinanceMember: 293,
  FinanceFacility: 294,
  FinanceEmployer: 295,
  FinanceQuarter: 296,
  FinanceFilters: 297,
  FinanceFiltersDuesAmount: 298,
  FinanceTransaction: 299,
  FinanceTransactionAmount: 300,
  FinanceTransactionRefund: 301,
  FinanceTransactionDate: 302,
  FinanceTransactionCheckNumber: 303,
  FinanceFile: 304,
  FinanceFileRecordDetail: 305,
  FinanceFileErrors: 306,
  FinanceFileErrorsSummary: 307,
  FinanceFileErrorsMemberName: 308,
  FinanceFileErrorsRegion: 309,
  FinanceFileErrorsFacility: 310,
  FinanceFileErrorsMemberNumber: 311,
  FinanceFileErrorsExpectedAmount: 312,
  FinanceFileErrorsAmount: 313,
  FinanceFileErrorsRefund: 314,
  FinanceFileErrorsDuesCollectionType: 315,
  FinanceFileErrorsPDF: 316,
  FinanceFileErrorsCSV: 317,
  FinanceFileIgnore: 318,
  FinanceFileIgnoreSummary: 319,
  FinanceFileIgnoreMemberName: 320,
  FinanceFileIgnoreRegion: 321,
  FinanceFileIgnoreFacility: 322,
  FinanceFileIgnoreMemberNumber: 323,
  FinanceFileIgnoreExpectedAmount: 324,
  FinanceFileIgnoreAmount: 325,
  FinanceFileIgnoreRefund: 326,
  FinanceFileIgnoreDuesCollectionType: 327,
  FinanceFileIgnorePDF: 328,
  FinanceFileIgnoreCSV: 329,
  FinanceFileSuccess: 330,
  FinanceFileSuccessSummary: 331,
  FinanceFileSuccessMemberName: 332,
  FinanceFileSuccessRegion: 333,
  FinanceFileSuccessFacility: 334,
  FinanceFileSuccessMemberNumber: 335,
  FinanceFileSuccessExpectedAmount: 336,
  FinanceFileSuccessAmount: 337,
  FinanceFileSuccessRefund: 338,
  FinanceFileSuccessDuesCollectionType: 339,
  FinanceFileSuccessPDF: 340,
  FinanceFileSuccessCSV: 341,
  FinanceYear: 342,
  CreateGrievance: 343,
  CanCreateUnionGrievance: 344,
  ///#######END OF FINANCE##############
  AssignAreas: 345,
  Areas: 346,
  PayfileIndicator: 348,
  AddressLatLong: 349,
};
