import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import actionFilter from "../../../store/advancedfilter/action";
import axios from "../../Shared/auth-header";
import $ from "jquery";
import { reset, getFormValues } from "redux-form";
import { store } from "../../../../src/index";
import { toast } from "react-toastify";
import { change } from "redux-form";
import FE_AdvanceFilter_NewTabs from "../../F&E/AdvanceFilter/FE_AdvanceFilter_NewTabs";

export class Tags extends Component {
  ///called from tag cross
  removeFilter = (removeId, optionText) => {
    //this.resetFormById(removeId);
    var removeIndex = _.findIndex(
      this.props.filterState.filterData.searchApplied,
      (o) => {
        return o.searchType === +removeId;
      }
    );
    if (removeIndex !== -1) {
      let currentSearchAplied = this.props.filterState.filterData.searchApplied[
        removeIndex
      ];

      if (
        currentSearchAplied.hasOwnProperty("selectedItems") &&
        currentSearchAplied.selectedItems.length === 1
      ) {
        this.props.filterState.filterData.searchApplied[
          removeIndex
        ].selectedItems = this.props.filterState.filterData.searchApplied[
          removeIndex
        ].selectedItems.filter((item) => item !== optionText);

        _.remove(this.props.filterState.filterData.searchApplied, {
          searchType: +removeId,
        });
      } else if (
        currentSearchAplied.hasOwnProperty("selectedItems") &&
        currentSearchAplied.selectedItems.length > 1
      ) {
        this.props.filterState.filterData.searchApplied[
          removeIndex
        ].selectedItems = this.props.filterState.filterData.searchApplied[
          removeIndex
        ].selectedItems.filter((item) => item !== optionText);
      }
    }
    switch (+removeId) {
      case 4:
        //filteredText = "Age";
        store.dispatch(reset("ageForm"));
        this.props.filterState.filterData.age = null;
        break;
      case 7:
        this.props.formValues.memberECIForm.values.memberECI = this.props.formValues.memberECIForm.values.memberECI.filter(
          (f) => f !== optionText
        );
        this.props.filterState.filterData.memberECI = this.props.formValues.memberECIForm.values.memberECI;
        this.getRefrehDataAndCount();
        break;
      case 8:
        //filteredText = "Facility";
        this.props.filterState.filterData.facility = this.props.filterState.filterData.facility.map(
          (item) => {
            if (item.code === optionText) {
              return { ...item, checked: false };
            } else {
              return { ...item };
            }
          }
        );
        break;
      case 9:
        this.props.filterState.filterData.memberStatus = this.props.filterState.filterData.memberStatus.map(
          (item) => {
            if (item.memberStatus === optionText) {
              return { ...item, checked: false };
            } else {
              return { ...item };
            }
          }
        );
        break;
      case 10:
        //filteredText = "Member Type";
        this.props.filterState.filterData.memberType = this.props.filterState.filterData.memberType.map(
          (item) => {
            if (item.description === optionText) {
              return { ...item, checked: false };
            } else {
              return { ...item };
            }
          }
        );
        break;
      case 11:
        //filteredText = "Region";
        this.props.filterState.filterData.region = this.props.filterState.filterData.region.map(
          (item) => {
            if (item.code === optionText) {
              return { ...item, checked: false };
            } else {
              return { ...item };
            }
          }
        );
        break;
      case 12:
        //filteredText = "Member Number";
        this.props.formValues.memberNoForm.values.memberNumbers = this.props.formValues.memberNoForm.values.memberNumbers.filter(
          (f) => f !== optionText
        );
        this.props.filterState.filterData.memberNumber = this.props.formValues.memberNoForm.values.memberNumbers;
        break;
      case 14:
        //filteredText = "Name";
        //optionText == "Ehsaan"  // FirstName        //
        const formValues = this.props.formValues.nameForm.values || {}; //{firstName:"Ehsaan"}
        let keyName = Object.keys(formValues).find((key) => {
          if (formValues[key] === optionText) {
            return key;
          }
        });
        //keyName == firstName
        store.dispatch(async (dispatch) => {
          keyName && dispatch(change("nameForm", keyName, ""));
          await new Promise((resolve) => setImmediate(resolve))
            .then(() => {
              this.props.filterState.filterData.name = this.props.formValues.nameForm.values;
              this.getRefrehDataAndCount();
            })
            .catch((err) => console.log(err));
        });

        return;
      case 15:
        //filteredText = "Shirt Size";
        this.props.filterState.filterData.shirtSize = this.props.filterState.filterData.shirtSize.map(
          (item) => {
            if (item.shirtSize === optionText) {
              return { ...item, checked: false };
            } else {
              return { ...item };
            }
          }
        );
        break;
      case 16:
        //filteredText = "Gender";
        this.props.filterState.filterData.gender = this.props.filterState.filterData.gender.map(
          (item) => {
            if (item.gender === optionText) {
              return { ...item, checked: false };
            } else {
              return { ...item };
            }
          }
        );
        break;
      case 17:
        //filteredText = "PAC";
        store.dispatch(reset("pacForm"));
        this.props.filterState.filterData.pac = null;
        break;
      case 18:
        //filteredText = "DRF";
        store.dispatch(reset("drfForm"));
        this.props.filterState.filterData.drf = null;
        break;
      case 53:
        //"DuesBalance"

        let financeObj = this.props.filterState.filterData.finance;
        if (
          financeObj.duesBalance.checked &&
          financeObj.duesBalance.duesBalance === optionText
        ) {
          financeObj.duesBalance.checked = false;
        }
        if (optionText.includes("DuesRange")) {
          this.props.filterState.filterData.finance.fromDuesAmount = null;
          this.props.filterState.filterData.finance.toDuesAmount = null;
        }
        break;
      case 19:
        //  filteredText = "Duty Station";
        this.props.filterState.filterData.dutyStation = this.props.filterState.filterData.dutyStation.map(
          (item) => {
            if (item.dutyStation === optionText) {
              return { ...item, checked: false };
            } else {
              return { ...item };
            }
          }
        );
        break;
      case 20:
        /// filteredText = "Unit";
        this.props.filterState.filterData.unit = this.props.filterState.filterData.unit.map(
          (item) => {
            if (item.unitCode === optionText) {
              return { ...item, checked: false };
            } else {
              return { ...item };
            }
          }
        );
        break;

      case 21:
        ///  filteredText = "Occupation Series";
        this.props.filterState.filterData.occupationSeries = this.props.filterState.filterData.occupationSeries.map(
          (item) => {
            if (item.occupation === optionText) {
              return { ...item, checked: false };
            } else {
              return { ...item };
            }
          }
        );
        break;
      case 22:
        /// filteredText = "Career Level";
        this.props.filterState.filterData.careerLevel = this.props.filterState.filterData.careerLevel.map(
          (item) => {
            if (item.description === optionText) {
              return { ...item, checked: false };
            } else {
              return { ...item };
            }
          }
        );

        break;
      case 23:
        ///  filteredText = "Pay Grade";

        this.props.filterState.filterData.payGrade = this.props.filterState.filterData.payGrade.map(
          (item) => {
            if (item.grade === optionText) {
              return { ...item, checked: false };
            } else {
              return { ...item };
            }
          }
        );

        break;
      case 24:
        ///  filteredText = "FLSA Category";

        this.props.filterState.filterData.flsaCategory = this.props.filterState.filterData.flsaCategory.map(
          (item) => {
            if (item.flsa === optionText) {
              return { ...item, checked: false };
            } else {
              return { ...item };
            }
          }
        );

        break;
      case 25:
        /// filteredText = "Communication";

        this.props.filterState.filterData.communication = this.props.filterState.filterData.communication.map(
          (item) => {
            if (item.communication === optionText) {
              return { ...item, checked: false };
            } else {
              return { ...item };
            }
          }
        );
        break;

      case 26:
        /// filteredText = "Address";

        let addressObj = this.props.filterState.filterData.address;
        if (
          addressObj.addressTypes.some(
            (s) => s.checked && s.code === optionText
          )
        ) {
          this.props.filterState.filterData.address.addressTypes = this.props.filterState.filterData.address.addressTypes.map(
            (item) => {
              if (item.code === optionText) {
                return { ...item, checked: false };
              } else {
                return { ...item };
              }
            }
          );
        }
        if (
          addressObj.states.some((s) => s.checked && s.stateName === optionText)
        ) {
          this.props.filterState.filterData.address.states = this.props.filterState.filterData.address.states.map(
            (item) => {
              if (item.stateName === optionText) {
                return { ...item, checked: false };
              } else {
                return { ...item };
              }
            }
          );
        }

        if (
          addressObj.verifiedAddress.some(
            (s) => s.checked && s.verifiedAddress === optionText
          )
        ) {
          this.props.filterState.filterData.address.verifiedAddress = this.props.filterState.filterData.address.verifiedAddress.map(
            (item) => {
              if (item.verifiedAddress === optionText) {
                return { ...item, checked: false };
              } else {
                return { ...item };
              }
            }
          );
        }

        if (
          addressObj.nullPrimaryAddress.some(
            (s) => s.checked && s.tag === optionText
          )
        ) {
          this.props.filterState.filterData.address.nullPrimaryAddress = this.props.filterState.filterData.address.nullPrimaryAddress.map(
            (item) => {
              if (item.tag === optionText) {
                return { ...item, checked: false };
              } else {
                return { ...item };
              }
            }
          );
        }

        if (!_.isEmpty(addressObj.city) && addressObj.city === optionText) {
          this.props.filterState.filterData.address.city = null;
        }
        if (
          !_.isEmpty(addressObj.country) &&
          addressObj.country === optionText
        ) {
          this.props.filterState.filterData.address.country = null;
        }
        if (!_.isEmpty(addressObj.zip) && addressObj.zip === optionText) {
          this.props.filterState.filterData.address.country = null;
        }
        if (
          addressObj.zipWithout4 &&
          addressObj.zipWithout4.zipcodeWithout4 === optionText
        ) {
          this.props.filterState.filterData.address.zipWithout4.checked = false;
        }

        break;
      case 27:
        ///  filteredText = "Veteran";
        this.props.filterState.filterData.veteran = this.props.filterState.filterData.veteran.map(
          (item) => {
            if (item.veteran === optionText) {
              return { ...item, checked: false };
            } else {
              return { ...item };
            }
          }
        );
        break;

      case 28:
        ///  filteredText = "Retired Military";
        this.props.filterState.filterData.retiredMilitary = this.props.filterState.filterData.retiredMilitary.map(
          (item) => {
            if (item.retiredMilitary === optionText) {
              return { ...item, checked: false };
            } else {
              return { ...item };
            }
          }
        );
        break;

      case 29:
        ///  filteredText = "Facility Routing Symbol";
        store.dispatch(reset("facilityRoutingSymbolForm"));
        this.props.filterState.filterData.facilityRoutingSymbol = null;

        break;
      case 30:
        ///  filteredText = "Org. Code";
        store.dispatch(reset("orgCodeForm"));
        this.props.filterState.filterData.orgCode = null;

        break;
      case 31:
        ///  filteredText = "Hourly Salary";
        store.dispatch(reset("hourlySalaryForm"));
        this.props.filterState.filterData.hourlySalary = null;
        break;
      case 32:
        //filteredText = "Annual Salary";
        store.dispatch(reset("annualSalaryForm"));
        this.props.filterState.filterData.annualSalary = null;
        break;
      case 33:
        // filteredText = "Email";

        store.dispatch(async (dispatch) => {
          if (this.props.filterState.filterData.email === optionText) {
            dispatch(change("emailForm", "Email", null));
          }

          this.props.filterState.filterData.nullPrimaryEmail.map((item) => {
            if (item.tag === optionText) {
              dispatch(
                change(
                  "emailForm",
                  "NullPrimaryEmail" + item.hasPrimaryEmail,
                  null
                )
              );
            }
          });
        });
        if (this.props.filterState.filterData.email === optionText) {
          this.props.filterState.filterData.email = null;
        }

        this.props.filterState.filterData.nullPrimaryEmail = this.props.filterState.filterData.nullPrimaryEmail.map(
          (item) => {
            if (item.tag === optionText) {
              return { ...item, checked: false };
            } else {
              return { ...item };
            }
          }
        );
        break;
      case 52:
        store.dispatch(reset("facebookForm"));
        this.props.filterState.filterData.facebook = null;
        break;
      case 34:
        // filteredText = "Planned Retirement";
        const retirementFormValues = this.props.formValues.plannedRetirementForm
          .values;
        let rkeyName = Object.keys(retirementFormValues).find((key) => {
          if (
            retirementFormValues[key].getTime() ===
            new Date(optionText).getTime()
          ) {
            return key;
          }
        });
        store.dispatch(async (dispatch) => {
          dispatch(change("plannedRetirementForm", rkeyName, ""));
          await new Promise((resolve) => setImmediate(resolve))
            .then(() => {
              this.props.filterState.filterData.plannedRetirement = this.props.formValues.plannedRetirementForm.values;
              this.getRefrehDataAndCount();
            })
            .catch((err) => console.log(err));
        });
        return;
      case 50:
        // filteredText = "Mandatory Retirement";
        const mrretirementFormValues = this.props.formValues
          .mandatoryRetirementForm.values;
        let mrkeyName = Object.keys(mrretirementFormValues).find((key) => {
          if (
            mrretirementFormValues[key].getTime() ===
            new Date(optionText).getTime()
          ) {
            return key;
          }
        });
        store.dispatch(async (dispatch) => {
          dispatch(change("mandatoryRetirementForm", mrkeyName, ""));
          await new Promise((resolve) => setImmediate(resolve))
            .then(() => {
              this.props.filterState.filterData.mandatoryRetirement = this.props.formValues.mandatoryRetirementForm.values;
              this.getRefrehDataAndCount();
            })
            .catch((err) => console.log(err));
        });
        return;
      case 35:
        //  filteredText = "SCD";
        const scdFormValues = this.props.formValues.sCDForm.values;
        let skeyName = Object.keys(scdFormValues).find((key) => {
          if (scdFormValues[key].getTime() === new Date(optionText).getTime()) {
            return key;
          }
        });
        store.dispatch(async (dispatch) => {
          dispatch(change("sCDForm", skeyName, ""));
          await new Promise((resolve) => setImmediate(resolve))
            .then(() => {
              this.props.filterState.filterData.scd = this.props.formValues.sCDForm.values;
              this.getRefrehDataAndCount();
            })
            .catch((err) => console.log(err));
        });
        return;
      case 36:
        // filteredText = "EOD Date";
        const eODFormValues = this.props.formValues.eODDateForm.values;
        let ekeyName = Object.keys(eODFormValues).find((key) => {
          if (eODFormValues[key].getTime() === new Date(optionText).getTime()) {
            return key;
          }
        });
        store.dispatch(async (dispatch) => {
          dispatch(change("eODDateForm", ekeyName, ""));
          await new Promise((resolve) => setImmediate(resolve))
            .then(() => {
              this.props.filterState.filterData.eodDate = this.props.formValues.eODDateForm.values;
              this.getRefrehDataAndCount();
            })
            .catch((err) => console.log(err));
        });
        return;
      case 37:
        // filteredText = "NATCA BU Date";
        const natcaBuFormValues = this.props.formValues.nATCABUDateForm.values;
        let nkeyName = Object.keys(natcaBuFormValues).find((key) => {
          if (
            natcaBuFormValues[key].getTime() === new Date(optionText).getTime()
          ) {
            return key;
          }
        });
        store.dispatch(async (dispatch) => {
          dispatch(change("nATCABUDateForm", nkeyName, ""));
          await new Promise((resolve) => setImmediate(resolve))
            .then(() => {
              this.props.filterState.filterData.natcabuDate = this.props.formValues.nATCABUDateForm.values;
              this.getRefrehDataAndCount();
            })
            .catch((err) => console.log(err));
        });
        return;

      case 38:
        //  filteredText = "BU Date";

        const bUDateForm = this.props.formValues.bUDateForm.values;
        let bkeyName = Object.keys(bUDateForm).find((key) => {
          if (bUDateForm[key].getTime() === new Date(optionText).getTime()) {
            return key;
          }
        });
        store.dispatch(async (dispatch) => {
          dispatch(change("bUDateForm", bkeyName, ""));
          await new Promise((resolve) => setImmediate(resolve))
            .then(() => {
              this.props.filterState.filterData.unionStartDate = this.props.formValues.bUDateForm.values;
              this.getRefrehDataAndCount();
            })
            .catch((err) => console.log(err));
        });
        return;
      case 39:
        // filteredText = "Union Start Date";

        const unionStartDateFormValues = this.props.formValues
          .unionStartDateForm.values;
        let ukeyName = Object.keys(unionStartDateFormValues).find((key) => {
          if (
            unionStartDateFormValues[key].getTime() ===
            new Date(optionText).getTime()
          ) {
            return key;
          }
        });
        store.dispatch(async (dispatch) => {
          dispatch(change("unionStartDateForm", ukeyName, ""));
          await new Promise((resolve) => setImmediate(resolve))
            .then(() => {
              this.props.filterState.filterData.unionStartDate = this.props.formValues.unionStartDateForm.values;
              this.getRefrehDataAndCount();
            })
            .catch((err) => console.log(err));
        });

        return;
      case 40:
        // filteredText = "Union Join Date";

        const unionJoinDateFormValues = this.props.formValues.unionJoinDateForm
          .values;
        let jkeyName = Object.keys(unionJoinDateFormValues).find((key) => {
          if (
            unionJoinDateFormValues[key].getTime() ===
            new Date(optionText).getTime()
          ) {
            return key;
          }
        });
        store.dispatch(async (dispatch) => {
          dispatch(change("unionJoinDateForm", jkeyName, ""));
          await new Promise((resolve) => setImmediate(resolve))
            .then(() => {
              this.props.filterState.filterData.unionJoinDate = this.props.formValues.unionJoinDateForm.values;
              this.getRefrehDataAndCount();
            })
            .catch((err) => console.log(err));
        });
        return;

      case 41:
        // filteredText = "Retirement Date";

        const retirementDateFormValues = this.props.formValues.retirementForm
          .values;
        let jrkeyName = Object.keys(retirementDateFormValues).find((key) => {
          if (
            retirementDateFormValues[key].getTime() ===
            new Date(optionText).getTime()
          ) {
            return key;
          }
        });
        store.dispatch(async (dispatch) => {
          dispatch(change("retirementForm", jrkeyName, ""));
          await new Promise((resolve) => setImmediate(resolve))
            .then(() => {
              this.props.filterState.filterData.unionJoinDate = this.props.formValues.retirementForm.values;
              this.getRefrehDataAndCount();
            })
            .catch((err) => console.log(err));
        });
        return;
      case 42:
        //filteredText = "Name";
        const teamPositionFormValues = this.props.formValues.teamPositionForm
          .values;
        let tpkeyName = Object.keys(teamPositionFormValues).find((key) => {
          if (teamPositionFormValues[key] === optionText) {
            return key;
          }
        });
        store.dispatch(async (dispatch) => {
          dispatch(change("teamPositionForm", tpkeyName, ""));
          await new Promise((resolve) => setImmediate(resolve))
            .then(() => {
              this.props.filterState.filterData.teamPosition = this.props.formValues.teamPositionForm.values;
              this.getRefrehDataAndCount();
            })
            .catch((err) => console.log(err));
        });

        return;
      case 55:
        //filteredText = "Name";
        const activeDatePositionFormValues = this.props.formValues
          .activeDatePositionForm.values;

        let adkeyName = Object.keys(activeDatePositionFormValues).find(
          (key) => {
            if (activeDatePositionFormValues[key] === optionText) {
              return key;
            }
          }
        );
        console.log(
          "activeDatePositionFormValues",
          activeDatePositionFormValues
        );
        store.dispatch(async (dispatch) => {
          dispatch(change("activeDatePositionForm", adkeyName, ""));
          await new Promise((resolve) => setImmediate(resolve))
            .then(() => {
              this.props.filterState.filterData.activeTeamPositionDate = this.props.formValues.activeDatePositionForm.values;
              console.log(
                "this.props.formValues.activeDatePositionForm.values",
                this.props.formValues.activeDatePositionForm.values
              );
              this.getRefrehDataAndCount();
            })
            .catch((err) => console.log(err));
        });

        return;
      case 54:
        this.props.filterState.filterData.teams = this.props.filterState.filterData.teams.map(
          (item) => {
            if (item.teamName === optionText) {
              return { ...item, checked: false };
            } else {
              return { ...item };
            }
          }
        );
        break;
      case 44:
        this.props.filterState.filterData.positionTypes = this.props.filterState.filterData.positionTypes.map(
          (item) => {
            if (item.positionType === optionText) {
              return { ...item, checked: false };
            } else {
              return { ...item };
            }
          }
        );
        break;
      //FE Member AdvanceFilter Tabs for Equipment

      case Number(FE_AdvanceFilter_NewTabs.MEM_AF_INV_STATUS):
        this.props.filterState.filterData.fe_request_statuses = this.props.filterState.filterData.fe_request_statuses.map(
          (item) => {
            if (item.status === optionText) {
              return { ...item, checked: false };
            } else {
              return { ...item };
            }
          }
        );
        break;
      case Number(FE_AdvanceFilter_NewTabs.MEM_AF_INV_TYPE):
        this.props.filterState.filterData.fe_inventory_types = this.props.filterState.filterData.fe_inventory_types.map(
          (item) => {
            if (item.type === optionText) {
              return { ...item, checked: false };
            } else {
              return { ...item };
            }
          }
        );
        break;
      case Number(FE_AdvanceFilter_NewTabs.MEM_AF_INV_BUDGETLINES):
        this.props.filterState.filterData.fe_inventory_budgetlines = this.props.filterState.filterData.fe_inventory_budgetlines.map(
          (item) => {
            if (item.budgetline === optionText) {
              return { ...item, checked: false };
            } else {
              return { ...item };
            }
          }
        );
        break;
      case Number(FE_AdvanceFilter_NewTabs.MEM_AF_INV_BOOKVAL):
        this.props.filterState.filterData.fe_inv_book_vals = null;
        store.dispatch(reset("fe_mem_bookValsForm"));
        break;

      case Number(FE_AdvanceFilter_NewTabs.MEM_AF_INV_DESC):
        this.props.filterState.filterData.fe_inventory_desc = null;
        store.dispatch(reset("fe_mem_descriptionForm"));
        break;
      case 51:
        // filteredText = "Email";

        store.dispatch(async (dispatch) => {
          this.props.filterState.filterData.nullPrimaryPhone.map((item) => {
            if (item.tag === optionText) {
              dispatch(
                change(
                  "phoneForm",
                  "NullPrimaryPhone" + item.hasPrimaryPhone,
                  null
                )
              );
            }
          });
          if (this.props.filterState.filterData.phone === optionText) {
            dispatch(change("phoneForm", "Phone", null));
          }
        });

        if (this.props.filterState.filterData.phone === optionText) {
          this.props.filterState.filterData.phone = null;
        }

        this.props.filterState.filterData.nullPrimaryPhone = this.props.filterState.filterData.nullPrimaryPhone.map(
          (item) => {
            if (item.tag === optionText) {
              return { ...item, checked: false };
            } else {
              return { ...item };
            }
          }
        );
        break;

      case 56:
        /// filteredText = "Unit";
        this.props.filterState.filterData.congressionalDistrict = this.props.filterState.filterData.congressionalDistrict.map(
          (item) => {
            if ("CD (" + item.cd + ")" === optionText) {
              return { ...item, checked: false };
            } else {
              return { ...item };
            }
          }
        );
        break;
      default:
        break;
    }
    this.getRefrehDataAndCount();
  };

  getRefrehDataAndCount = () => {
    this.props.setFilterData({
      ...this.props.filterState.filterData,
    });
    this.onReturnNo();
    if (this.props.RefreshResult) {
      if (
        !_.isEmpty(this.props.filterState.filterData.searchApplied) &&
        this.props.filterState.returnResultNo !== 0
      ) {
        $(".loading").show();
        axios
          .post(
            "/api/Member/AdvancedFilter?pageOffset=0&pageFetch=25&sortName=seniorityasc",
            this.props.filterState.filterData
          )
          .then(({ data }) => {
            if (data.status && data.status.toLowerCase() == "success") {
              this.props.onChangeTable(data);
              this.props.setFilterResult(data);
              this.props.setAdvancedSearch(true);
              this.props.setIsBasicSearchSelected(false);

              this.props.filterState.filterData.searchApplied = this.props.filterState.filterData.searchApplied.map(
                (item) => {
                  if (item.isApplied) {
                    item.isSearched = true;
                  } else {
                    item.isSearched = false;
                  }
                  return item;
                }
              );
              this.props.setFilterData({
                ...this.props.filterState.filterData,
              });
              $(".loading").hide();
            } else {
              $(".loading").hide();
              //console.log(data);
            }
          });
      } else {
        this.props.onResetTable();
      }
    }

    this.props.setAdvanceFilterLoader(true);
    this.props.getNewFilteredData(this.props.filterState.filterData, false);
  };
  onReturnNo() {
    /// API
    this.props.getMemberTotalCount(this.props.filterState.filterData);
    // if (!_.isEmpty(this.props.filterState.filterData.searchApplied)) {
    //   this.props.getMemberTotalCount(this.props.filterState.filterData);
    // } else {
    //   this.props.setReturnResultNo(0);
    // }
  }
  async tagSelection(searchType) {
    if (+this.props.filterState.activeTab !== +searchType) {
      await this.props.setActiveTab(searchType.toString());
      var $s = $(".sidebar-adv");
      if ($(".sidebar-adv .active").length) {
        var optionTop = $(".sidebar-adv .active").offset().top;
        var selectTop = $s.offset().top;
        $(".sidebar-adv").animate(
          {
            scrollTop: $s.scrollTop() + (optionTop - selectTop),
          },
          800
        );
      } else {
        console.log("sidebar-adv .active is undefined");
      }
    }
  }
  applySelection(item, disable) {
    const selectedTag = item.selectedItems.map((optionText, idx) => {
      return (
        <a
          key={idx + 1}
          className={`filter-selected ${disable ? "disabled" : ""} `}
          onClick={() => {
            if (disable || this.props.RefreshResult) {
              // Don't perform the action when the link is disabled or when RefreshResult is true
              return;
            }
            this.tagSelection(item.searchType);
          }}
        >
          {optionText}

          <span key={"filtered-" + item.searchType}>
            <a
              onClick={() => {
                if (disable) {
                  // Don't perform the action when the link is disabled
                  return;
                }
                this.removeFilter(item.searchType, optionText);
              }}
              className={`${disable ? "disabled" : ""}`}
            >
              <i className={`fas fa-times ${disable ? "disabled" : ""}`} />
            </a>
          </span>
        </a>
      );
    });

    return (
      <>
        {selectedTag.map((item, idx) => {
          return <div key={item.searchType}>{item}</div>;
        })}
      </>
    );
  }

  render() {
    const { dontAllowEditFilter, disableTags } = this.props;
    let shouldDisable;
    if (dontAllowEditFilter || disableTags) {
      shouldDisable = true;
    }
    return (
      <div className="row pl-2">
        {!_.isEmpty(this.props.filterState.filterData) &&
          !_.isEmpty(this.props.filterState.filterData.searchApplied) &&
          this.props.filterState.filterData.searchApplied.map((item) => {
            if (item.isApplied) {
              return this.applySelection(item, shouldDisable);
            }
          })}
      </div>
    );
  }
}
export default connect(
  (state) => ({
    filterState: state.advancedFilter,
    formValues: state.form,
  }),
  {
    ...actionFilter,
  }
)(Tags);
