import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MDBInput } from "mdbreact";
import InputMask from "react-input-mask";
import axios from "../../Shared/auth-header";
import AlreadyRegisteredModal from "./Modals/AlreadyRegisteredModal";
import { toast } from "react-toastify";

export default class Step1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isEmailExist: true,
      objectPE: null,
      phoneCheck: false,
    };
    this.handleOnBlur = this.handleOnBlur.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.verificationControl = this.verificationControl.bind(this);
  }

  handleClose = () => {
    this.setState({
      showModal: false,
    });
  };
  async handleOnBlur() {
    try {
      let { data } = await axios.post(
        `/api/Account/CheckRegistrationStatus?memberNumber=${this.props.MemberNumber}`
      );

      if (data.status && data.status.toLowerCase() == "success") {
        if (data.isRegisteredAlready === false) {
          let { objectPE } = data;
          let haveEmail = false,
            havePhone = false;

          if (objectPE.emailObj && objectPE.emailObj.email != null) {
            await this.setState({ isEmailExist: true });
            haveEmail = true;
          } else {
            await this.setState({ isEmailExist: false });
          }
          if (objectPE.phoneObj && objectPE.phoneObj.phone != null) {
            havePhone = true;
          }
          if (havePhone && haveEmail) {
            this.props.changePEFlag(1);
            this.props.changePEHandler(
              "email",
              objectPE.emailObj.id,
              objectPE.emailObj.email
            );
          } else if (haveEmail) {
            this.props.changePEFlag(2);
            this.props.changePEHandler(
              "email",
              objectPE.emailObj.id,
              objectPE.emailObj.email
            );
          } else if (havePhone) {
            this.props.changePEFlag(3);
          } else {
            this.props.changePEFlag(4);
          }
          await this.setState({ objectPE: objectPE ? objectPE : null });
        } else if (data.isRegisteredAlready === true) {
          this.setState({ showModal: true });
        }
      } else {
        await this.setState({ objectPE: null, isEmailExist: true });
        toast.error(data.message);
      }
    } catch (e) {
      console.log(e.message);
    }
  }
  verificationControl() {
    let selected_PEObj = this.props.PE_obj;
    let { objectPE } = this.state;
    if (objectPE == null || this.props.PEFlag == 4) {
      return null;
    }
    if (this.props.PEFlag == 1 || this.props.PEFlag == 2) {
      return (
        <div className="form-group">
          <div className="mb-2 font-italic text-secondary font-weight-bold">
            {this.props.PEFlag == 1
              ? "Select Email/Phone to send the verification code:"
              : "Select Email to send the verification code:"}
          </div>
          {objectPE.emailObj != null && (
            <div key={objectPE.emailObj.id} className="pl-4">
              <input
                type="radio"
                id={objectPE.emailObj.id}
                name="radioGroup"
                class="form-check-input"
                value={objectPE.emailObj.id}
                checked={
                  selected_PEObj != null
                    ? selected_PEObj.type == "email" &&
                      selected_PEObj.id == objectPE.emailObj.id
                    : false
                }
                onChange={() =>
                  this.props.changePEHandler(
                    "email",
                    objectPE.emailObj.id,
                    objectPE.emailObj.email
                  )
                }
              />
              <label
                class="form-check-label"
                class="form-check-label"
                htmlFor={objectPE.emailObj.id}
              >
                {objectPE.emailObj.email}
              </label>
            </div>
          )}

          {objectPE.phoneObj != null && (
            <div
              key={objectPE.phoneObj.id}
              className={"pl-4" + (objectPE.emailObj != null ? " mt-2" : "")}
            >
              <input
                type="radio"
                id={objectPE.phoneObj.id}
                name="radioGroup"
                class="form-check-input"
                value={objectPE.phoneObj.id}
                checked={
                  selected_PEObj != null
                    ? selected_PEObj.type == "phone" &&
                      selected_PEObj.id == objectPE.phoneObj.id
                    : false
                }
                onChange={() =>
                  this.props.changePEHandler(
                    "phone",
                    objectPE.phoneObj.id,
                    objectPE.phoneObj.phone
                  )
                }
              />
              <label htmlFor={objectPE.phoneObj.id}>
                {objectPE.phoneObj.phone}
              </label>
            </div>
          )}
          {this.props.PEFlag == 1 && (
            <a target="_blank" href={`mailto:support@natca.org`}>
              My email/phone is not displayed
            </a>
          )}
          <div className="text-danger">{this.props.PE_err}</div>
        </div>
      );
    } else if (this.props.PEFlag == 3) {
      return (
        <div className="form-group">
          <div className="mb-2 font-italic text-secondary font-weight-bold">
            Select Phone for verification code if you want:
          </div>

          {objectPE.phoneObj != null && (
            <>
              <div key={objectPE.phoneObj.id} className={"pl-4"}>
                <input
                  type="checkbox"
                  id={objectPE.phoneObj.id}
                  name="PEPhone"
                  class="form-check-input"
                  onChange={async (e) => {
                    this.props.changePEHandler(
                      "phone",
                      objectPE.phoneObj.id,
                      objectPE.phoneObj.phone,
                      e.target.checked
                    );
                  }}
                />
                <label htmlFor={objectPE.phoneObj.id}>
                  {objectPE.phoneObj.phone}
                </label>
              </div>
              <div className="text-danger">{this.props.PE_err}</div>
            </>
          )}
        </div>
      );
    }
  }

  render() {
    if (this.props.currentStep !== 1) {
      // Prop: The current step
      return null;
    }

    return (
      <div className="step1Parent">
        <div className="form-group">
          <MDBInput
            name="MemberNumber"
            value={this.props.MemberNumber}
            onChange={this.props.changeHandler}
            label="NATCA Member Number"
            containerClass="mb-2"
            maxLength="5"
            containerClass="mb-2"
            group
            type="text"
            onBlur={this.handleOnBlur}
          >
            {this.props.MemberNumber_err && (
              <span className="text-danger">{this.props.MemberNumber_err}</span>
            )}
          </MDBInput>
          <a target="_blank" href='/MemberNumberLookup'>
            I don't know my member number.
          </a>
        </div>
        {!this.state.isEmailExist && (
          <div className="form-group">
            <MDBInput
              name="Email"
              value={this.props.Email}
              onChange={this.props.handleChangeEmail}
              label="Email"
              group
              type="text"
            >
              <span className="text-danger">{this.props.email_err}</span>
            </MDBInput>
          </div>
        )}
        {this.verificationControl()}
        <div className="md-form form-group mt-0 mb-2">
          <InputMask
            value={this.props.DateofBirth}
            onChange={this.props.handleChangeDOB}
            name="DateofBirth"
            data-type="P"
            mask="99/99/9999"
            className="form-control"
            type="text"
            placeholder="Birthdate"
            label="Date of Birth"
          />
          <span className="text-danger">{this.props.DateofBirth_err}</span>
        </div>

        <div className="form-group">
          <MDBInput
            name="userName"
            value={this.props.userName}
            onChange={this.props.changeUserNameHandler}
            label="Username"
            group
            type="text"
          >
            <span className="text-danger">{this.props.userName_err}</span>
          </MDBInput>
        </div>
        <div className="form-group">
          <MDBInput
            name="Password"
            value={this.props.Password}
            onChange={this.props.handleChangePwd}
            label="Password"
            group
            type="password"
          >
            <span className="text-danger">{this.props.Password_err}</span>
          </MDBInput>
        </div>
        <div className="form-group">
          <MDBInput
            name="RePassword"
            value={this.props.RePassword}
            onChange={this.props.handleChangePwd}
            label="Confirm Password"
            group
            type="password"
          >
            <span className="text-danger">{this.props.RePassword_err}</span>
          </MDBInput>
        </div>

        <div className="form-group m-t-10 mb-4 row">
          <div className="col-12 m-t-20 mx-auto text-center">
            <button
              disabled={!this.props.valid}
              className="btn btn-primary btn-block w-md waves-effect waves-light"
              type="button"
              onClick={(e) => this.props.registerClick(e)}
            >
              Register
            </button>
          </div>
          <div className="col-12 m-t-20 text-left ">
            <span style={{ fontWeight: "450" }}>
              Already have an account ?{" "}
            </span>
            <Link to="login" className="font-500 text-primary text-right">
              {" "}
              Login{" "}
            </Link>{" "}
          </div>

          <div className="col-12 m-t-15 text-left font-weight-bold">
            By registering for an account with NATCA you agree to our
            <Link
              to="/privacy-policy"
              className="font-500 text-primary text-right"
            >
              {" "}
              Terms & Conditions.{" "}
            </Link>{" "}
          </div>

          <div className="col-12 m-t-5 text-left font-weight-bold">
            Need help?
          </div>
          <div className="col-12 m-t-5 text-left font-weight-lighter">
            Can't remember your Member Number?
          </div>
          <div className="col-12 m-t-5 text-left">
            <a
              className="font-500 text-primary text-right"
              href="/MemberNumberLookup"
              target="_blank"
            >
              Member Number Lookup
            </a>
            {/* <Link
              to="/ForgotUsername.aspx"
              className="font-500 text-primary text-right"
            >
              {" "}
              Member Number Lookup{" "}
           </Link>{" "}*/}
          </div>
          <div className="col-12 m-t-5 text-left font-weight-lighter">
            Unable to complete account registration?
          </div>
          <div className="col-12 m-t-5 text-left">
            <a target="_blank" href={`mailto:support@natca.org`}>
              support@natca.org
            </a>
          </div>

          <AlreadyRegisteredModal
            showModal={this.state.showModal}
            onClose={this.handleClose}
          />
        </div>
      </div>
    );
  }
}
